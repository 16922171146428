import { useEffect, useState } from "react";
import html2pdf from "html2pdf.js";
import styles from "./Invoice.module.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { config } from "../../utils/config";
import { tokenDecode } from "../../utils/helper";
import BeatLoader from "react-spinners/BeatLoader";
import styled from "styled-components";

const Pbutton = styled.button`
  outline: none;
  border: none;
  margin-left: 18px;
  background: #0094ff;
  padding: 3px 5px;
`;

export default function Invoice2() {
  const param = useParams();
  const decoded = tokenDecode();
  const { data } = decoded;
  console.log(param);
  const { apiEndpoint } = config;
  const [orderDetails, setOrderDetails] = useState([]);
  const [productDetails, setproductDetails] = useState([]);
  const [billingDetails, setbillingDetails] = useState([]);
  const [paymentDetails, setpaymentDetails] = useState([]);
  const [logisticsDetails, setlogisticsDetails] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [Word, setWord] = useState("");
  useEffect(() => {
    getOrderDetails();
  }, []);
  useEffect(() => {
    convertAmountToWord(parseInt(paymentDetails?.params?.amount));
  }, [paymentDetails]);
  function convertAmountToWord(number) {
    const first = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    const tens = [
      "",
      "",
      "twenty ",
      "thirty ",
      "forty ",
      "fifty ",
      "sixty ",
      "seventy ",
      "eighty ",
      "ninety ",
    ];
    const mad = ["", "thousand", "million", "billion", "trillion"];
    let word = "";
    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word =
            first[Math.floor(tempNumber / Math.pow(1000, i))] +
            mad[i] +
            " " +
            word;
        } else {
          word =
            tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
            first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
            mad[i] +
            " " +
            word;
        }
      }
      tempNumber = number % Math.pow(1000, i + 1);
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
        word =
          first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] +
          "hunderd " +
          word;
    }
    setWord(word);
  }
  async function getOrderDetails() {
    setisLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerorderPdf`,
        method: "POST",
        data: { order_id: param.order_id },
      });
      console.log("data--->", data);
      if (data.status == 200) {
        setOrderDetails(data.data[0]);
        setproductDetails(data?.data);
        setbillingDetails(JSON.parse(data.data[0].billing));
        setpaymentDetails(JSON.parse(data.data[0].payment));
        setisLoading(false);
        setlogisticsDetails(JSON.parse(data.data[0].quote));
      } else {
        console.log("Something Went Wrong");
        setisLoading(false);
      }
    } catch (err) {
      console.log(err);
      setisLoading(false);
    }
  }
  function PDFGenerator() {
    const element = document.getElementById("page-content"); // ID of the element containing the page content
    html2pdf()
      .set({
        margin: 0,
        filename: "page.pdf",
        image: { type: "jpeg", quality: 1.0 },
      })
      .from(element)
      .save();
  }
  const TableHeads = [
    "S No",
    "Description",
    "Price (Tax Included)",
    "Qty",
    "Tax Rate (in %)",
    "Delivery Charge",
    "Total Amount",
  ];

  return (
    <>
      {!isLoading ? (
        <div id="page-content" className={styles.inv_con}>
          <div style={{ border: "1px solid black", padding: "20px" }}>
            <div className="d-flex justify-content-between">
              <img src="" alt="" />
              <section>
                <p style={{ fontWeight: "600", color: "black" }}>
                  Tax Invoice/Bill of Supply/Cash Memo
                </p>
                <p>Original for Recipient</p>
              </section>
            </div>
            <div className="w-25" style={{ fontSize: "9px" }}></div>
            <div className="d-flex justify-content-between mt-4">
              <div className={styles.text_boxes}>
                <h6 style={{ fontWeight: "600", color: "black" }}>Sold By:</h6>
                <section>
                  <p>{orderDetails.company_name}</p>
                  <p>{`${orderDetails.personal_address}, ${orderDetails.city}, ${orderDetails.state}, ${orderDetails.area_code}, India`}</p>
                </section>
              </div>
              <div className={styles.text_boxes} style={{ textAlign: "end" }}>
                <h6
                  className="m-0 p-0"
                  style={{ fontWeight: "600", color: "black" }}
                >
                  Billing Address:
                </h6>
                <section>
                  <p className="text-capitalize">
                    {billingDetails?.address?.name}{" "}
                  </p>
                  <p className="m-0 p-0 text-capitalize">
                    {" "}
                    {`${billingDetails?.address?.locality}, ${billingDetails?.address?.city}, ${billingDetails?.address?.state},  ${billingDetails?.address?.area_code}, ${billingDetails?.address?.country}`}{" "}
                  </p>
                </section>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2">
              <div className={styles.text_boxes}>
                <p style={{ fontWeight: "600", color: "black" }}>
                  PAN No: {orderDetails.pan_no}
                </p>
                <p style={{ fontWeight: "600", color: "black" }}>
                  GST Registration No: {orderDetails.gst_no}
                </p>
              </div>
              <div className={styles.text_boxes} style={{ textAlign: "end" }}>
                <h6 style={{ fontWeight: "600", color: "black" }}>
                  Shipping Address:
                </h6>
                <section>
                  <p className="text-capitalize">
                    {billingDetails?.address?.name}{" "}
                  </p>
                  <p className="m-0 p-0 text-capitalize">
                    {" "}
                    {`${billingDetails?.address?.locality}, ${billingDetails?.address?.city}, ${billingDetails?.address?.state},  ${billingDetails?.address?.area_code},   ${billingDetails?.address?.country}`}{" "}
                  </p>
                </section>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2">
              <div className={styles.text_boxes}>
                <p style={{ fontWeight: "600", color: "black" }}>
                  Order No: {param.order_id}
                </p>
                <p style={{ fontWeight: "600", color: "black" }}>
                  Order Date: {orderDetails?.created_at?.substring(0, 10)}
                </p>
              </div>
              <div className={styles.text_boxes} style={{ textAlign: "end" }}>
                <p style={{ fontWeight: "600", color: "black" }}>
                  Invoice No: CJB1-3890865{" "}
                </p>
                <p style={{ fontWeight: "600", color: "black" }}>
                  Invoice Date: {orderDetails?.created_at?.substring(0, 10)}
                </p>
              </div>
            </div>
            <div className="mt-4" style={{ overflow: "scroll" }}>
              <table>
                <thead className={styles.invoiceTableHead}>
                  <tr>
                    {TableHeads.map((item, index) => {
                      return (
                        <th style={{ textAlign: "center" }} key={index}>
                          <td style={{ border: "none", margin: "0 auto" }}>
                            <div>
                              <span>{item}</span>
                            </div>
                          </td>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {productDetails?.map((item, index) => {
                    let originalAmount = Math.round(
                      item?.price / (1 + item?.tax / 100)
                    );
                    let taxAmount = Math.round(item?.price * (item?.tax / 100));
                    let netAmount = originalAmount * item?.quantity;
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="w-75">{item?.product_name}</td>
                        <td>{item.price}</td>
                        <td>{item?.quantity}</td>
                        <td>{item?.tax}</td>
                        <td>{taxAmount}</td>
                        <td>{item?.price * item?.quantity}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div style={{ fontWeight: "600", color: "black" }}>
                <p
                  className="d-flex justify-content-end"
                  style={{ gap: "10px" }}
                >
                  <span>Delivery Charge</span>
                  <span>
                    {
                      logisticsDetails?.breakup[
                        logisticsDetails?.breakup.length - 2
                      ]?.price?.value
                    }
                    /-
                  </span>
                </p>
                <p
                  className="d-flex justify-content-end"
                  style={{ gap: "10px" }}
                >
                  <span>Packing Charge</span>
                  <span>
                    {
                      logisticsDetails?.breakup[
                        logisticsDetails?.breakup.length - 1
                      ]?.price?.value
                    }
                    /-
                  </span>
                </p>
                <p
                  className="d-flex justify-content-end"
                  style={{ gap: "10px" }}
                >
                  <span>Total Amount</span>
                  <span>Rs {paymentDetails?.params?.amount}</span>
                </p>
              </div>
              <div
                className="mb-2"
                style={{ fontWeight: "600", color: "black" }}
              >
                Amount In words:-
                <span className="text-capitalize">Rupees {Word} Only</span>
              </div>
              <div style={{ color: "black" }}>
                <div>
                  <p>For: {orderDetails.company_name} </p>
                  <p>Authorized Signatory</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: "100vw", height: "100vh" }}
        >
          <BeatLoader color="#0094ff" />
        </div>
      )}
      {!isLoading && (
        <Pbutton
          onClick={PDFGenerator}
          className="my-2"
          style={{ margin: "0 auto", display: "block" }}
        >
          Download Invoice
        </Pbutton>
      )}
    </>
  );
}
