// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thememaker_smallScreenSidebar__\\+6AlL {
  display: none;
}
@media screen and (max-width: 768px) {
  .thememaker_smallScreenSidebar__\\+6AlL {
    display: block;
  }
  .thememaker_bigScreenSidebar__FScOe {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/thememaker/thememaker.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE;IACE,cAAc;EAChB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".smallScreenSidebar {\n  display: none;\n}\n@media screen and (max-width: 768px) {\n  .smallScreenSidebar {\n    display: block;\n  }\n  .bigScreenSidebar {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallScreenSidebar": `thememaker_smallScreenSidebar__+6AlL`,
	"bigScreenSidebar": `thememaker_bigScreenSidebar__FScOe`
};
export default ___CSS_LOADER_EXPORT___;
