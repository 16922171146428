import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";

export default function LoginPageIsLogedIn({ children }: any) {
  const history = useHistory();
  const token: any = localStorage.getItem("isLogedIn");
  try {
    var decoded = jwtDecode(token);
    if (decoded) {
      history.push("/");
    } else {
      return children;
    }
  } catch (error) {
    return children;
  }
}
