import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import logo from "../../icons/savageseller-logo.jpg";
import { config } from "../../utils/config";
import CryptoJS from "crypto-js";

export default function Forgget() {
  const { apiEndpoint, baseNodeapiUrl } = config;
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    email: true,
  });
  const [secretKey, setSecretKey] = useState("yourSecretKey123");
  const [errorShow, seterrorShow] = useState(false);
  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams?.get("I5s3kVT3Yaqs67n");
  console.log(email);
  const decrypt = (encryptedText, key) => {
    const bytes = CryptoJS.AES.decrypt(encryptedText, key);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  };
  const decryptedEmail = decrypt(email, secretKey);
  async function handleSubmit(e) {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/checkemail`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { email: user.email },
      });
      if (data.status === 403) {
        toast.error("Mail Doesnot Exist In our Database!");
        return;
      }
      if (data.status === 200) {
        const { data } = await axios({
          url: `${baseNodeapiUrl}/admin/api/resetPassword`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: { email: user.email },
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong !");
      console.log(error);
    }
  }
  const rawData = {
    newPassword: "",
    confirmPassword: "",
  };
  const [passwordData, setpasswordData] = useState(rawData);
  function onChangeHandler(e) {
    const { newPassword, confirmPassword } = passwordData;
    setpasswordData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
    if (e.target.name == "confirmPassword") {
      if (e.target.value !== newPassword) {
        seterrorShow(true);
      } else {
        seterrorShow(false);
      }
    }
  }
  async function PasswordHandler() {
    const { newPassword, confirmPassword } = passwordData;
    if (!errorShow && newPassword !== "" && confirmPassword !== "") {
      const data = await axios({
        url: `${apiEndpoint}/resetpassword`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          password: passwordData?.confirmPassword,
          email: decryptedEmail,
        },
      });
      if (data.data.status == 200) {
        toast.success("Password Updated, Please Login With New Password");
        setTimeout(() => {
          history.push("/");
        }, 3000);
      } else {
        toast.error("Something Went Wrong");
      }
    } else {
      toast.error("Please Enter Fields Correctly!");
    }
  }
  return (
    <>
      <div className="container-fluid bg-dark" style={{ height: "100vh" }}>
        <div className="row justify-content-center">
          <div className="col-md-6 mt-4 mb-4">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center mb-3">
                        <img
                          src={logo}
                          style={{ height: "65px" }}
                          alt="logo here"
                        />
                      </div>
                      <div className="text-center d-none">
                        <h1 className="h4 text-gray-900 mb-2">
                          Forgot Your Password?
                        </h1>
                        <p className="mb-4"> Please Contact Administrator!</p>
                      </div>
                      <div className="text-center">
                        <h5
                          className="mb-4"
                          style={{ color: "black", fontWeight: "600" }}
                        >
                          {" "}
                          Reset Your Password
                        </h5>
                      </div>
                      <div className="user mb-4">
                        <div className="">
                          <div className="form-group">
                            <label className="genericlabel">New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="newPassword"
                              placeholder="Enter New Password"
                              onChange={onChangeHandler}
                            />
                          </div>
                        </div>
                        <div className="">
                          <div className="form-group">
                            <label className="genericlabel">
                              Confirm New Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              onChange={onChangeHandler}
                            />
                            {errorShow ? (
                              <span className="text-danger tiny_size">
                                {" "}
                                Password Mismatch !{" "}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {loading ? (
                          <button className="btn btn-primary btn-user btn-block mt-4 mb-4">
                            Loading...
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-user btn-block mt-4 mb-4"
                            onClick={PasswordHandler}
                          >
                            Update
                          </button>
                        )}
                      </div>
                      <hr />
                      <div className="text-center">
                        <Link className="small" to="/">
                          Already have an account? Login!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
