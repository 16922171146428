// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification_main__6Q6K0 {
  border-radius: 0.35rem;
  margin: 4px 2px;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);
  outline: none !important;
  min-height: 85px;
}

.Notification_notificationMsg__GThKj {
  color: #000000;
}
.Notification_notificationText__RnchZ {
  font-size: 17px;
}
.Notification_noNotification__WdqzM {
  font-size: 18px;
}
.Notification_notify__nw\\+qK {
  font-size: 14px;
  font-weight: bold;
}
.Notification_main__6Q6K0 .Notification_notificationIndex__odsbv {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #4e73df;
  /* background-color: #000000; */
  color: white;
  text-align: center;
  font-size: 18px;
}
.Notification_main__6Q6K0 .Notification_notificationIndex__odsbv .Notification_userName__71OBF {
  font-size: 18px;
  font-weight: 600;
  margin-top: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Notification/Notification.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,eAAe;EACf,mBAAmB;EACnB,wCAAwC;EACxC,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,+BAA+B;EAC/B,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".main {\n  border-radius: 0.35rem;\n  margin: 4px 2px;\n  align-items: center;\n  box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);\n  outline: none !important;\n  min-height: 85px;\n}\n\n.notificationMsg {\n  color: #000000;\n}\n.notificationText {\n  font-size: 17px;\n}\n.noNotification {\n  font-size: 18px;\n}\n.notify {\n  font-size: 14px;\n  font-weight: bold;\n}\n.main .notificationIndex {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  background-color: #4e73df;\n  /* background-color: #000000; */\n  color: white;\n  text-align: center;\n  font-size: 18px;\n}\n.main .notificationIndex .userName {\n  font-size: 18px;\n  font-weight: 600;\n  margin-top: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Notification_main__6Q6K0`,
	"notificationMsg": `Notification_notificationMsg__GThKj`,
	"notificationText": `Notification_notificationText__RnchZ`,
	"noNotification": `Notification_noNotification__WdqzM`,
	"notify": `Notification_notify__nw+qK`,
	"notificationIndex": `Notification_notificationIndex__odsbv`,
	"userName": `Notification_userName__71OBF`
};
export default ___CSS_LOADER_EXPORT___;
