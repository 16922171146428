import { useState, useEffect, useRef, memo } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import ThemeMaker from "../../components/thememaker";
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import { config } from "../../utils/config";
import styled from "styled-components";
import style from "../SellerDetails/Sellerdetails.module.css";
import { Paginated } from "../../components/Paginated";
import "../../index.css";

const { apiEndpoint } = config;

var $ = require("jquery");

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export default function Addcategory() {
  const decoded = tokenDecode();
  const { data } = decoded;
  const {
    permission: { category },
  } = data;
  const [listing, setListing] = useState(true);

  const [subcat, setSubcat] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [collectionData, setcollectionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [collection, setcollection] = useState({
    title: "",
    id: "",
  });

  const collectionD = {
    title: false,
  };
  const [collectionErrorData, setcollectionErrorData] = useState(collectionD);
  const errorHandler = (e) => {
    if (e.target.value) {
      setcollectionErrorData({
        ...collectionErrorData,
        [e.target.name]: false,
      });
    } else {
      setcollectionErrorData({ ...collectionErrorData, [e.target.name]: true });
    }
  };

  const forbiddenKeywords = [
    "Nike", "Jordan", "Adidas", "vape", "cannabis", "marijuana", "CBD", "THC",
    "weed", "firearm", "gun", "bullet", "dope", "pistol", "cleaning supplies",
    "cleaning equipment", "holsters", "riffle", "shotguns", "Air pistols",
    "sawa", "range finders", "rifle parts"
  ];
  
  const createcollectionHandler = async () => {
    try {
      setLoading(true);
      if (collection?.title) {
        const containsForbiddenKeyword = forbiddenKeywords.some(keyword =>
          collection.title.toLowerCase().includes(keyword.toLowerCase())
        );
  
        if (containsForbiddenKeyword) {
          toast.error("The collection title contains prohibited keywords!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const duplicate = collectionData.some(existingCollection =>
            existingCollection.title.toLowerCase() === collection.title.toLowerCase() ||
            existingCollection.handle.toLowerCase() === collection.title.toLowerCase().replace(/\s+/g, '-')
          );
  
          if (duplicate) {
            toast.error("A collection with the same title or handle already exists!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            const { data } = await axios({
              url: `${apiEndpoint}/categories/addcollectioninshopifystore`,
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-Shopify-Access-Token": "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",
              },
              data: {
                custom_collection: {
                  title: collection.title,
                },
              },
            });
  
            if (data.status === 201) {
              toast.success("New Category created !", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setcollection({
                title: "",
              });
            } else {
              toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        }
      } else {
        toast.error("Please fill the required field !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("403 Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const collectionListHanler = async () => {
    try {
      setListing(true);
      setSubcat(false);

      const { data } = await axios({
        url: `${apiEndpoint}/categories/getcollectionlistinshopifystore`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Access-Token": "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",
        },
      });

      if (data.status === 201) {
        setcollectionData(data.response);
        // console.log("collectionData",data.response);
      }
    } catch (error) {}
  };

  const setCrete = () => {
    setListing(false);
    setIsUpdate(false);
    setSubcat(false);
    setcollection({
      title: "",
    });
  };

  // const editRedirect = async (id, parentCatName) => {
  //   try {
  //     const { data } = await axios({
  //       url: `${apiEndpoint}/categories/getsinglecollectioninshopifystore`,
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-Shopify-Access-Token": "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",
  //       },
  //       data: {
  //         id: id,
  //       },
  //     });

  //     console.log("editRedirect data", data);
  //     if (data.status === 201) {
  //       setcollection({
  //         title: data?.response?.title,
  //         id: data?.response?.id,
  //       });
  //     }
  //   } catch (error) {}
  //   setIsUpdate(true);
  //   setListing(false);
  //   if (parentCatName !== "") {
  //     setSubcat(true);
  //   } else {
  //     setSubcat(false);
  //   }
  // };

  const updatecollection = async () => {
    try {
      if (collection?.title) {
        setLoading(true);
        const containsForbiddenKeyword = forbiddenKeywords.some(keyword =>
          collection?.title.toLowerCase().includes(keyword.toLowerCase())
        );
  
        if (containsForbiddenKeyword) {
          toast.error("The collection title contains prohibited keywords!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          const duplicate = collectionData.some(existingCollection =>
            (existingCollection.title.toLowerCase() === collection.title.toLowerCase() ||
            existingCollection.handle.toLowerCase() === collection.title.toLowerCase().replace(/\s+/g, '-')) &&
            existingCollection.id !== collection.id
          );
  
          if (duplicate) {
            toast.error("A category with the same title or handle already exists!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            const { data } = await axios({
              url: `${apiEndpoint}/categories/updatesinglecollectioninshopifystore`,
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-Shopify-Access-Token": "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",
              },
              data: {
                id: collection.id,
                custom_collection: {
                  title: collection.title,
                },
              },
            });
            if (data.status === 201) {
              toast.success("Category Updated !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error("403 Error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        }
      } else {
        toast.error("Please fill the required field !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("403 Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deletecollection = async (id, parent_id) => {
    try {
      setLoading(true);
      const { data } = await axios({
        url: `${apiEndpoint}/categories/deletesinglecollectioninshopifystore`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Access-Token": "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",
        },
        data: {
          id: id,
        },
      });
      if (data.status === 201) {
        toast.success("Category Deleted !", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      } else {
        toast.error("403 Error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("403 Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const COLUMNS = [
    {
      Header: "Serial No.",
      Footer: "Serial No.",
      accessor: (row, count) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "title",
      sticky: "left",
    }
      
    //   ,
    

    // {
    //   Header: "Action",
    //   Footer: "Action",
    //   accessor: (row) => {
    //     return (
    //       <>
    //         {/* <button
    //           className="btn-sm m-1 btn btn-success btn-circle"
    //           onClick={() => editRedirect(row.id)}
    //         >
    //           <i className="fas fa-pen"></i>
    //         </button> */}

    //         <button
    //           className="btn-sm m-1 btn btn-danger btn-circle"
    //           onClick={() => deletecollection(row.id)}
    //         >
    //           <i className="fas fa-trash"></i>
    //         </button>
    //       </>
    //     );
    //   },
    //   sticky: "left",
    // },
  ];

  useEffect(() => {
    collectionListHanler();
  }, []);
  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0">
          <div className="col-md-12 mt-2">
            <div className="card shadow mb-4" style={{ minHeight: "80vh" }}>
               <div className="card-header py-3 px-0 mt-2">
                <h6 className="m-0 font-weight-bold text-primary">
                  <div className={style.tabs}>
                    <button
                      className={
                        listing
                          ? `${style.tab} ${style.active}`
                          : `${style.tab}`
                      }
                      onClick={collectionListHanler}
                    >
                      Category list
                    </button>
                    <button
                      className={
                        !subcat && !listing
                          ? `${style.tab} ${style.active}`
                          : `${style.tab}`
                      }
                      onClick={setCrete}
                    >
                      New Category
                    </button>
                  </div>
                </h6>
              </div> 
              <div class="card-header py-3 d-flex justify-content-between">
                <h6 class="m-0 font-weight-bold text-primary">Category List</h6>
              </div>
              {listing ? (
                <>
                  {
                    <div className="">
                      <Paginated data={collectionData} columns={COLUMNS} />
                    </div>
                  }
                </>
              ) : (
                <>
                  <div className="card-body">
                  <p><b>Disclaimer:</b> Nike, Jordan, Adidas, vape, cannabis, marijuana, CBD, THC, weed, firearm, gun, bullet, dope, pistol, cleaning supplies, cleaning equipment, holsters, riffle, shotguns, Air pistols, sawa, range finders, rifle parts, etc. — the mentioned products are excluded from the listing.</p>

                    <div className="">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="genericlabel">Category name</label>
                          <span className="text-danger"> * </span>
                          {collectionErrorData?.title ? (
                            <span className="text-danger tiny_size">
                              {" "}
                              This field is required !{" "}
                            </span>
                          ) : null}
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            placeholder="Enter Category Name.."
                            value={collection.title}
                            onChange={(e) =>
                              setcollection({
                                ...collection,
                                [e.target.name]: e.target.value,
                              })
                            }
                            onBlur={(e) => errorHandler(e)}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        {!isUpdate && !listing ? (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={createcollectionHandler}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-success"
                            onClick={updatecollection}
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
