import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { Paginated } from "../../components/Paginated";
import Pagination from "../../components/Paginated/Pagination";
import "../../components/table.css";

const { apiEndpoint } = config;

var $ = require("jquery");

var count = 1;

export default function SellerNonApprovedlist() {
  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id;
  // console.log("sdlsadsa", decoded)
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [sproductList, setSproductList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [sellers, setSellers] = useState({
    seller: "",
  });
  const rawData = {
    seller_id: "",
  };
  const rawpData = {
    seller_id: "",
    min_price: "",
  };
  const rawsData = {
    seller_id: "",
    sku: "",
  };
  const [categories, setCategories] = useState();
  const [searchData, setSearchData] = useState(rawData);
  const [searchpData, setSearchpData] = useState(rawpData);
  const [searchsData, setSearchsData] = useState(rawsData);
  const [valuename, setValuename] = useState("");
  const [valueprice, setValueprice] = useState("");
  const [valuesku, setValuesku] = useState("");
  const [toggle, setToggle] = useState(false);
  const decode = tokenDecode();
  const { data } = decode;
  const {
    permission: {
      category,
      order,
      product,
      role,
      seller,
      tax,
      user,
      mastercatalog,
    },
  } = data;
  const categoryListing = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/listrejectProduct`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decode?.data?.user?.seller_id,
        },
      });
      if (data.status == 200) {
        setProductList(data?.data);
      }
    } catch (error) {}
  };
  const [quickUpdatedMaxPrice, setquickUpdatedMaxPrice] = useState("");
  const [quickUpdatedMaxPrice2, setquickUpdatedMaxPrice2] = useState("");
  const [openInput, setopenInput] = useState(null);
  const deleteProduct = async (id) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/deleterejectProduct`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { id },
      });
      if (data.status === 200) {
        setProductList(productList.filter((item) => item.id !== id));
        toast.success("Product deleted!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const COLUMNS = [
    {
      Header: "Image",
      Footer: "Image",
      accessor: (row) => {
        return (
          <img
            src={
              row.image
                ? row.image
                : "http://cdn.onlinewebfonts.com/svg/img_546302.png"
            }
            alt="ok"
            height={80}
            width={80}
          />
        );
      },
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Price",
      Footer: "Price",
      accessor: "mrp",
      sticky: "left",
    },
    {
      Header: "SKU",
      Footer: "SKU",
      accessor: "sku",
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (row) => {
        return <>{row.status ? "Active" : "Draft"}</>;
      },
      sticky: "left",
    },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row) => {
        return (
          <>
            {product.update ? (
              <Link
                to={`nonapprovedproduct/${row.id}`}
                className="btn-sm m-1 btn btn-success btn-circle"
              >
                <i className="fas fa-pen"></i>
              </Link>
            ) : null}
            {product.delete ? (
              <button
                className="btn-sm m-1 btn btn-danger btn-circle"
                onClick={() => deleteProduct(row.id)}
              >
                <i className="fas fa-trash"></i>
              </button>
            ) : null}
          </>
        );
      },
      sticky: "left",
    },
  ];
  count++;
  useEffect(() => {
    categoryListing();
  }, []);

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Non Approved Products
                </h6>
                <div className="search mt-1">
                  <div className="row"></div>
                </div>
              </div>
              <div className="card-body cardBodyHeight  p-2">
                <div className="table table-responsive ">
                  <div className="productListing_desktop">
                    <Paginated data={productList} columns={COLUMNS} />
                  </div>
                  <div className="productListing_mobile">
                    <Pagination
                      itemsPerPage={20}
                      items={productList}
                      deleteProduct={deleteProduct}
                      handle="nonapprovedproduct"
                      categoryListing={categoryListing}
                    ></Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
