import styles from "./Notification.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../../utils/config";
import { tokenDecode } from "../../utils/helper";
import CircularProgress from "@mui/material/CircularProgress";
import { format, isToday, isYesterday, isBefore, subDays, formatDistanceToNow, } from "date-fns";
const { apiEndpoint } = config;

export default function Notification() {
  const decode = tokenDecode();
  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let seller_email = decode?.data?.user?.email;

  useEffect(() => {
    setIsLoading(true);
    const fetchNotification = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/notification/getNotification`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: { seller_email: seller_email },
        });
        // console.log("user listing", data);

        if (data.status === 200) {
          const sortLatestFirst = data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          // console.log("sortLatestFirst", sortLatestFirst);
          setNotificationList(sortLatestFirst);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error", error);
      }
    };
    fetchNotification();
  }, [seller_email]);

  const formatTiming = (timing) => {
    const timingDate = new Date(timing);
    const now = new Date();

    if (isToday(timingDate)) {
      if (now - timingDate < 60 * 60 * 1000) {
        return `Today, ${format(timingDate, "hh:mm a")}`;
      } else {
        return `${formatDistanceToNow(timingDate, { addSuffix: true })}`;
      }
    } else if (isYesterday(timingDate)) {
      return `Yesterday, ${format(timingDate, "hh:mm a")}`;
    } else if (isBefore(timingDate, subDays(now, 6))) {
      return format(timingDate, "MMMM dd, yyyy");
    } else {
      return `${format(timingDate, "EEEE, hh:mm a")}`;
    }
  };

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const getNameInitials = (user) => {
    const nameParts = user.name.split(" ");
    const firstLetters = nameParts.map((part) => part[0]).join("");
    return firstLetters.toUpperCase(); 
  };

  return (
    <>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "60vh" }}
        >
          <CircularProgress />
        </div>
      ) : notificationList.length > 0 ? (
        notificationList.map((item) => (
          <div key={item._id} className={`d-flex ${styles.main} bg-light p-3`}>
          {console.log("item__",item.name)}
            <div className="d-flex mr-2">
              <div className={`${styles.notificationIndex}`}>
                <span className={`${styles.userName}`}>{getNameInitials(item)}</span>
              </div>
            </div>
            <div
              className={`ml-2 d-flex text-left flex-column  ${styles.notificationMsg}`}
            >
              <span className={`${styles.notificationText}`}>
                {capitalizeFirstLetter(item.name) +
                  " wants to buy " +
                  item.content +
                  ". Please connect with him/her using email: " +
                  item.customer_email +
                  " or phone number: " +
                  item.customer_phone}
              </span>
              <span className={`mt-2 ${styles.notify}`}>
                {formatTiming(item.createdAt)}
              </span>
            </div>
          </div>
        ))
      ) : (
        <div
          className={`d-flex align-items-center justify-content-center text-dark ${styles.noNotification}`}
        >
          No notifications found
        </div>
      )}
    </>
  );
}
