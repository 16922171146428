import { useState } from "react";
import ReactPaginate from "react-paginate";
import ProductTemplate from "../ProductViewTemplate/ProductTemplate";
import "./pagination.css";

export default function Pagination({
  itemsPerPage,
  items,
  deleteProduct,
  handle,
  categoryListing,
}) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };
  return (
    <>
      <ProductTemplate
        data={currentItems}
        deleteProduct={deleteProduct}
        handle={handle}
        categoryListing={categoryListing}
      ></ProductTemplate>
      <div className="pagination_main">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          activeLinkClassName="current_active"
        />
      </div>
    </>
  );
}
