// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Invoice_inv_con__aGmi1 {
  max-width: 1024px;
  margin: 0 auto;
  padding: 10px;
}
.Invoice_inv_con__aGmi1 p,
h6 {
  margin: 0 !important;
  padding: 0 !important;
}
.Invoice_inv_con__aGmi1 .Invoice_text_boxes__x859a {
  width: 33%;
}
.Invoice_invoiceTableHead__2tLkV th {
  background-color: grey !important;
}
.Invoice_invoiceTableHead__2tLkV {
  white-space: wrap;
}
@media screen and (max-width: 720px) {
  .Invoice_inv_con__aGmi1 .Invoice_text_boxes__x859a {
    width: 46%;
  }
  .Invoice_inv_con__aGmi1 {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Invoice/Invoice.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;AACf;AACA;;EAEE,oBAAoB;EACpB,qBAAqB;AACvB;AACA;EACE,UAAU;AACZ;AACA;EACE,iCAAiC;AACnC;AACA;EACE,iBAAiB;AACnB;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".inv_con {\n  max-width: 1024px;\n  margin: 0 auto;\n  padding: 10px;\n}\n.inv_con p,\nh6 {\n  margin: 0 !important;\n  padding: 0 !important;\n}\n.inv_con .text_boxes {\n  width: 33%;\n}\n.invoiceTableHead th {\n  background-color: grey !important;\n}\n.invoiceTableHead {\n  white-space: wrap;\n}\n@media screen and (max-width: 720px) {\n  .inv_con .text_boxes {\n    width: 46%;\n  }\n  .inv_con {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inv_con": `Invoice_inv_con__aGmi1`,
	"text_boxes": `Invoice_text_boxes__x859a`,
	"invoiceTableHead": `Invoice_invoiceTableHead__2tLkV`
};
export default ___CSS_LOADER_EXPORT___;
