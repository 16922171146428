import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../../utils/config";
import { toast } from "react-toastify";

const { apiEndpoint,communityUrl } = config;
const accessToken = localStorage.getItem("us_st_d");

interface CoreState {
  loading?: boolean;
  data?: any;
  error?: any;
}

const initialState: CoreState = {
  loading: false,
  data: "",
  error: "",
};

export const registerSeller = createAsyncThunk(
  "seller/register",
  async (_data: any) => {
    try {
      // console.log("accessTokenaccessToken", accessToken);

      const { data } = await axios({
        url: `${apiEndpoint}/seller/sellerregister`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: _data,
      });
      //const { status } = data;
      console.log("asyy", data.status);

      if (data.status === 200) {
        await axios.post(`${communityUrl}/user/getuserbyemailformultivendor`, {
          name: _data.name,
          email: _data.email,
          phone: _data.contact_no,
          password: "12345678", // Hardcoded password for multivendor API, adjust if needed
          location: _data.personal_address,
          hero_img: _data.profile_image,
        });
        toast.success("New seller registered !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return true;
      }
      if (data.status == 301) {
        toast.warning("Already registered !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
    } catch (error: any) {
      return false;
    }
  }
);

const sellerSlice = createSlice({
  name: "seller",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerSeller.pending, (state, action) => {
      state.loading = true;
      state.data = "";
      state.error = "";
    });
    builder.addCase(registerSeller.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(registerSeller.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = "error";
    });
  },
});

export default sellerSlice.reducer;
