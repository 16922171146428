import React, { useEffect, useState } from 'react'
import html2pdf from 'html2pdf.js';
import styles from "./Invoice.module.css"
import axios from "axios"
import { useParams } from 'react-router-dom';
import { config } from "../../utils/config";
import { tokenDecode } from "../../utils/helper";
import BeatLoader from "react-spinners/BeatLoader";
import styled from "styled-components"
import moment from  "moment"
import { numberToWords } from 'number-to-words';
import { IoLogoUsd } from "react-icons/io";
const Pbutton = styled.button
    `
outline: none;
border: none;
margin-left: 18px;
background: #0094ff;
padding: 3px 5px;

`


// type MyComponentProps = {
//     orderId: any;
// };

const Invoice = (props) => {

    const param = useParams()
    const decoded = tokenDecode();
    const { data } = decoded;

    // console.log(data)
    const { apiEndpoint } = config;
    const [orderDetails, setOrderDetails] = useState([])
    const [productDetails, setproductDetails] = useState([])
    const [billingDetails, setbillingDetails] = useState([])
    const [paymentDetails, setpaymentDetails] = useState([])
    const [logisticsDetails, setlogisticsDetails] = useState([])
    const [isLoading, setisLoading] = useState(true)
    const [Word, setWord] = useState("")
    useEffect(() => {
        if (props.orderId) {
            getOrderDetails()
        }
    }, [])

    useEffect(() => {
        convertAmountToWord(parseInt(paymentDetails?.params?.amount))
    }, [paymentDetails])


    function convertAmountToWord(number) {
        const first = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
        const tens = ['', '', 'twenty ', 'thirty ', 'forty ', 'fifty ', 'sixty ', 'seventy ', 'eighty ', 'ninety '];
        const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
        let word = "";

        for (let i = 0; i < mad.length; i++) {
            let tempNumber = number % (100 * Math.pow(1000, i));
            if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
                if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
                    word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + ' ' + word;
                } else {
                    word = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + mad[i] + ' ' + word;
                }
            }

            tempNumber = number % (Math.pow(1000, i + 1));
            if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hunderd ' + word;
        }
        setWord(word)
    }

    let SellerId = decoded?.data?.user?.seller_id;
    let pannumber=decoded?.data?.seller_data?.pan_no;
    let gstnumber=decoded?.data?.seller_data?.gst_no;
    async function getOrderDetails() {
        const { data } = await axios({
            url: `${apiEndpoint}/order/getOrderById/${props.orderId}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
            },

        });
        if (data.status == 200) {
            setOrderDetails(data.response)
            setproductDetails(data?.line_items)
            setbillingDetails(data.response.billing_address)
            setpaymentDetails(data.response.current_total_price)
            setisLoading(false)
            setlogisticsDetails(data.response.quote)
        }

        // console.log("order data--->", data)

    }
    // console.log("item data--->", paymentDetails)


    function PDFGenerator() {

        const element = document.getElementById('page-content'); // ID of the element containing the page content
        html2pdf().set({ margin: 0, filename: 'page.pdf', image: { type: 'jpeg', quality: 1.0 } }).from(element).save();
    }


    const TableHeads = ["S No", "Description", "Price in USD", "Qty", "Tax Rate (in %)", "Tax Amount in USD", "Total Amount in USD"]


    return (
        <>
            {
                !isLoading ? <div id='page-content' className={styles.inv_con}>
                {orderDetails.map((item,index)=>{
                    const price = item.current_total_price;
                    const integerPart = Math.floor(price);
                    const decimalPart = Math.round((price - integerPart) * 100); // Get the decimal part and round it to avoid floating-point errors
                    const integerInWords = numberToWords.toWords(integerPart);
                    const decimalInWords = numberToWords.toWords(decimalPart);
                  console.log(item.id)
                    let priceInWords = `${integerInWords}`;
                    if (decimalPart > 0) {
                      priceInWords += ` and ${decimalInWords}`;
                    }
                return(
                    <div style={{ border: "1px solid black", padding: "20px" }}>
                        <div className='d-flex justify-content-between'>
                            <img src="" alt="" />
                            <section>
                                <p style={{ fontWeight: "600", color: "black" }}>Tax Invoice/Bill of Supply/Cash Memo</p>
                                <p>Original for Recipient</p>
                            </section>

                        </div>

                        <div className='w-25' style={{ fontSize: "9px" }}>
                            {/* Digitally Signed by <span> {data.seller_data.company_name} </span> <br /> */}
                            {/* Date: <span> {orderDetails?.created_at} 2021.12.31 03:00:09 UTC </span> <br /> */}
                            {/* Reason: Invoice */}
                        </div>

                        <div className='d-flex justify-content-between mt-4'>

                            <div className={styles.text_boxes}>
                                <h6 style={{ fontWeight: "600", color: "black" }}>Sold By: {item?.seller_company_name}</h6>
                                <section>
                                   
                                    <p >{`${item.billing_address.address1}, India`}</p>
                                </section>
                            </div>

                            <div className={styles.text_boxes} style={{ textAlign: "end" }}>
                                <h6 className='m-0 p-0' style={{ fontWeight: "600", color: "black" }}>Billing Address:</h6>
                                <section>
                                    <p className='text-capitalize'>{item.billing_address.address1} </p>
                                    <p className='m-0 p-0 text-capitalize'> {` ${item.billing_address.city}, ${item.billing_address.province},  ${item.billing_address.zip}, ${item.billing_address.country}`
                                    } </p>
                                </section>
                            </div>

                        </div>
                        <div className='d-flex justify-content-between mt-2'>

                        <div className={styles.text_boxes}>
                        <p style={{ fontWeight: "600", color: "black" }}>Invoice No: #{item.order_number}  </p>
                        <p style={{ fontWeight: "600", color: "black" }}>Invoice Date: {moment.utc(item.billing_address.created_at).format("DD-MM-YYYY  HH:mm:ss")}</p>
                    </div>
{item.shipping_address?
                            <div className={styles.text_boxes} style={{ textAlign: "end" }}>
                                <h6 style={{ fontWeight: "600", color: "black" }}>Shipping Address:</h6>
                                <section>
                                    <p className='text-capitalize'>{item.shipping_address.address1} </p>
                                    <p className='m-0 p-0 text-capitalize'> {` ${item.shipping_address.city}, ${item.shipping_address.province},  ${item.shipping_address.zip},   ${item.shipping_address.country}`
                                    } </p>
                                </section>
                            </div>:""}

                        </div>


                        <div className='d-flex justify-content-between mt-2'>

                            <div className={styles.text_boxes}>
                                <p style={{ fontWeight: "600", color: "black" }}>Order No: {item.id}</p>
                                <p style={{ fontWeight: "600", color: "black" }}>Order Date: {moment.utc(item.billing_address.created_at).format("DD-MM-YYYY  HH:mm:ss")}</p>

                            </div>

                            

                        </div>

                        <div className='mt-4' style={{ overflow: "scroll" }}>
                            <table>
                                <thead className={styles.invoiceTableHead}>
                                    <tr>
                                        {
                                            TableHeads.map((item, index) => {
                                                return (<th style={{ textAlign: 'center' }} key={index}>
                                                    <td style={{ border: "none", margin: "0 auto" }}>
                                                        <div>
                                                            <span>{item}</span>
                                                        </div>
                                                    </td>
                                                </th>)
                                            })
                                        }
                                    </tr>
                                </thead>

                                <tbody>
                                {orderDetails.map((item, index) => {
                                        let originalAmount = Math.round((item?.price / (1 + (item?.tax / 100))))
                                        let taxAmount = Math.round(item?.price * (item?.tax / 100));
                                        let netAmount = originalAmount * item?.quantity
                                        let shipping=item?.shipping_lines[0]?.discounted_price
                                        return (
                                            item.line_items.map((item,index)=>{
                                                return(
                                            <tr key={index}>

                                                <td>{index + 1}</td>

                                                <td className='w-75'>{item.name}</td>
                                                <td className=''><IoLogoUsd />{parseFloat(item.price)}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item?.tax_lines[0]?.rate?item?.tax_lines[0]?.rate:0}</td>
                                                <td>{item?.tax_lines[0]?.price?item?.tax_lines[0]?.price:0}</td>
                                               
                                                <td className=''><IoLogoUsd />{(parseFloat(item?.price) * item?.quantity) + (item?.tax_lines[0]?.rate ? item?.tax_lines[0]?.rate : 0)}</td>
                                            </tr>)
                                                })
                                        )
                                    })}



                                </tbody>
                            </table>
                            <div style={{ fontWeight: "600", color: "black" }} >
                                
                                <p className='d-flex justify-content-end' style={{ gap: "10px" }}>
                                <span>Shipping Charges: </span>
                                <span className='d-flex justify-content-center align-items-center'><IoLogoUsd /> {(item.current_total_price-item.subtotal_price).toFixed(2)}</span>
                                    <br /><span>Total Amount: </span>
                                    <span className='d-flex justify-content-center align-items-center'><IoLogoUsd />
                                         {item.total_price}
                                    </span>
                                </p>
                            </div>
                            <div style={{ fontWeight: "600", color: "black" }} >
                            {item?.note ? (
                                <div className="mb-0 genericlabel">
                                  {item?.note ? (
                                <>
                                  {item?.note.includes("Pickup:") && (
                                    <p>
                                      <span>
                                      <strong>Pickup:</strong> {item?.note.split("Pickup:")[1].split("Drop off:")[0].trim()}
                                      </span>
                                    </p>
                                  )}
                              
                                  {item?.note.includes("Drop off:") && (
                                    <p>
                                      <span>
                                      <strong> Drop off:</strong> {item?.note.split("Drop off:")[1].split("Meet-up:")[0].trim()}
                                      </span>
                                    </p>
                                  )}
                              
                                  {item?.note.includes("Meet-up:") && (
                                    <p>
                                      <span>
                                        <strong>Meet-up:</strong> {item?.note.split("Meet-up:")[1].trim()}
                                      </span>
                                    </p>
                                  )}
                                </>
                              ) : (
                                ``
                              )}
                              
                                </div>
                              ) : (
                                ``
                              )}
                            </div>

                           {/* <div className='mb-2' style={{ fontWeight: "600", color: "black" }}>
                                Amount In words:-
                                <span className='text-capitalize'>Rupees {priceInWords} Only</span>
                                </div>*/}
                                <br/>
                            <div style={{ color: "black" }}>
                                <div>
                                    <p>For: { decoded?.data?.seller_data?.company_name} </p>
                                    <p>Authorized Signatory</p>
                                    
                                   
                                </div>
                            </div>
                        </div>


                    </div>
                )
                })}
                </div> : <div className='d-flex align-items-center justify-content-center' style={{ width: "100vw", height: "100vh" }}><BeatLoader color="#0094ff" /></div>

            }
            {!isLoading && <Pbutton onClick={PDFGenerator} className='my-2' style={{ margin: "0 auto", display: "block" }}>Download Invoice</Pbutton>}

        </>
    )
}

export default Invoice
