import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../../utils/config";
import { toast } from "react-toastify";

const { apiEndpoint } = config;

interface CoreState {
  loading?: boolean;
  data?: any;
  error?: any;
}

const initialState: CoreState = {
  loading: false,
  data: "",
  error: "",
};

export const checkLogin = createAsyncThunk(
  "login/checkLogin",
  async (cred: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/login`,
        method: "POST",
        data: cred,
      });
      console.log(data);
      //const { token, status } = data;
      if (data.status == 200) {
        localStorage.setItem("us_st_d", data.data);
        // console.log("data", data);
        return data.data;
      } else {
        toast.error("Wrong credentials !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
    } catch (error: any) {
      // console.log("errrr-------", error);
      return false;
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkLogin.pending, (state, action) => {
      state.loading = true;
      state.data = "";
      state.error = "";
    });
    builder.addCase(checkLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(checkLogin.rejected, (state, action) => {
      state.loading = false;
      state.data = "";
      state.error = "error";
    });
  },
});

export default loginSlice.reducer;
