import { useState, useEffect } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import ThemeMaker from "../../components/thememaker";
import TaxlistTemplate from "../../components/TaxlistTemplate/TaxlistTemplate";
import { toast } from "react-toastify";
import { config } from "../../utils/config";
import styles from "../transaction/transaction.module.css";
import btnstyle from "../../components/Buttons/Buttons.module.css";
import "../../index.css";

const { apiEndpoint } = config;
var $ = require("jquery");

export default function Tax() {
  const decode = tokenDecode();
  const { data } = decode;
  const {
    permission: { tax: taxPermission },
  } = data;
  const [listing, setListing] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [listingLoading, setListingLoading] = useState(false);
  const [taxData, setTaxData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tax, setTax] = useState({
    tax: "",
    status: true,
    id: "",
  });

  const createTaxHandler = async () => {
    try {
      setLoading(true);
      console.log("tax", tax);

      const { data } = await axios({
        url: `${apiEndpoint}/tax/addtax`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          tax_rate: tax.tax,
          status: tax.status,
        },
      });
      console.log("data---", data);
      if (data.status === 200) {
        toast.success("New Tax added successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 Error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("403 Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const taxListHanler = async () => {
    try {
      setListing(true);
      setListingLoading(true);
      const { data } = await axios({
        url: `${apiEndpoint}/tax/gettaxlist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      // console.log("tax listing", data);
      setListingLoading(false);
      if (data.status === 200) {
        setTaxData(data.data);
      }
    } catch (error) {
      setListingLoading(false);
    }
  };
  const setCrete = () => {
    setListing(false);
    setIsUpdate(false);
    setTax({
      tax: "",
      status: true,
      id: "",
    });
  };
  const editRedirect = async (id: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/tax/gettax`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          id: id,
        },
      });
      console.log("editRedirect data", data);
      if (data.status === 200) {
        setTax({
          tax: data?.data?.tax_rate,
          status: data?.data?.status,
          id: data?.data?.id,
        });
      }
    } catch (error) {}
    setIsUpdate(true);
    setListing(false);
  };

  const updateTax = async () => {
    try {
      setLoading(true);
      console.log("tax", tax);
      const { data } = await axios({
        url: `${apiEndpoint}/tax/updatetax`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          tax_rate: tax.tax,
          status: tax.status,
          id: tax.id,
        },
      });
      console.log("data----", data);
      if (data.status === 200) {
        toast.success("Tax Updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 Error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("403 Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteTax = async (id: any) => {
    try {
      setLoading(true);
      console.log("tax", tax);
      const { data } = await axios({
        url: `${apiEndpoint}/tax/deletetax`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { id },
      });
      console.log("data----", data);
      if (data.status === 200) {
        setTaxData(taxData.filter((item: any) => item.id !== id));
        toast.success("Tax Deleted !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 Error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("403 Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  useEffect(() => {}, []);
  return (
    <>
      <ThemeMaker>
        <div className="row m-0 mt-1 w-100">
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6
                  className="m-0 font-weight-bold text-primary d-flex justify-content-center "
                  style={{ gap: "10px" }}
                >
                  <button className={btnstyle.button} onClick={setCrete}>
                    Create new tax
                  </button>
                  <button className={btnstyle.button} onClick={taxListHanler}>
                    Tax list
                  </button>
                </h6>
              </div>
              {listing ? (
                <>
                  <div
                    className={`card-body ${styles.transactionListing_desktop}`}
                  >
                    <div className="table table-responsive table-bordered">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>Serial No.</th>
                            <th>Rate</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {taxData.map((item: any, index: any) => (
                            <>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.tax_rate}</td>
                                <td>
                                  {item?.status ? (
                                    <span className="badge badge-success">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      Deactive
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <div className="row justify-content-center">
                                    <div className="col-md-2">
                                      {taxPermission?.update ? (
                                        <button
                                          className="btn btn-success btn-circle"
                                          onClick={() => editRedirect(item.id)}
                                        >
                                          <i className="fas fa-eye"></i>
                                        </button>
                                      ) : null}
                                    </div>
                                    <div className="col-md-1">
                                      {taxPermission?.delete ? (
                                        <button
                                          className="btn btn-danger btn-circle"
                                          onClick={() => deleteTax(item.id)}
                                        >
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={`${styles.transactionListing_mobile}`}
                    style={{ padding: "10px" }}
                  >
                    {" "}
                    {taxData.map((item, index) => {
                      return (
                        <TaxlistTemplate
                          item={item}
                          index={index}
                          editRedirect={editRedirect}
                          deleteTax={deleteTax}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="card-body" style={{ minHeight: "70vh" }}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="genericlabel">Percentage</label>
                        <input
                          type="number"
                          className="form-control"
                          name="tax"
                          placeholder="Percentage"
                          value={tax.tax}
                          onChange={(e) =>
                            setTax({ ...tax, [e.target.name]: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="genericlabel">Status</label>
                        <input
                          type="checkbox"
                          checked={tax.status}
                          name="status"
                          placeholder="Name"
                          value=""
                          onChange={(e) =>
                            setTax({ ...tax, [e.target.name]: !tax.status })
                          }
                          style={{ marginLeft: "10px" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      {!isUpdate && !listing ? (
                        <button
                          type="submit"
                          onClick={createTaxHandler}
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={updateTax}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
