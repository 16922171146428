/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import "./switch.css";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import orderImg from "../../icons/order.png";
import moment from "moment";
import { useEffect, useState } from "react";
// import orderDetail from "./orderCompleteDetail";
import searchicon_black from "../../icons/searchicon_black.png";
import style from "../../components/Search&Options/Search&Options.module.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ThemeMaker from "../../components/thememaker";
import { Paginated } from "../../components/Paginated";
import * as XLSX from "xlsx";
import { IoLogoUsd } from "react-icons/io";
// import InputTemplate from "../../components/Search&Options/InputTemplate";

export default function customerOrder() {
  const decoded: any = tokenDecode();
  const [orderList, setOrderList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [billings, setBillings]: any = useState([]);
  const [items, setItems]: any = useState([]);
  const [payment, setPayment]: any = useState([]);
  const [quote, setQuote]: any = useState([]);
  const [allorders, setallorders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apiEndpoint } = config;
  const [toggle, setToggle] = useState(false);
  const rawData = {
    provider_id: "",
  };
  const [currentStatus, setCurrentStatus] = useState([]);
  const [orderDetail, setorderDetail]: any = useState([]);
  const [_orderDetail, _setorderDetail]: any = useState({
    billing_address: "",
    contact_number: "",
    created_at: "",
    email: "",
    id: "",
    name: "",
    order_id: "",
    order_state: "",
    provider_id: "",
    shipping_address: "",
    status: "",
    total_price: 0,
  });
  const [updateOrder_, setUpdateOrder_]: any = useState({
    order_id: "",
    tracking_url: "",
    order_state: "",
  });
  const [searchData, setSearchData]: any = useState(rawData);
  const [sorderList, setSorderList] = useState([]);

  const [valuename, setValuename] = useState("");
  const [valueid, setValueid] = useState("");
  const [valueemail, setValueemail] = useState("");
  const [valuecontact, setValuecontact] = useState("");
  const [valuestatus, setValuestatus] = useState("");
  const [valueamount, setValueamount] = useState("");
  const [valuedate, setValuedate] = useState("");
  const { data } = decoded;
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;

  const fetchSellerList = async () => {
    setIsLoading(true);
    let parentId = decoded?.data?.user?.parent_id;
    let SellerId = decoded?.data?.user?.seller_id;
    let payload;
    if (parentId > 1) {
      payload = { seller_id: parentId };
    } else {
      payload = { seller_id: SellerId };
    }
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/order/getOrderBySellerId/${SellerId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        setOrderList(data.response);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const getorderDetails = async (order_id: any) => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerorder`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          order_id,
        },
      });
      // console.log("order detail ->------   ", data);
      if (data.status === 200) {
        setIsLoading(false);
        setorderDetail(data.products);
        let totalP = 0;
        data.products.forEach((item: any) => {
          totalP += parseFloat(item?.price);
        });
        setUpdateOrder_({
          ...updateOrder_,
          order_id: data.data[0]?.order_id,
          tracking_url: data.data[0]?.tracking_url,
          order_state: data.data[0]?.order_state,
        });
        _setorderDetail({
          ..._orderDetail,
          ...data.data[0],
          total_price: totalP,
          billing_address: JSON.parse(data.data[0]?.billing),
          shipping_address: JSON.parse(data.data[0]?.fulfillments),
        });
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const updateOrder = async (order_id: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: updateOrder_,
      });
      // console.log(data, "datadata");

      if (data.message === "flag1") {
        toast.success("Order updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchSellerList();
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("update error", error);
      toast.error("404 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // window.location.reload();
    }
  };
  const getSellerList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/getsellerlist`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        // console.log(data.data);
        setSellerList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const inputHandler = async (e: any) => {
    setValuename("");
    setValueid("");
    setValueemail("");
    setValuecontact("");
    setValuestatus("");
    setValueamount("");
    setValuedate("");
    var optionvalue = e.target.value;
    if (optionvalue) {
      try {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbyseller`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data);
          setOrderList(data.data);
          setSorderList(data.data);
          setToggle(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setOrderList(sorderList);
    }
  };
  const inputeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        const { provider_id } = searchData;
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            name: optionvalue,
            order_status: "open-order",
          },
        });

        // console.log({
        //   data: {
        //     provider_id: { searchData },
        //     name: optionvalue,
        //   },
        // });

        if (data.status === 200) {
          // console.log(data);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/order/filterOrderById/${optionvalue}`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            name: optionvalue,
            order_status: "open-order",
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputoHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            order_id: optionvalue,
            order_status: "Accepted, Created, In-progress",
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log("line 332", data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputooHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/order/filterOrderById/${optionvalue}`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            order_id: optionvalue,
            order_status: "open-order",
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputemHandler = async (e: any) => {
    const optionvalue = e.target.value;
    // console.log(optionvalue);
    setValueemail(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            email: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeemHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueemail(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/order/filterOrderById/${optionvalue}`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            email: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputcHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            contact_number: optionvalue,
            order_status: "open-order",
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputccHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/order/filterOrderById/${optionvalue}`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            contact_number: optionvalue,
            order_status: "open-order",
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const inputdHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            created_at: optionvalue,
            order_status: "open-order",
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputddHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/order/filterOrderById/${optionvalue}`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            created_at: optionvalue,
            order_status: "open-order",
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const inputpHandler = async (e: any) => {
    const optionvalue = e.target.value;
    // console.log(optionvalue)
    setValuestatus(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            status: optionvalue,
            order_status: "open-order",
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response)
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };

  const inputppHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuestatus(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/order/filterOrderById/${optionvalue}`,
          method: "GET",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            status: optionvalue,
            order_status: "open-order",
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const getVendorOrderList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/order/getVendorOrderList`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        setCurrentStatus(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const currentOrderState = currentStatus.reduce((acc: any, item: any) => {
    acc[item.order_id] = item.order_state;
    return acc;
  }, {});

  const COLUMNS = [
    {
      Header: "Serial No.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Order ID",
      accessor: (a: any) => (a?.id ? a?.id : null),
      sticky: "left",
    },
    {
      Header: "Order Status",
      accessor: (row: any) => {
        const currentRowId = String(row.id);
        if (currentOrderState[currentRowId]) {
          return currentOrderState[currentRowId];
        }
        if (row.financial_status === "voided") {
          return "Canceled";
        } else if (row.fulfillment_status === null) {
          return "Accepted";
        } else if (
          row.fulfillment_status === "fulfilled" &&
          row?.fulfillments.length > 0 &&
          row?.fulfillments[0].shipment_status === "delivered"
        ) {
          return "Completed";
        } else if (
          row.fulfillment_status === "fulfilled" &&
          row?.fulfillments.length > 0 &&
          (row?.fulfillments[0].shipment_status === null ||
            row?.fulfillments[0].shipment_status === "in_transit" ||
            row?.fulfillments[0].shipment_status === "out_for_delivery")
        ) {
          return "In-progress";
        } else {
          return "";
        }
      },
      sticky: "left",
    },
    {
      Header: "Item",
      accessor: (row: any) => row.line_items.length,
      sticky: "left",
    },
    {
      Header: "Customer Name",
      accessor: (row: any) => row.billing_address.name,
      sticky: "left",
    },
    {
      Header: "Total (In USD)",

      accessor: (row: any, count: any) => {
        return (
          <span className="d-flex justify-content-center align-items-center">
            {" "}
            <IoLogoUsd />
            {row.current_total_price}
          </span>
        );
      },
      sticky: "left",
    },
    {
      Header: "Payment Status",
      accessor: (row: any) => {
        return <>{row.financial_status}</>;
      },
      sticky: "left",
    },
    {
      Header: "Created Date",
      accessor: (row: any) => {
        if (row?.created_at) {
          return moment.utc(row?.created_at).format("DD-MM-YYYY  HH:mm:ss");
        } else {
          return "";
        }
      },
      sticky: "left",
    },

    {
      Header: "Action",
      accessor: (row: any) => {
        return (
          <>
            <Link
              className="text-dark"
              to={`./vendororderCompleteDetail/${row.id}`}
            >
              {" "}
              <span
                className="badge badge-primary"
                style={{ fontSize: "15px", fontWeight: "400" }}
              >
                Get Order Details
              </span>
            </Link>
          </>
        );
      },
      sticky: "left",
    },
  ];

  const exportorders = async () => {
    let payload;
    let sellerId = decoded?.data?.user?.seller_id;
    if (sellerId == 1 || sellerId > 1) {
      payload = sellerId;
    }

    if (sellerId == 0) {
      payload = decoded?.data?.user?.parent_id;
    }

    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getallstatusorder`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: payload,
        },
      });
      // console.log("order data -> ", data);
      if (data.status === 200) {
        setallorders(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const exportToExcel = (orders: any) => {
    // Define custom column headers for the Excel file
    const headers = [
      "S.No",
      "Order Id",
      "Created At",
      "Seller Name",
      "Order State",
      "Customer Name",
      "Customer Email",
      "Customer Contact",
      "Status",
      "Item Count",
      "Order Total in USD",
    ];

    // Prepare data with just the values to be displayed in the columns
    const data = orderList.map((order: any, index: any) => {
      let orderCreatedDate = moment(order?.created_at).format(
        "DD-MM-YYYY hh:mm:ss"
      );
      let sellerName = order?.address?.start?.location?.descriptor?.name;
      let sellerEmail = order?.address?.end?.contact?.email;
      let sellerContact = order?.address?.end?.contact?.phone;
      let orderstatus;
      if (order.financial_status === "voided") {
        orderstatus = "Canceled";
      } else if (order.fulfillment_status === null) {
        orderstatus = "Accepted";
      } else if (
        order.fulfillment_status === "fulfilled" &&
        order?.fulfillments.length > 0 &&
        order?.fulfillments[0].shipment_status === "delivered"
      ) {
        orderstatus = "Completed";
      } else if (
        order.fulfillment_status === "fulfilled" &&
        order?.fulfillments.length > 0 &&
        (order?.fulfillments[0].shipment_status === null ||
          order?.fulfillments[0].shipment_status === "in_transit" ||
          order?.fulfillments[0].shipment_status === "out_for_delivery")
      ) {
        orderstatus = "In-progress";
      } else {
        orderstatus = "";
      }
      return {
        "S.No": index + 1,
        "Order Id": order.id,
        "Created At": orderCreatedDate,
        "Seller Name": decoded?.data?.user?.name,
        "Order State": order.billing_address.province,
        "Customer Name": order.billing_address.name,
        "Customer Email": order.email,
        "Customer Contact": order.customer.phone,
        Status: orderstatus,
        "Item Count": order.line_items.length,
        "Order Total in USD": `$${order.current_total_price}`,
      };
    });

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Orders");

    // Generate a unique name for the Excel file (you can customize this as needed)
    const fileName = `allorders_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, fileName);
  };

  useEffect(() => {
    fetchSellerList();
    getSellerList();
    getVendorOrderList();
    //exportorders();
  }, []);
  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-2">
          <div className="col-md-12 ">
            <div className="card shadow mb-4 mt-1">
              <div className="card-header py-3">
                <div className="row d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Customer Order List
                  </h6>

                  <div className={`search mt-1`}>
                    <div className={`row d-flex justify-content-start mb-1`}>
                      <div className="col-12 col-sm-12 col-md-8 mb-1">
                        <input
                          className="form-control"
                          type="search"
                          name="order_id"
                          placeholder="Enter Order ID"
                          onChange={(e) => {
                            seller?.read ? inputoHandler(e) : inputooHandler(e);
                          }}
                          value={valueid ? valueid : ""}
                        />
                      </div>

                      <div className="col-12 col-sm-12 col-md-3 mb-1 d-none">
                        <input
                          className="form-control"
                          type="search"
                          name="name"
                          placeholder="Search by name"
                          onChange={(e) => {
                            seller?.read ? inputeHandler(e) : inputeeHandler(e);
                          }}
                          value={valuename ? valuename : ""}
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 mb-1 d-none">
                        <input
                          type="search"
                          className="form-control"
                          name="contact_number"
                          placeholder="Search by Contact"
                          onChange={(e) => {
                            seller?.read ? inputcHandler(e) : inputccHandler(e);
                          }}
                          value={valuecontact ? valuecontact : ""}
                        />
                      </div>
                      {/* </div> */}
                      {/* <input type="search" name="payment_status" placeholder="Search by Payment Status" className="mr-2" onChange={(e) => { seller?.read ? inputpHandler(e) : inputppHandler(e) }} value={valuestatus ? valuestatus : ""} /> */}
                      {/* <div className="col-12 col-sm-12 col-md-4 mb-1">
                        <select name="payment_status" onChange={(e) => { seller?.read ? inputpHandler(e) : inputppHandler(e) }} value={valuestatus ? valuestatus : ""} className={`form-control`} >
                          <option value="" disabled selected >Payment Status</option>
                          <option value="NOT-PAID">Cod</option>
                          <option value="pa">Paid</option>
                        </select>
                      </div> */}
                      {/* <div className="input-group mb-3" style={{ width: "30%" }}> */}
                      {/* <span className="input-group-text">Filter By Date</span> */}
                      <div className="col-12 col-sm-12 col-md-4 mb-1 d-none">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Search by date"
                          name="date"
                          onChange={(e) => {
                            seller?.read ? inputdHandler(e) : inputddHandler(e);
                          }}
                          value={valuedate ? valuedate : ""}
                        />
                      </div>

                      <div className="col-12 col-sm-12 col-md-2 mb-1">
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            setTimeout(() => {
                              exportToExcel(allorders);
                            }, 2000)
                          }
                        >
                          Export{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isLoading ? (
                <div className="card-body">
                  {
                    orderList && (
                      <>
                        <div className="showdiv table-responsive table-bordered">
                          <Paginated data={orderList} columns={COLUMNS} />
                        </div>
                        <div className="hidediv">
                          {orderList.map((item: any, index) => {
                            let sellerName =
                              item?.address?.start?.location?.descriptor?.name;
                            if (sellerName) {
                              sellerName = sellerName;
                            } else {
                              // const parsedData = JSON.parse(item?.fulfillments);
                              //const selletName = parsedData[0]?.start?.location?.descriptor?.name
                              //sellerName = selletName
                            }

                            return (
                              <div className="border p-1" key={index}>
                                <div
                                  className="d-flex  mt-1 align-items-center"
                                  style={{ gap: "10px" }}
                                >
                                  <span
                                    className="genericlabel"
                                    style={{ width: "28%" }}
                                  >
                                    Order ID
                                  </span>
                                  <span
                                    className=""
                                    style={{ fontSize: "14px", width: "70%" }}
                                  >
                                    {item?.id}
                                  </span>
                                </div>
                                <div
                                  className="d-flex  mt-1"
                                  style={{ gap: "10px" }}
                                >
                                  <span
                                    className="genericlabel "
                                    style={{ width: "28%" }}
                                  >
                                    Order Status
                                  </span>
                                  <span
                                    className=""
                                    style={{ fontSize: "14px", width: "70%" }}
                                  >
                                    {item.financial_status === "voided"
                                      ? "Canceled"
                                      : item.fulfillment_status === null
                                      ? "Accepted"
                                      : item.fulfillment_status ===
                                          "fulfilled" &&
                                        item?.fulfillments.length > 0 &&
                                        item?.fulfillments[0]
                                          .shipment_status === "delivered"
                                      ? "Completed"
                                      : item.fulfillment_status ===
                                          "fulfilled" &&
                                        item?.fulfillments.length > 0 &&
                                        (item?.fulfillments[0]
                                          .shipment_status === null ||
                                          item?.fulfillments[0]
                                            .shipment_status === "in_transit" ||
                                          item?.fulfillments[0]
                                            .shipment_status ===
                                            "out_for_delivery")
                                      ? "In-progress"
                                      : ""}
                                  </span>
                                </div>
                                <div
                                  className="d-flex mt-1"
                                  style={{ gap: "10px" }}
                                >
                                  <span
                                    className="genericlabel "
                                    style={{ width: "28%" }}
                                  >
                                    {" "}
                                    Seller Name
                                  </span>
                                  <span
                                    className=""
                                    style={{ fontSize: "14px", width: "70%" }}
                                  >
                                    {decoded?.data?.user?.name}
                                  </span>
                                </div>
                                <div
                                  className="d-flex mt-1"
                                  style={{ gap: "10px" }}
                                >
                                  <span
                                    className="genericlabel "
                                    style={{ width: "31%" }}
                                  >
                                    Payment Status
                                  </span>
                                  <span
                                    className=""
                                    style={{ fontSize: "14px", width: "69%" }}
                                  >
                                    {item.financial_status === "paid"
                                      ? "Paid"
                                      : "Not Paid"}
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <Link
                                    to={`../../orderCompleteDetail/${item.id}`}
                                  >
                                    {" "}
                                    <p
                                      style={{
                                        width: "120px",
                                        fontWeight: 600,
                                        borderRadius: "17px",
                                        color: "white",
                                        background: "blue",
                                        padding: "6px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Check Order
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )
                    // orderList.map((item: any, index: any) => {
                    //   return (
                    //     <div className="my-2" style={{ background: "white" }} key={index}>
                    //       <div className="row m-2 p-2 " style={{ gap: "10px" }}>
                    //         <div className="d-flex justify-content-center align-items-center">
                    //           <img src={orderImg} alt="" className="orderimg" />
                    //         </div>
                    //         <div >
                    //           <div className="m-0">
                    //             <p style={{ fontSize: "15px", color: "black", fontWeight: "500" }} className="mb-0">
                    //               Order ID: <span>{item.order_id}</span>
                    //               <br />
                    //               <span className="text-capitalize"> {item.name}</span>
                    //             </p>
                    //             <small className="text-capitalize">
                    //               {item?.address?.end?.location?.address?.building}
                    //             </small>
                    //           </div>
                    //         </div>
                    //       </div>
                    //       <div className=" m-2 ">
                    //         <div className="row m-2 p-2 d-flex justify-content-evenly">
                    //           <div className="col-6" style={{ color: "black", fontWeight: "500" }}>
                    //             Item : {item.item_count}
                    //             <br />
                    //             Status : {item.status}
                    //           </div>
                    //           <div
                    //             className="col-6"
                    //             style={{
                    //               textAlign: "center",
                    //               fontSize: "23px",
                    //               fontWeight: "bold",
                    //             }}
                    //           >
                    //             <Link className="text-dark" to={`./orderCompleteDetail/${item.order_id}`}>
                    //               <span className="badge badge-primary" style={{ fontSize: "15px", fontWeight: "400" }}>Get Order Details</span>
                    //             </Link>
                    //           </div>
                    //         </div>
                    //         <div
                    //           style={{ borderBottom: "2px dotted grey", width: "100%" }}
                    //         ></div>
                    //         <div className="row my-2 d-flex justify-content-evenly" style={{ fontSize: "15px", fontWeight: "500" }}>
                    //           <div className="col-6 pb-1">{moment(`${item.created_at}`).format("MMMM Do YYYY")}</div>
                    //           <div className="col-6 pb-1" style={{ textAlign: "end" }}>
                    //             Order Amount: {item.order_total}
                    //           </div>
                    //         </div>
                    //       </div>
                    //       <div className="col-12">
                    //         {/* <a className="text-primary" style={{ cursor: "pointer" }}>
                    //           Accept Partial Order &#x3E;
                    //         </a> */}
                    //       </div>
                    //     </div>
                    //   );
                    // })
                  }
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "60vh" }}
                >
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
