import "./style.css";

export default function Loader() {
  return (
    <div className="card1 br1">
      <div className="wrapper1">
        <div className="profilePic animate1"></div>
        <div className="comment1 br1 animate1 w80"></div>
        <div className="comment1 br1 animate1"></div>
        <div className="comment1 br1 animate1"></div>
      </div>
    </div>
  );
}
