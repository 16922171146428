import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import PageNotFound from "../pages/404";
import Login from "../pages/login";
import AddUser from "../pages/adduser";
import UserList from "../pages/userlist";

import ProductList from "../pages/productlist";

import LoginPageIsLogedIn from "../components/loginProtected";
import ProtectedRoute from "../components/proctectedRoute";
import ForgetPassword from "../pages/forget-password/Index";
import ResetPassword from "../pages/reset-password";
import AddSeller from "../pages/addseller";
import SellerList from "../pages/sellerlist";
import Roles from "../pages/roles";
import OrderList from "../pages/orderlist";
import Category from "../pages/category";
import UpdateSeller from "../pages/updateseller";
import Tax from "../pages/tax";
import UpdateUser from "../pages/updateuser";
import Location from "../pages/location";
import Logs from "../pages/logs";
import Transaction from "../pages/transaction";
import MasterProductAdd from "../pages/NewmasterPage/MasterProductAdd";
import MasterProductList from "../pages/NewmasterPage/MasterProductList";

import NonapprovedProducts from "../pages/Non Approved Product List/index";
import NonapprovedProduct from "../pages/NonApprovedProduct/index";
import Notification from "../pages/Notifications/index.js";
import CustomerQuery from "../pages/CustomerQuery/index.js";
import Sellerdetails from "../pages/SellerDetails";
import SellerNonApprovedlist from "../pages/Non Approved Product List/SellerNonApprovedlist";
import Onboarding from "../pages/Onboarding";
import Registerseller from "../pages/SellerRegisteration";
import Personaldetails from "../pages/SellerRegisteration/Personaldetails";
import Bankdetails from "../pages/SellerRegisteration/BankDetails";
import Checkseller from "../pages/SellerRegisteration/Checkseller";
import Pendingpage from "../pages/SellerRegisteration/Pendingpage";
import Brand from "../pages/Brand";
import Addcategory from "../pages/New Category/Addcategory";
import Sellerauthcheck from "../pages/SellerRegisteration/Sellerauthcheck";

import Productcatalog from "../pages/catalog/productcatalog";
import CreateProduct from "../pages/catalog/createProduct";
import Invoice2 from "../pages/Invoice/Invoice2";
import AllOrderList from "../pages/allorderslist";
import Sidebar from "../components/thememaker/sidebar";
import Catalog from "../pages/catalog";
import Order from "../pages/order";
import OrderCompleteDetail from "../pages/order/orderCompleteDetail";
import VendororderCompleteDetail from "../pages/order/vendororderCompleteDetail";
import OrderCompleteinfo from "../pages/order/orderCompleteinfo";
import CancelOrderlist from "../pages/order/cancelOrderlist";
import CustomerOrder from "../pages/order/customerOrder";
import CompleteOrder from "../pages/order/completeOrder";
import Invoice from "../pages/Invoice/Invoice";
import SupportList from "../pages/support";
import UpdatePassword from "../pages/Update Password/UpdatePassword";
import Unsettledtransction from "../pages/unsettledTransaction";
import Forgget from "../pages/forget-password/Forgget";

import RequestProductApproval from "../pages/RequestProductApproval/RequestProductApproval";
import AddBusinessSeller from "../pages/addbusinessseller";
import RentalVendororderCompleteDetail from "../pages/order/rentalVendororderCompleteDetail";
import RentalOrderList from "../pages/rentalOrderlist";
import RentalOrderCompleteDetail from "../pages/order/rentalOrderCompleteDetail";
import RentalCustomerOrder from "../pages/order/rentalCustomerOrder";
import { Component } from "react";
const routes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/",
    component: <Login />,
  },
  {
    path: "/addseller",
    component: <AddSeller />,
  },
  {
    path: "/addBusinessSeller",
    component: <AddBusinessSeller />,
  },
  {
    path: "/sellerlist",
    component: <SellerList />,
  },
  {
    path: "/tax",
    component: <Tax />,
  },
  {
    path: "/updateseller/:id",
    component: <UpdateSeller />,
  },
  {
    path: "/roles",
    component: <Roles />,
  },
  {
    path: "/adduser",
    component: <AddUser />,
  },
  {
    path: "/updateuser/:id",
    component: <UpdateUser />,
  },
  {
    path: "/userlist",
    component: <UserList />,
  },
  {
    path: "/category",
    component: <Category />,
  },

  {
    path: "/productlist",
    component: <ProductList />,
  },
  {
    path: "/orderlist",
    component: <OrderList />,
  },
  // {
  //   path: "/orderdetails/:id?",
  //   component: <OrderDetails />,
  // },
  {
    path: "/transaction",
    component: <Transaction />,
  },

  {
    path: "/location",
    component: <Location />,
  },
  {
    path: "/forget-password",
    component: <ForgetPassword />,
  },
  {
    path: "/reset-password/:id",
    component: <ResetPassword />,
  },
  {
    path: "/logs",
    component: <Logs />,
  },

  {
    path: "/createmasterproduct",
    component: <MasterProductAdd />,
  },
  {
    path: "/updatemasterproduct/:id?",
    component: <MasterProductAdd />,
  },
  {
    path: "/masterproductlist",
    component: <MasterProductList />,
  },

  {
    path: "/nonapprovedproducts",
    component: <NonapprovedProducts />,
  },
  {
    path: "/nonapprovedproduct/:id?",
    component: <NonapprovedProduct />,
  },
  {
    path: "/notifications",
    component: <Notification />,
  },
  {
    path: "/customer-query",
    component: <CustomerQuery />,
  },
  {
    path: "/sellernonapprovedproducts",
    component: <SellerNonApprovedlist />,
  },

  // {
  //   path: "/test",
  //   component: <Something />
  // },

  {
    path: "/sidebar",
    component: <Sidebar />,
  },
  {
    path: "/sellerdetail",
    component: <Sellerdetails />,
  },

  {
    path: "/selleregister",
    component: <Registerseller />,
  },
  {
    path: "/personaldetails/:id?",
    component: <Personaldetails />,
  },
  {
    path: "/bankdetails/:id?",
    component: <Bankdetails />,
  },
  {
    path: "/checkseller",
    component: <Checkseller />,
  },

  {
    path: "/pending",
    component: <Pendingpage />,
  },
  {
    path: "/catalog",
    component: <Productcatalog />,
  },
  {
    path: "/createSellerProduct",
    component: <CreateProduct />,
  },
  {
    path: "/brand",
    component: <Brand />,
  },
  {
    path: "/newcategory",
    component: <Addcategory />,
  },
  {
    path: "/sellerauthcheck",
    component: <Sellerauthcheck />,
  },

  {
    path: "/order",
    component: <Order />,
  },

  {
    path: "/orderCompleteDetail/:id?",
    component: <OrderCompleteDetail />,
  },
  {
    path:"/vendororderCompleteDetail/:id?",
    component: <VendororderCompleteDetail />,
  },
  {
    path: "/rentalorderlist",
    component: <RentalOrderList />,
  },
  {
    path: "/rentalOrderCompleteDetail/:id?",
    component: <RentalOrderCompleteDetail />,
  },
  {
    path:"/rentalVendororderCompleteDetail/:id?",
    component: <RentalVendororderCompleteDetail />,
  },
  {
    path: "/rentalcustomerOrder",
    component: <RentalCustomerOrder />,
  },
  {
    path: "/customerOrder",
    component: <CustomerOrder />,
  },
  {
    path: "/completeOrder",
    component: <CompleteOrder />,
  },
  {
    path: "/cancelOrderlist",
    component: <CancelOrderlist />,
  },
  {
    path: "/orderCompleteinfo/:id?",
    component: <OrderCompleteinfo />,
  },

  {
    path: "/updatestoreproduct/:id?",
    component: <CreateProduct />,
  },

  {
    path: "/invoice/:order_id?",
    component: <Invoice />,
  },
  {
    path: "/order_invoice/:order_id?",
    component: <Invoice2 />,
  },

  {
    path: "/allorderslist",
    component: <AllOrderList />,
  },

  {
    path: "/supportlist",
    component: <SupportList />,
  },
  {
    path: "/updatepassword",
    component: <UpdatePassword />,
  },

  {
    path: "/unsettledtransactions",
    component: <Unsettledtransction />,
  },
  {
    path: "/resetpassword",
    component: <Forgget />,
  },

  {
    path:"/requestproductapproval",
    component:<RequestProductApproval />
  },
  {
    path: "*",
    component: <PageNotFound />,
  },
];

export default function AllRoutes() {
  return (
    <>
      <Router>
        <Switch>
          {routes.map((item, index) => (
            <Route exact path={item?.path} key={index}>
              {item.path === "/" ? (
                <LoginPageIsLogedIn>{item.component}</LoginPageIsLogedIn>
              )  : item.path === "/checkseller" ? (
                <Checkseller />
              ) : item.path === "/resetpassword" ? (
                <Forgget />
              ) : item.path === "/order_invoice/:order_id?" ? (
                <Invoice2 />
              ) : item.path === "/sellerauthcheck" ? (
                <Sellerauthcheck />
              )  : (
                <>
                  <ProtectedRoute>{item.component}</ProtectedRoute>
                </>
              )}
            </Route>
          ))}
        </Switch>
      </Router>
    </>
  );
}
