import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { tokenDecode } from "../../utils/helper";
import allorderslist from "../../pages/allorderslist";

export default function ProtectedRoute({ children }: any) {
  const history = useHistory();
  const location = useLocation();
  const search = useLocation().search;
  const tk_access = new URLSearchParams(search).get("tk_access");
  const keyV = location.pathname.split("/")[1];
  try {
    const decoded: any = tokenDecode(tk_access);
    const { data } = decoded;
    const {
      permission: {
        category,
        order,
        product,
        role,
        seller,
        tax,
        user,
        location,
        transaction,
      },
    } = data;
    if (decoded) {
      switch (keyV) {
        case "":
          return children;
        case "dashboard":
          return children;
        case "addseller":
          return seller.write ? children : history.push("/");
        case "addBusinessSeller":
          return seller.write ? children : history.push("/");
        case "sellerlist":
          return seller.read ? children : history.push("/");
        case "tax":
          return tax.write ? children : history.push("/");
        case "updateseller":
          return seller.update ? children : history.push("/");
        case "roles":
          return role.write ? children : history.push("/");
        case "adduser":
          return user.write ? children : history.push("/");
        case "updateuser":
          return user.update ? children : history.push("/");
        case "userlist":
          return user.read ? children : history.push("/");
        case "category":
          return category.write ? children : history.push("/");
        case "product":
          return product.write ? children : history.push("/");
        case "productlist":
          return product.read ? children : history.push("/");
        case "masterproductlist":
          return product.read ? children : history.push("/");
        case "orderlist":
          return order.read ? children : history.push("/");
        case "rentalorderlist":
          return order.read ? children : history.push("/");
        case "rentalOrderCompleteDetail":
            return order.read ? children : history.push("/");
        case "rentalcustomerOrder":
          return order.read ? children : history.push("/");
          
        case "orderdetails":
          return order.read ? children : history.push("/");
        case "transaction":
          return transaction.read ? children : history.push("/");
        case "location":
          return location.read ? children : history.push("/");

        case "createmasterproduct":
          return children;
        case "updatemasterproduct":
          return children;
        // case "productlist":
        //   return children;

     
        case "nonapprovedproducts":
          return children;
        // case "nonapprovedproducts":
        //   return children;
        case "nonapprovedproduct":
          return children;
        case "notifications":
          return children;
        case "customer-query":
          return children;
        case "sellernonapprovedproducts":
          return children;
        case "test":
          return children;
        case "profile":
          return children;
        case "sellerdetail":
          return children;
        case "logs":
          return children;
        case "sidebar":
          return children;

        case "resetpassword":
          return children;
        case "selleregister":
          return children;
        case "personaldetails":
          return children;
        case "bankdetails":
          return children;
        case "checkseller":
          return children;
        case "pending":
          return children;
        case "catalog":
          return product.read ? children : history.push("/");
        case "createSellerProduct":
          return product.read ? children : history.push("/");
        case "order":
          return order.read ? children : history.push("/");
        case "allorderslist":
          return children;
        case "orderCompleteDetail":
          return order.read ? children : history.push("/");
        case "vendororderCompleteDetail":
          return order.read ? children : history.push("/");
        case "rentalVendororderCompleteDetail":
          return order.read ? children : history.push("/");
        case "orderCompleteinfo":
          return order.read ? children : history.push("/");
        case "customerOrder":
          return order.read ? children : history.push("/");
        case "completeOrder":
          return order.read ? children : history.push("/");
        case "cancelOrderlist":
          return order.read ? children : history.push("/");
        case "unsettledtransactions":
          return transaction.read ? children : history.push("/");
        case "brand":
          return children;
        case "sellerauthcheck":
          return children;
        case "newcategory":
          return children;
        case "seeorderdetails":
          return children;
        case "acceptedorderdetail":
          return children;
        case "orderpopup":
          return children;
        case "updatestoreproduct":
          return children;
        case "invoice":
          return children;
        case "order_invoice":
          return children;
        case "supportlist":
          return children;
        case "updatepassword":
          return children;
        case "requestproductapproval":
          return children;
        default:
          return history.push("/");
      }
    } else {
      console.log("no");
      switch (keyV) {
        case "forget-password":
          return children;
        case "reset-password":
          return children;
        default:
          return history.push("/");
      }
    }
  } catch (error) {
    console.log("this erro -->", error);
    switch (keyV) {
      case "forget-password":
        return children;
      case "reset-password":
        return children;
      case "test":
        return children;
      default:
        return history.push("/");
    }
  }
}
