/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import styled from "styled-components";
import "./switch.css";
import InvoiceVendor from "../Invoice/Invoicevendor";
import html2pdf from "html2pdf.js";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { IoLogoUsd } from "react-icons/io";

const { apiEndpoint, baseNodeapiUrl } = config;

const Pbutton = styled.button`
  outline: none;
  border: none;
  margin-left: 18px;
  background: #105293;
  padding: 3px 5px;
`;

const tableStyle = {
  width: "35%",
  maxWidth: "40%",
};

const vendororderCompleteDetail = () => {
  let params = useParams();
  const { id: order_id }: any = params;
  const decoded = tokenDecode();
  const { data } = decoded;
  let seller_id = decoded?.data?.user?.seller_id;
  const {
    permission: { order },
  } = data;
  const [isLoading, setIsLoading] = useState(false);

  const [orderDetail, setorderDetail]: any = useState([]);
  const [trackingUrl, setTrackingUrl]: any = useState("");
  const [checkFulfillment, setCheckFulfillment]: any = useState(false);
  const [fulfillment, setFulfillment]: any = useState([]);
  const [currentStatus, setCurrentStatus]: any = useState([]);

  useEffect(() => {
    console.log("orderDetail[0].order_status", fulfillment[0]?.shipment_status);
    const status = orderDetail[0]?.order_status;
    setOrderState((prevState) => ({
      ...prevState,
      order_state:
        status === null
          ? "Accepted"
          : status === "fulfilled" && fulfillment[0]?.shipment_status === null
          ? "In-progress"
          : status === "fulfilled" &&
            fulfillment[0]?.shipment_status === "in_transit"
          ? "In-progress"
          : status === "fulfilled" &&
            fulfillment[0]?.shipment_status === "out_for_delivery"
          ? "In-progress"
          : status === "fulfilled" &&
            fulfillment[0]?.shipment_status === "delivered"
          ? "Completed"
          : "Created",
      tracking_url: trackingUrl ? trackingUrl : "",
    }));

    setOrderState((prevState) => ({
      ...prevState,
      fulfillment_state:
        status === null
          ? "Pending"
          : status === "fulfilled" && fulfillment[0]?.shipment_status === null
          ? "Shipped"
          : status === "fulfilled" &&
            fulfillment[0]?.shipment_status === "in_transit"
          ? "On-the-way"
          : status === "fulfilled" &&
            fulfillment[0]?.shipment_status === "out_for_delivery"
          ? "Out-for-delivery"
          : status === "fulfilled" &&
            fulfillment[0]?.shipment_status === "delivered"
          ? "Order-delivered"
          : "Select Fulfillment Status",
    }));
  }, [fulfillment, orderDetail]);

  const [orderState, setOrderState] = useState({
    seller_id,
    order_id,
    order_state: "",
    fulfillment_state: "",
    tracking_url: "",
  });
  console.log("orderState", orderState);

  const [sendingOrderStatus, setsendingOrderStatus] = useState(false);

  const [orderStatus, setorderStatus] = useState([
    "Created",
    "Accepted",
    "In-progress",
    "Completed",
    // "Cancelled",
    // "Returned",
  ]);

  const getorderDetails = async (order_id: any) => {
    // debugger
    let apiurl = `getOrderByIdandvendorid/${seller_id}`;

    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/order/${apiurl}/${order_id}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        console.log("data.response", data.response);
        setIsLoading(false);
        setorderDetail(data.response);
        if (data.response[0]?.fulfillments.length > 0) {
          const getFulfillments = data.response[0]?.fulfillments.filter(
            (item: any) => item.status === "success"
          );
          if (getFulfillments.length > 0) {
            setCheckFulfillment(true);
            setFulfillment(getFulfillments);
          }
        } else {
          setCheckFulfillment(false);
        }

        setTrackingUrl(data.response[0]?.fulfillments[0]?.tracking_url);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const sellerId = orderDetail.flatMap((item: any) =>
    item.line_items.map((line: any) => line.vendor)
  )[0];
  
  const updateVendorOrder = async (e: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/order/updateVendorOrder`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: currentStatus,
      });

      if (data.status === 200) {
        const index = orderStatus.indexOf(currentStatus.order_state);
        if (index !== -1) {
          setorderStatus(orderStatus.slice(index));
        }

        if (currentStatus.order_state === "Created") {
          toast.success("Order Created successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (currentStatus.order_state === "Accepted") {
          toast.success("Order Accepted successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (currentStatus.order_state === "In-progress") {
          toast.success("Order In-progress!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (currentStatus.order_state === "Completed") {
          toast.success("Order Completed successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (currentStatus.order_state === "Cancelled") {
          toast.success("Order Cancelled successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // toast.success("Order updated !", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong 500 !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getVendorOrderByOrderId = async (id: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/order/getVendorOrderByOrderId`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        params: {
          seller_id:sellerId,
          order_id: id,
          order_state: orderState.order_state,
          fulfillment_state: orderState.fulfillment_state,
          tracking_url: trackingUrl,
        },
      });
      if (data.status === 200) {
        setCurrentStatus(data.data);
      }
    } catch (error) {
      toast.error("Something went wrong 500 !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    getorderDetails(order_id);
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    getVendorOrderByOrderId(order_id);
  }, [orderState.order_state]);


  interface Item {
    id: number;
    checked: boolean;
  }

  function PDFGenerator() {
    const element = document.getElementById("page-content"); // ID of the element containing the page content
    html2pdf()
      .set({
        margin: 0,
        filename: `Invoice - ${order_id}`,
        image: { type: "jpeg", quality: 1.0 },
      })
      .from(element)
      .save();
  }

  async function sendStatusHandler() {
    try {
      setsendingOrderStatus(true);
      const { data } = await axios({
        url: `${baseNodeapiUrl}/v1/unsolicated_status`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          order_id: order_id,
        },
      });

      if (data?.message?.ack?.status === "ACK") {
        toast.success("Unsolicated Status Updated");
        setsendingOrderStatus(false);
      } else {
        setsendingOrderStatus(false);
        toast.error("Error in Update!!");
      }
    } catch (err) {
      setsendingOrderStatus(false);
      console.log("Something Went Wrong!!", err);
    }
    setsendingOrderStatus(false);
    console.log(data);
  }

  const handleOrderStatusChange = (e: { target: { value: any } }) => {
    const { value } = e.target;
    setOrderState((prevState) => ({
      ...prevState,
      order_state: value,
      fulfillment_state:
        orderDetail[0]?.order_status === null
          ? "Pending"
          : orderDetail[0]?.order_status === "fulfilled" &&
            fulfillment[0]?.shipment_status === null
          ? "On-the-way"
          : orderDetail[0]?.order_status === "fulfilled" &&
            fulfillment[0]?.shipment_status === "in_transit"
          ? "On-the-way"
          : orderDetail[0]?.order_status === "fulfilled" &&
            fulfillment[0]?.shipment_status === "out_for_delivery"
          ? "Out-for-delivery"
          : orderDetail[0]?.order_status === "fulfilled" &&
            fulfillment[0]?.shipment_status === "delivered"
          ? "Order-delivered"
          : "Select Fulfillment Status",
    }));
  };

  const handleFulfillmentChange = (e: { target: { value: any } }) => {
    const { value } = e.target;
    setOrderState((prevState) => ({
      ...prevState,
      fulfillment_state: value,
    }));
  };
  // ----------------------------------------Code functions for Download invoice end-----------------------------------------

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <>
      <ThemeMaker>
        {!isLoading ? (
          <div className="card container my-3">
            <div
              className=" m-2 p-2"
              style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}
            >
              Order ID : {order_id}
            </div>

            {orderDetail &&
              orderDetail.map((item: any, index: any) => {
                return (
                  <>
                    <div className="p-1" key={index}>
                      <strong
                        className="text-info"
                        style={{ paddingLeft: "10px" }}
                      >
                        Items Details
                      </strong>
                      {item.line_items.map((item: any, index: any) => {
                        console.log(item);
                        return (
                          <div className="col-12 d-flex mt-2">
                            <div className="col-4">
                              {/* {item.product_images.map((item:any,index:any)=>{
                      return(                        
                      <img src={item} id={item.vendor }alt="image" className="w-75" />
                     
                      )})} */}
                              <img
                                src={item.product_images[0]}
                                id={item.vendor}
                                alt="image"
                                className="w-75"
                              />
                            </div>
                            <div
                              className="col-8"
                              style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              <p className="mb-0 genericlabel">
                                Product: <span>{item.name}</span>{" "}
                              </p>

                              {item?.weight ? (
                                <p className="mb-0 genericlabel">
                                  Size : <span>{item?.weight}</span>{" "}
                                </p>
                              ) : (
                                ``
                              )}
                              {item?.quantity ? (
                                <p className="mb-0 genericlabel">
                                  Qty : <span> {item?.quantity}</span>
                                </p>
                              ) : (
                                ``
                              )}
                              {item?.cancel_qty ? (
                                <p className="mb-0 genericlabel">
                                  Cancel Qty : {item?.cancel_qty}
                                </p>
                              ) : (
                                ``
                              )}
                              {item?.price ? (
                                <p className="mb-0 genericlabel">
                                  Price in USD : <IoLogoUsd />
                                  {item?.price}
                                </p>
                              ) : (
                                ``
                              )}
                              {item?.variant_title ? (
                                <p className="mb-0 genericlabel">
                                  One time / Rent :{" "}
                                  {item?.variant_title === "Weekly Rent" ||
                                  item?.variant_title === "1 Day Rent" ||
                                  item?.variant_title === "2 Day Rent" ||
                                  item?.variant_title === "3 Day Rent" ||
                                  item?.variant_title === "4 Day Rent" ||
                                  item?.variant_title === "5 Day Rent" ||
                                  item?.variant_title === "6 Day Rent"
                                    ? item?.variant_title
                                    : "Buy"}
                                </p>
                              ) : (
                                ``
                              )}
                              {item?.variant_title === "Weekly Rent" ||
                              item?.variant_title === "Daily Rent"
                                ? item.properties.map(
                                    (property: any) =>
                                      property.name === "Date" && (
                                        <p
                                          key={property.name}
                                          className="mb-0 genericlabel"
                                        >
                                          Date: {property.value}
                                        </p>
                                      )
                                  )
                                : null}
                                {item?.properties?.length > 0 && (
                                  <>
                                    {item.properties.map((property:any, index:any) => {
                                      if (property.name === "Pickup") {
                                        return (
                                          <p key={index} className="mb-0 genericlabel">
                                            Pickup Date:  {property.value}
                                          </p>
                                        );
                                      } else if (property.name === "Dropoff") {
                                        return (
                                          <p key={index} className="mb-0 genericlabel">
                                            Dropoff Date:  {property.value}
                                          </p>
                                        );
                                      }
                                      return null;
                                    })}
                                  </>
                                )}

                            </div>
                          </div>
                        );
                      })}
                      {item?.note ? (
  <div className="mb-0 genericlabel">
    {item?.note ? (
  <>
    {item?.note.includes("Pickup:") && (
      <p>
        <span>
        <strong>Pickup:</strong> {item?.note.split("Pickup:")[1].split("Drop off:")[0].trim()}
        </span>
      </p>
    )}

    {item?.note.includes("Drop off:") && (
      <p>
        <span>
        <strong> Drop off:</strong> {item?.note.split("Drop off:")[1].split("Meet-up:")[0].trim()}
        </span>
      </p>
    )}

    {item?.note.includes("Meet-up:") && (
      <p>
        <span>
          <strong>Meet-up:</strong> {item?.note.split("Meet-up:")[1].trim()}
        </span>
      </p>
    )}
  </>
) : (
  ``
)}

  </div>
) : (
  ``
)}


                      <hr />
                    </div>
                  </>
                );
              })}

            {orderState?.order_state !== "Cancelled" ? (
              <div className="row d-flex ">
                <div className="col-md-6 col-sm-12 mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="col-12 form-label genericlabel"
                  >
                    Order Status
                  </label>
                  <br />
                  <select
                    className="form-select col-md-8"
                    aria-label="size 3 select example"
                    value={currentStatus?.order_state}
                    name="order_state"
                    // onChange={handleOrderStatusChange}
                    onChange={(e) => {
                      setCurrentStatus({
                        ...currentStatus,
                        [e.target.name]: e.target.value,
                        fulfillment_state: ''
                      });
                    }}
                    // disabled={true}
                  >
                    {orderStatus.map((item) => {
                      return (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-6 col-sm-12 mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="col-12 form-label genericlabel"
                  >
                    Fulfillment
                  </label>
                  <br />
                  <select
                    className={`form-select col-md-8 ${currentStatus?.fulfillment_state === "" ? 'border border-danger' : ''}`}
                    aria-label="size 3 select example"
                    value={currentStatus?.fulfillment_state}
                    name="fulfillment_state"
                    // onChange={handleFulfillmentChange}
                    onChange={(e) => {
                      setCurrentStatus({
                        ...currentStatus,
                        [e.target.name]: e.target.value,
                      });
                    }}
                    // disabled={true}
                  >
                    <option value="">Select Fulfillment Status</option>
                    {(currentStatus?.order_state === "Created" ||
                      currentStatus?.order_state === "Accepted") && (
                      <>
                        {" "}
                        <option value="Pending">Pending</option>{" "}
                      </>
                    )}

                    {currentStatus?.order_state === "In-progress" && (
                      <>
                        <option value="Shipped">Shipped</option>
                        <option value="On-the-way">On-the-way</option>
                        <option value="Out-for-delivery">
                          Out-for-delivery
                        </option>
                      </>
                    )}

                    {currentStatus?.order_state === "Completed" && (
                      <>
                        <option value="Order-delivered">Order-delivered</option>
                      </>
                    )}
                  </select>
                </div>
                <div className="col-12 mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="col-12 form-label genericlabel"
                  >
                    Tracking URL
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={currentStatus.tracking_url}
                    name="tracking_url"
                    onChange={(e) =>
                      setCurrentStatus({
                        ...currentStatus,
                        [e.target.name]: e.target.value,
                      })
                    }
                    // disabled={true}
                  />
                </div>
                {order?.update ? (
                  <div className="row m-2 w-100 d-flex justify-content-between">
                    <div className=" col-md-6 col-sm-12 mt-2">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => updateVendorOrder(e)}
                        disabled={currentStatus?.fulfillment_state === ""}
                      >
                        Update
                      </button>

                      {/* <button
                        className="btn btn-secondary ml-2"
                        onClick={sendStatusHandler}
                        disabled={sendingOrderStatus}
                      >
                        {sendingOrderStatus
                          ? "Sending Staus..."
                          : "Send Order Status"}
                      </button> */}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                {" "}
                <span
                  className="badge badge-danger"
                  style={{
                    display: "inline-block",
                    marginBottom: "5px",
                    marginLeft: "10px",
                  }}
                >
                  Order Cancelled
                </span>
                <button
                  className="btn btn-secondary ml-2 col-md-3 w-75 w-md-25"
                  onClick={sendStatusHandler}
                  disabled={sendingOrderStatus}
                >
                  {sendingOrderStatus
                    ? "Sending Staus..."
                    : "Send Order Status"}
                </button>{" "}
              </>
            )}

            {orderDetail.map((item: any, index: any) => {
              return (
                <>
                  <TableContainer component={Paper} className="mt-3">
                    <h6 className="text-info p-2 border-bottom-info font-weight-bold">
                      Order Details
                    </h6>
                    <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                      <TableHead style={{ background: "white" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Order Number
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.id}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Bill Number
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.order_number}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Bill Date
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {" "}
                            {moment
                              .utc(item.billing_address.created_at)
                              .format("DD-MM-YYYY  HH:mm:ss")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" className="bg-white">
                            Customer Name
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.billing_address.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" className="bg-white">
                            Customer Email
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.email}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Total Product
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.line_items.length}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Total Amount
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            <IoLogoUsd />
                            {item.total_price}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Shipping Price
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                            
                          >
                            <IoLogoUsd />
                            {item.shipping_price}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="text-info bg-white font-weight-bold"
                            style={tableStyle}
                          >
                            Grand Total
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white text-info font-weight-bold"
                          >
                            <IoLogoUsd />
                            {item.grand_total}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>

                  <TableContainer component={Paper} className="mt-5">
                    <h6 className="text-info p-2 border-bottom-info font-weight-bold">
                      Payment Method
                    </h6>
                    <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Order Number
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.id}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Bill Date
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {" "}
                            {moment
                              .utc(item?.billing_address?.created_at)
                              .format("DD-MM-YYYY  HH:mm:ss")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Total Amount
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            <IoLogoUsd />
                            {item?.grand_total}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Payment Status
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold text-capitalize"
                          >
                            {item.payment_status}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white text-capitalize"
                            style={tableStyle}
                          >
                            Payment Method
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold text-capitalize"
                          >
                            {item.payment_method}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>

                  <TableContainer component={Paper} className="mt-5 mb-5">
                    <h6 className="text-info p-2 border-bottom-info font-weight-bold">
                      Shipping Address
                    </h6>
                    <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.billing_address.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.customer.email}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Mobile Number
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.billing_address.phone}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {" "}
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Address
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.billing_address.address1}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            City
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.billing_address.city}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            State
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.billing_address.province}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Country
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {" "}
                            {item.billing_address.country}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="bg-white"
                            style={tableStyle}
                          >
                            Pin Code
                          </TableCell>
                          <TableCell
                            align="right"
                            className="bg-white font-weight-bold"
                          >
                            {item.billing_address.zip}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </>
              );
            })}

            {/* <Link to={`/invoice/${order_id}`}> <Pbutton style={{ marginBottom: "10px" }}>Check Invoice</Pbutton> </Link> */}
            <Pbutton
              onClick={PDFGenerator}
              className="my-2"
              style={{
                margin: "0 auto",
                display: "block",
                color: "white",
                borderRadius: "3px",
                padding: "5px",
              }}
            >
              Download Invoice
            </Pbutton>
            <div style={{ display: "none" }}>
              <InvoiceVendor orderId={order_id} />
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "90vh", width: "100%" }}
          >
            <CircularProgress />
          </div>
        )}
      </ThemeMaker>
    </>
  );
};

export default vendororderCompleteDetail;
