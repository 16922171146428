import eye from "../../icons/eye.png";
import deleteicon from "../../icons/delete.png";
import styles from "./TaxlistTemplate.module.css";

export default function TaxlistTemplate({
  item,
  index,
  editRedirect,
  deleteTax,
}) {
  return (
    <>
      <div
        className={`d-flex justify-content-between w-100 ${styles.taxlistTemplatemain}`}
        key={item.id}
      >
        <div className={`${styles.taxlistTemplateImage}`}>
          <span>0{index + 1}</span>
        </div>
        <div className={`${styles.taxlistTemplateDesc}`}>
          <div>
            <p className="m-0">
              {" "}
              <span>Rate:</span> <span>{item.tax_rate}</span>
            </p>
          </div>
          <div>
            <p className="m-0">
              {" "}
              <span>Created At:</span>
              <span> {item.created_at} </span>
            </p>
          </div>
          <div>
            {item.status ? (
              <button className={styles.success}>Active</button>
            ) : (
              <button className={styles.danger}>Deactive</button>
            )}
          </div>
        </div>
        <div className={`${styles.taxlistTemplateedit}`}>
          <img
            src={eye}
            alt=""
            onClick={() => {
              editRedirect(item.id);
            }}
            data-target="#exampleModal1"
            data-toggle="modal"
          />
          <img
            src={deleteicon}
            alt=""
            className="mt-2"
            onClick={() => {
              deleteTax(item.id);
            }}
          />
        </div>
      </div>
    </>
  );
}
