import React, { useEffect, useState } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import "./switch.css";
import moment from "moment";
import styled from "styled-components";
import Invoice from "../Invoice/Invoice";
import html2pdf from "html2pdf.js";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const { apiEndpoint } = config;

const Pbutton = styled.button`
  outline: none;
  border: none;
  margin-left: 18px;
  background: #105293;
  padding: 3px 5px;
`;
const tableStyle = {
  width: "35%",
   maxWidth: "40%"
}
export default function OrderDetail() {
  const [showPop, setShowPop] = useState(false);
  const toggle = (e: any) => {
    e.preventDefault();
    setShowPop(true);
  };
  let params = useParams();
  const { id: order_id }: any = params;
  const [alldetails, setalldetails] = useState([]);
  const decoded = tokenDecode();
  const [isLoading, setIsLoading] = useState(false);
  const [updateOrder_, setUpdateOrder_] = useState({
    order_id: "",
    tracking_url: "",
    order_state: "",
  });
  const [orderDetail, setorderDetail]: any = useState([]);
  const [_orderDetail, _setorderDetail]: any = useState({
    billing_address: "",
    contact_number: "",
    created_at: "",
    email: "",
    id: "",
    name: "",
    order_id: "",
    order_state: "",
    provider_id: "",
    shipping_address: "",
    status: "",
    total_price: 0,
  });
  const [orderState, setOrderState] = useState({
    order_id,
    order_state: "",
    fulfillment_state: "",
    tracking_url: "",
  });
  const [isChecked, setisChecked]: any = useState("");
  const [cancelvalue, setCancelvalue]: any = useState("");
  const [logisticCharges, setlogisticCharges]: any = useState("");
  let rawdata;
  const [orderCancle, setOrderCancle]: any = useState({
    order_id,
    product_id: [],
  });
  const [addButton, setaddButton] = useState(true);
  const [orderStatus, setorderStatus] = useState([
    "Created",
    "Accepted",
    "In-progress",
    "Completed",
    "Cancelled",
    // "Returned",
  ]);
  let SellerId = decoded?.data?.user?.seller_id;
  const getorderDetails = async (order_id: any) => {
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/order/getOrderById/${order_id}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        setIsLoading(false);
        setorderDetail(data.response);
        setOrderCancle(data.response);
        setlogisticCharges(JSON.parse(data.data[0]?.quote));
        setalldetails(data);
        let totalP = 0;
        data.products.forEach((item: any) => {
          totalP += parseFloat(item?.price);
        });
        if (data.data[0].order_state !== "") {
          const index = orderStatus.indexOf(data.data[0].order_state);
          if (index !== -1) {
            setorderStatus(orderStatus.slice(index));
          }
        }
        setOrderState({
          ...orderState,
          order_state: data.data[0]?.order_state,
          tracking_url: data.products[0]?.tracking_url,
          fulfillment_state: data.products[0]?.fulfillment_state,
        });
        setUpdateOrder_({
          ...updateOrder_,
          order_id: data.data[0]?.order_id,
          tracking_url: data.data[0]?.tracking_url,
          order_state: data.data[0]?.order_state,
        });
        _setorderDetail({
          ..._orderDetail,
          ...data.data[0],
          total_price: data.data[0].order_total,
          billing_address: JSON.parse(data.data[0]?.billing),
          shipping_address: JSON.parse(data.data[0]?.fulfillments),
        });
        rawdata = data.products.map((item: any, i: any) => ({
          item_id: item.product_id,
          order_status: orderState.order_state,
          qty: cancelvalue,
        }));
        orderCancle.product_id = Object.assign(rawdata);
        setOrderCancle(orderCancle);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const updateOrder = async (product_id: any) => {
    try {
      const getItem = orderDetail.find(
        (item: any) => item?.product_id === product_id
      );
      const { data } = await axios({
        url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: getItem,
      });
      if (data.message === "flag1") {
        toast.success("Item status updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("yess", error);
    }
  };
  const updateOrderChangeHandler = (e: any, index: any) => {
    const newUpdate: any = orderDetail;
    newUpdate[index][e.target.name] = e.target.value;
    setorderDetail([...newUpdate]);
  };
  const updateThisOrder = async (e: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: orderState,
      });
      if (data.status === 200) {
        toast.success("Order cancel updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong 500 !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const cancleUpdate = (cancelvalue: any) => {
    console.log("orderCancle", orderCancle);
    console.log("quantity", cancelvalue);
    console.log("product id", isChecked);
    setCancelvalue(cancelvalue);
    console.log("item 288", orderCancle);
    const newrawdata = orderDetail.map((item: any, i: any) => ({
      item_id: item.product_id,
      order_status: orderState.order_state,
      qty: cancelvalue,
    }));
    orderCancle.product_id = Object.assign(newrawdata);
  };
  const cancelPartial = async (e: any) => {
    try {
      const response = await axios({
        url: `${apiEndpoint}/sellerordercancel`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: orderCancle,
      })
        .then(function (response) {
          toast.success("Order cancel updated !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowPop(false);
        })
        .catch(function (error) {
          console.log("error 176", error);
          toast.error("Something went wrong !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .finally(function () {
          console.log("response   // always executed 179");
        });
    } catch (error) {
      console.error(" error 784", error);
      toast.error("Something went wrong !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  function PDFGenerator() {
    const element = document.getElementById("page-content"); // ID of the element containing the page content
    html2pdf()
      .set({
        margin: 0,
        filename: `Invoice - ${order_id}`,
        image: { type: "jpeg", quality: 1.0 },
      })
      .from(element)
      .save();
  }
  useEffect(() => {
    getorderDetails(order_id);
  }, []);
  const [cancelpopup, setcancelpopup] = useState(false);
  const [selectedStatus, setselectedStatus] = useState();
  const cancelbtn = () => {
    setcancelpopup(true);
  };
  const [cancelreson, setcancelreson] = useState("");
  const changeReturnStatus = (selectedStatus: any) => {
    console.log("selectedStatus", selectedStatus);
    setcancelreson(selectedStatus);
  };
  const ordercancel = async () => {
    const statusData = {
      order_id: "2023-04-26-565129",
      reason_code: cancelreson,
    };
    const id = toast.loading("Please wait...");
    try {
      const response = await axios({
        method: "post",
        url: "https://shikhar-preprod.herokuapp.com/v1/unsolicated_cancel",
        headers: { Authorization: `Bearer ${localStorage.getItem("us_st_d")}` },
        data: statusData,
      })
        .then(function (response) {
          console.log("response 173", response);
          toast.update(id, {
            render: "Status Updating",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          setcancelpopup(false);
        })
        .catch(function (error) {
          console.log("error 176", error);
          toast.update(id, {
            render: "something worng happned",
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
        })
        .finally(function () {
          console.log("response   // always executed 179");
        });
    } catch (error) {
      console.error(" error 784", error);
    }
  };
  return (
    <>
      <ThemeMaker>
        <div className="card container my-3 ">
          <div className=" shadow m-2 p-2 fw-bold genericlabel">
            Order ID : {order_id}
          </div>{" "}
          {orderDetail &&
            orderDetail.map((item: any) => {
              return (
                <>
                  <div className=" shadow m-2 p-2">
                    <strong className="text-info">Items Details</strong>
                    <div className="col-12 d-flex">
                      
                      {item.line_items.map((item:any,index:any)=>{
                        return(
                        <>
                        <div className="col-4">
                        {item.product_images.map((item:any,index:any)=>{
                        return(                        
                        <img src={item} id={item.vendor }alt="image" className="w-75" />
                       
                        )})} 
                        </div>
                      <div className="col-8 itemFDetails genericlabel">

                        Product: {item.name}
                       <br/>
                        Qty : {item?.quantity}
                        <br/>
                        Price : {item?.price}
                  
                      </div>
                      </>)
                      })}

                    </div>
                    <hr />
                  </div>
                </>
              );
            })}
          <div className="row d-flex">
            <div className="col-md-6 col-sm-12 mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="col-12 form-label genericlabel"
              >
                Order Status
              </label>
              <br />
              <select
                disabled
                className="form-select col-8"
                aria-label="size 3 select example"
                value={orderState?.order_state}
                name="order_state"
                onChange={(e) =>
                  setOrderState({
                    ...orderState,
                    [e.target.name]: e.target.value,
                  })
                }
              >
                {orderStatus.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="col-12 form-label genericlabel"
              >
                Fulfillment
              </label>
              <br />
              <select
                disabled
                className="form-select col-8"
                aria-label="size 3 select example"
                value={orderState?.fulfillment_state}
                name="fulfillment_state"
                onChange={(e) =>
                  setOrderState({
                    ...orderState,
                    [e.target.name]: e.target.value,
                  })
                }
              >
                <option value="Pending">Pending</option>
                <option value="Packed">Packed</option>
                <option value="Order-picked-up">Order-picked-up</option>
                <option value="Out-for-delivery">Out-for-delivery</option>
                <option value="Order-delivered">Order-delivered</option>
                <option value="RTO-Initiated">RTO-Initiated</option>
                <option value="RTO-Delivered">RTO-Delivered</option>
                <option value="RTO-Disposed">RTO-Disposed</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>
            <div className="col-12 mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="col-12 form-label genericlabel"
              >
                Tracking URL
              </label>
              <br />
              <input
                disabled
                className="form-control"
                type="text"
                placeholder=""
                value={orderState?.tracking_url}
                name="tracking_url"
                onChange={(e) =>
                  setOrderState({
                    ...orderState,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
            <div className="">
              {showPop && showPop ? (
                <>
                  <div
                    onClick={() => setShowPop(false)}
                    className={`cancelpopupoverlay`}
                  ></div>
                  <div className={`popup `}>
                    {orderDetail &&
                      orderDetail.map((item: any, index: any) => {
                        return (
                          <>
                            <div className="row p-4 mb-2" key={index}>
                              <div className="col-12 mb-2">
                                Product Name: {item.product_name}
                              </div>
                              <div className="col-md-6 col-sm-12">
                                Current Quantity: {item.quantity}
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <label className="col-form-label">
                                  Cancle Quantity:
                                </label>
                                <input
                                  type="number"
                                  style={{
                                    border: "none",
                                    borderBottom: "1px solid #ccc",
                                  }}
                                  name="qty"
                                  min={1}
                                  max={item.quantity}
                                  value={cancelvalue}
                                  onChange={(e) => cancleUpdate(e.target.value)}
                                />
                              </div>
                              <div className="col-12 d-flex align-self-baseline">
                                Update &nbsp;
                                <input
                                  type="checkbox"
                                  name="item_id"
                                  value={isChecked}
                                  onChange={(e) => {
                                    setisChecked({
                                      ...isChecked,
                                      [e.target.name]: isChecked,
                                    });
                                    console.log("line 593", isChecked);
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                    <div className="d-flex justify-content-end position-relative p-2">
                      <button
                        className="btn btn-primary align-end"
                        type="submit"
                        onClick={(e) => cancelPartial(e)}
                      >
                        Cancelation
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="row m-2 w-100 d-flex justify-content-between">
              <div className=" col-md-6 col-sm-12">
                <button
                  disabled
                  className="btn btn-primary"
                  onClick={(e) => updateThisOrder(e)}
                >
                  Update
                </button>
              </div>
              <div className="col-md-6 col-sm-12">
                {/* <div className="col-12 w-100 m-0 p-0 d-flex btnAlign">
                  <div className="m-2 ">
                    <button
                      disabled
                      onClick={cancelbtn}
                      className="btn btn-outline-danger"
                    >
                      Order Cancel
                    </button>
                  </div>
                  {cancelpopup ? (
                    <>
                      <div
                        onClick={() => setcancelpopup(false)}
                        className={`cancelpopupoverlay`}
                      ></div>
                      <div className={`cancelorderpopup p-3 `}>
                        <div className="row">
                          <div className="col-12 ">
                            <select
                              className="btn btn-outline-dark w-100 text-left"
                              value={cancelreson}
                              onChange={(e) =>
                                changeReturnStatus(e.target.value)
                              }
                            >
                              <option value="002">
                                One or more items in the Order not available
                              </option>
                              <option value="005">
                                Merchant rejected the order
                              </option>
                              <option value="008">
                                Order not ready for pickup
                              </option>
                              <option value="011">
                                Buyer not found or cannot be contacted
                              </option>
                              <option value="013">
                                Buyer refused to accept delivery
                              </option>
                              <option value="014">Address not found</option>
                              <option value="015">
                                Buyer not available at location
                              </option>
                              <option value="016">
                                Accident / rain / strike / vehicle issues
                              </option>
                              <option value="017">
                                Order delivery delayed or not possible
                              </option>
                              <option value="018">
                                Delivery pin code not serviceable
                              </option>
                              <option value="019">
                                Pickup pin code not serviceable
                              </option>
                            </select>
                            <p className="text-center mt-3 d-none">{`You selected : ${cancelreson}`}</p>
                          </div>
                          <div className="col-12 m-2 p-2 d-flex justify-content-between">
                            <button
                              className="btn btn-outline-dark col-5 col-md-5"
                              onClick={() => setcancelpopup(false)}
                            >
                              Close
                            </button>
                            <button
                              className="btn btn-outline-danger col-5 col-md-5"
                              onClick={() => ordercancel()}
                            >
                              Cancel Order
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="m-2 ">
                    <button
                      disabled
                      className="btn btn-primary"
                      onClick={(e) => {
                        toggle(e);
                      }}
                    >
                      Partial Cancelation
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          
          {
            orderDetail.map((item: any, index: any) => {
              return(
              <>
              <TableContainer component={Paper} className="mt-3">
                <h6 className="text-info p-2 border-bottom-info font-weight-bold">Order Details</h6>
                <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                  <TableHead style={{ background: "white" }}>
                    <TableRow >
                      <TableCell align="center" className="bg-white" style={tableStyle}>Order Number</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Bill Number</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.order_number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Bill Date</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold"> {moment.utc(item.billing_address.created_at).format("DD-MM-YYYY  HH:mm:ss")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white">Customer Name</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.billing_address.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white">Customer Email</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.contact_email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Total Product</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.line_items.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Total Amount with Shipping</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.total_price}</TableCell>
                    </TableRow>
                    
                    <TableRow>
                      <TableCell align="center" className="text-info bg-white font-weight-bold" style={tableStyle}>Grand Total</TableCell>
                      <TableCell align="right" className="bg-white text-info font-weight-bold"> {item.total_price}</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} className="mt-5">
                <h6 className="text-info p-2 border-bottom-info font-weight-bold">Payment Method</h6>
                <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow >
                      <TableCell align="center" className="bg-white" style={tableStyle}>Order Number</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Bill Date</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold"> {moment.utc(item?.billing_address?.created_at).format("DD-MM-YYYY  HH:mm:ss")}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Total Amount</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item?.total_price}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Payment Status</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold text-capitalize">{item.financial_status}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white text-capitalize" style={tableStyle}>Payment Method</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold text-capitalize">{item.payment_gateway_names}</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} className="mt-5 mb-5">
                <h6 className="text-info p-2 border-bottom-info font-weight-bold">Shipping Address</h6>
                <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow >
                      <TableCell align="center" className="bg-white" style={tableStyle}>Name</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.billing_address.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Email</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.customer.email}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Mobile Number</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.billing_address.phone}
                      </TableCell>
                    </TableRow>
                    <TableRow> <TableCell align="center" className="bg-white" style={tableStyle}>Address</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold">{item.billing_address.address1}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>City</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold" >{item.billing_address.city}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>State</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold" >{item.billing_address.province}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Country</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold" > {item.billing_address.country}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className="bg-white" style={tableStyle}>Pin Code</TableCell>
                      <TableCell align="right" className="bg-white font-weight-bold" >{item.billing_address.zip}</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
          </>
          )})}
          <Pbutton
            onClick={PDFGenerator}
            className="my-2"
            style={{
              margin: "0 auto",
              display: "block",
              color: "white",
              borderRadius: "3px",
              padding: "5px",
            }}
          >
            Download Invoice
          </Pbutton>
          <div style={{ display: "none" }}>
            <Invoice orderId={order_id} />
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
