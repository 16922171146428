import styles from "../TaxlistTemplate/TaxlistTemplate.module.css";
import eye from "../../icons/eye.png";
import deleteicon from "../../icons/delete.png";
import { Link } from "react-router-dom";

export default function UserTemplate({ userlist, userDelete }) {
  return (
    <>
      {userlist.map((item, index) => {
        return (
          <>
            <div
              className={`d-flex justify-content-between w-100 ${styles.taxlistTemplatemain}`}
              key={index}
            >
              <div className={`${styles.taxlistTemplateDesc}`}>
                <div>
                  <p className="m-0 w-100">
                    {" "}
                    <span>{item.name}</span>
                  </p>
                  <p className="m-0 w-100">
                    {" "}
                    <span>{item.email}</span>
                  </p>
                  <p className="m-0">
                    {" "}
                    <span>{item.contact_no}</span>
                  </p>
                </div>

                <div>
                  {item.approve_status == 1 ? (
                    <button className={styles.success}>Active</button>
                  ) : (
                    <button className={styles.danger}>Deactive</button>
                  )}
                </div>
              </div>
              <div className={`${styles.taxlistTemplateedit}`}>
                <Link to={`/updateuser/${item?._id}`}>
                  {" "}
                  <img
                    src={eye}
                    alt=""
                    onClick={() => {
                      //  editRedirect(item.id)
                    }}
                    data-target="#exampleModal1"
                    data-toggle="modal"
                  />{" "}
                </Link>
                <div
                  onClick={() => userDelete(item._id)}
                  style={{ marginTop: "5px" }}
                >
                  <img src={deleteicon} alt="" />
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}
