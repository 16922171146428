import { configureStore } from "@reduxjs/toolkit";
import reducer from "./features";
import logger from "redux-logger";

const store = configureStore({
  reducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

export default store;
