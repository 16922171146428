import { combineReducers } from "@reduxjs/toolkit";
import loginSlice from "./login/loginSlice";
import sellerSlice from "./seller/sellerSlice";

const reducer = {
  loginReducer: loginSlice,
  sellerReducer: sellerSlice,
};

export default reducer;
