// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seller_register_thanks_container__0S7GE {
  width: 100%;
  height: 100%;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
.seller_register_thanks_statement__Z-z\\+p p {
  text-align: center;
}
.seller_register_thanks_statement__Z-z\\+p p:nth-child(1) {
  color: black;
  font-size: 17px;
  font-weight: 600;
}
.seller_register_thanks_button__14Q6P button {
  background-color: #0094ff !important;
}
#seller_register_app-cover__Y4X19 {
  display: table;
  width: 600px;
  margin: 80px auto;
  counter-reset: button-counter;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SellerRegisteration/seller_register.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,wBAAwB;EACxB,4BAA4B;AAC9B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,6BAA6B;AAC/B","sourcesContent":[".thanks_container {\n  width: 100%;\n  height: 100%;\n  background-position: bottom;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n.thanks_statement p {\n  text-align: center;\n}\n.thanks_statement p:nth-child(1) {\n  color: black;\n  font-size: 17px;\n  font-weight: 600;\n}\n.thanks_button button {\n  background-color: #0094ff !important;\n}\n#app-cover {\n  display: table;\n  width: 600px;\n  margin: 80px auto;\n  counter-reset: button-counter;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thanks_container": `seller_register_thanks_container__0S7GE`,
	"thanks_statement": `seller_register_thanks_statement__Z-z+p`,
	"thanks_button": `seller_register_thanks_button__14Q6P`,
	"app-cover": `seller_register_app-cover__Y4X19`
};
export default ___CSS_LOADER_EXPORT___;
