// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_main {
  margin: 20px auto;
}

.pagination_main ul {
  display: flex;
  justify-content: center;
  gap: 3px;
  align-items: center;
}

.pagination_main ul li a {
  list-style: none;
  background-color: #105293;
  color: white;
  font-weight: 600;
  border-radius: 50%;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination_main ul li {
  color: white;
  list-style: none;
  width: -moz-fit-content;
  width: fit-content;
}

.current_active {
  background-color: rgb(32, 32, 174) !important;
}

@media screen and (max-width: 321px) {
  .pagination_main ul li a {
    width: 25px;
    height: 25px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Paginated/pagination.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".pagination_main {\n  margin: 20px auto;\n}\n\n.pagination_main ul {\n  display: flex;\n  justify-content: center;\n  gap: 3px;\n  align-items: center;\n}\n\n.pagination_main ul li a {\n  list-style: none;\n  background-color: #105293;\n  color: white;\n  font-weight: 600;\n  border-radius: 50%;\n  width: 43px;\n  height: 43px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.pagination_main ul li {\n  color: white;\n  list-style: none;\n  width: fit-content;\n}\n\n.current_active {\n  background-color: rgb(32, 32, 174) !important;\n}\n\n@media screen and (max-width: 321px) {\n  .pagination_main ul li a {\n    width: 25px;\n    height: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
