import { useState, useEffect, ChangeEvent } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import "../../index.css";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
const { apiEndpoint } = config;

const RequestProductApproval = () => {
  const decode = tokenDecode();
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]); // Type annotation added here
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    // Fetch products from the server and set productOptions state
    const fetchProducts = async () => {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/product/communityvendorrentalProduct`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: decode?.data?.user?.seller_id,
          },
        });

        if (data.status === 200) {
          setProductOptions(data.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchProducts();
  }, []);

  const handleProductSelection = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setSelectedProducts(selectedOptions);
  };

  const sendRequest = async () => {
    setLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/sendRentalRequestEmail`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decode?.data?.user?.seller_id,
          email:decode?.data?.user?.email,
          products: selectedProducts 
        },
      });

      if (data.status === 200) {
        toast.success("Request sent successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Error sending request", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  
    setLoading(false);
  };

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-2">
          <div className="col-md-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Request Product Approval
                </h6>
              </div>
              <div className="card-body" style={{ minHeight: "70vh" }}>
                <div className="form-group">
                  <label className="genericlabel">Select Products</label>
                  <select
                    className="form-control text-capitalize"
                    multiple
                    value={selectedProducts}
                    onChange={handleProductSelection}
                  >
                    {productOptions.map((product:any) => (
                      <option key={product.reference_id} value={` <b>${product.reference_id}</b> (${product.name})`}>
                        {product.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={sendRequest}
                    disabled={selectedProducts.length === 0 || loading}
                  >
                    {loading ? "Sending..." : "Send Request"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default RequestProductApproval;
