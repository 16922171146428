import AllRoutes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export default function App() {
  return (
    <>
      <AllRoutes />
      <ToastContainer />
    </>
  );
}
