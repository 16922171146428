import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CustomerOrder from "./customerOrder";
import CompleteOrder from "./completeOrder";
import CancelOrder from "./cancelOrderlist";

export default function Index() {
  return (
    <>
      <div className="row m-0 w-100 mt-1">
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header p-0">
              <Tabs className="orders">
                <TabList className="custom-tab-list">
                  <Tab className="custom-tab">Customer Orders</Tab>
                  <Tab className="custom-tab">Complete Orders</Tab>
                  <Tab className="custom-tab">Cancelled Orders</Tab>
                </TabList>
                <TabPanel>
                  <CustomerOrder />
                </TabPanel>
                <TabPanel>
                  <CompleteOrder />
                </TabPanel>
                <TabPanel>
                  <CancelOrder />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
