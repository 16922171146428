import Sidebar from "./sidebar";
import Navbar from "./navbar";
import Footer from "./footer";
import React, { ReactNode, useState } from "react";
import styles from "../thememaker/sidebar/Side_bar.module.css";
import style from "./thememaker.module.css";
import { useHistory } from "react-router-dom";

interface Props {
  children?: ReactNode;
}

export default function ThemeMaker({ children }: Props) {
  const [showMenu, setshowMenu] = useState(false);
  const history = useHistory();
  const Showmenu = () => {
    setshowMenu(true);
    history.push("/sidebar");
  };
  return (
    <>
      <div className={style.smallScreenSidebar}>
        <div id="wrapper">
          <div id="content-wrapper" className={`d-flex flex-column `}>
            <div id="content">
            <Navbar Showmenu={Showmenu} />
              <div className={`container-fluid ${styles.theme_}`}>
                {showMenu ? (
                  <Sidebar />
                ) : (
                  <div className="d-flex w-100">
                    {" "}
                    <div className={styles.theme_sidemenuDesktop}> </div>{" "}
                    {children}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.bigScreenSidebar}>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Navbar />
              {children}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
