import { useTable, usePagination } from "react-table";
import "./table.css";
import { useState, useEffect } from "react";

export function Paginated({ columns, data }) {
  const [urlSegment,setUrlSegment] = useState(null);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  const { pageIndex, pageSize } = state;
  
  useEffect(() => {
    const currentPath = window.location.pathname.split('/');
    setUrlSegment(currentPath[1]);
  }, []);
  
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
              {row.cells.map((cell,index) => {
                const isLastTwoCells = index === row.cells.length - 1 || index === row.cells.length - 2;
                const isFirstCell = index === 0;
                const isSecondCell = index === 1;
                const applyWidthForFirstCell =(isFirstCell && urlSegment === "newcategory") || urlSegment === "brand";
                const applyWidthForSecondCell = isSecondCell && urlSegment === 'newcategory';

                return (
                  <td {...cell.getCellProps()} style={applyWidthForFirstCell ? {width: "95px"} : applyWidthForSecondCell ? {width:"80%"}:isLastTwoCells ? { padding: "0.75rem 0rem", width:"95px" } : {}}>{cell.render("Cell")}</td>
                );
              })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="my-3 d-flex justify-content-center align-items-center ">
        <div className={`d-flex ${(urlSegment === "catalog" || urlSegment === "masterproductlist") ? 'justify-content-end col-md-8' : 'justify-content-center'} `} style={{ fontSize: "12px" }}>
          <button onClick={() => gotoPage(0)}
            className="page_link_item"
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>{" "}
          <button
            onClick={() => previousPage()}
            className="page_link_item"
            disabled={!canPreviousPage}
          >
            Previous
          </button>{" "}
          <div className="d-flex" style={{ fontSize: "12px" }}>
            <span className="page_link_item">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span className="page_link_item">
              | Go to page:{" "}
              <input
                type="number"
                className=""
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
                style={{ width: "50px" }}
              />
            </span>{" "}
          </div>
          <button
            onClick={() => nextPage()}
            className="page_link_item"
            disabled={!canNextPage}
          >
            Next
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            className="page_link_item"
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <select
            value={pageSize}
            className="page_link_item"
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        {(urlSegment === "catalog" || urlSegment === "masterproductlist") && (
          <div className="d-flex justify-content-end col-md-4">
            <a href="https://community.savageseller.com" rel="noreferrer" target="_blank" className="btn btn-primary mx-2" disabled={true}>Return To Group Hub</a>
          </div>
        )}
          
      </div>
    </>
  );
}
