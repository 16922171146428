import { useEffect, useRef } from "react";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";
import { config } from "../../utils/config";

const { apiEndpoint } = config;

export default function Sellerauthcheck() {
  const headers = JSON.parse(JSON.stringify(window.location));
  const history = useHistory();
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    async function getId() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const HULID = urlParams.get("HULID");
      const Passedtoken = urlParams.get("ACCESSTOKEN");
      var decoded = jwtDecode(Passedtoken);
      const { data } = await axios({
        url: `${apiEndpoint}/checksellerauth`,
        method: "POST",
        data: {
          hulid: HULID,
          accesstoken: Passedtoken,
        },
      });
      console.log("data--->", data);
      if (data) {
        localStorage.setItem("us_st_d", data.data.token);
        var decode = jwtDecode(data.data.token);
        let decodedUserdata = decode.data.seller_data;
        if (decodedUserdata.gst_no == null && decodedUserdata.pan_no == null) {
          history.push(`/selleregister?hulid=${HULID}`);
        } else if (decodedUserdata.approve_status == 1) {
          history.push(`/dashboard`);
        } else if (decodedUserdata.approve_status == 0) {
          async function givedta() {
            const { data } = await axios({
              url: `${apiEndpoint}/getbankdetails`,
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
              },
            });
            // console.log("data", data)
            if (data.status === 200) {
              if (
                data.data[0].opening_time == null ||
                data.data[0].bank_account == null ||
                data.data[0].pan_name == null
              ) {
                if (data.data[0].opening_time == null) {
                  history.push(`/sellerdetail?tab=shopdetails`);
                } else if (data.data[0].pan_name == null) {
                  history.push(`/sellerdetail?tab=personaldetails`);
                } else if (data.data[0].bank_account == null) {
                  history.push(`/sellerdetail?tab=bankdetails`);
                }
              } else {
                history.push(`/pending`);
              }
            }
          }
          givedta();
        }
      }
    }
    if (!renderAfterCalled.current) {
      getId();
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <BeatLoader color="#0094ff" />
    </div>
  );
}
