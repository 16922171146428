import { jwtDecode } from "jwt-decode";

export const tokenDecode = (tk_access?: any) => {
  try {
    if (tk_access) {
      localStorage.setItem("us_st_d", tk_access);
    }
    const token: any = localStorage.getItem("us_st_d");
    const decoded: any = jwtDecode(token);
    const expirationDate = new Date(decoded?.exp * 1000);
    // console.log('Token expiration date:', expirationDate);

      const currentDate = new Date();
      
    
    if (expirationDate < currentDate) {
      return false
      
    } else {
      return decoded;
    }
  } catch (error) {
    return false;
  }
};
