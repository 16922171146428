// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomerQuery_main__XkgEe {
  padding: 3px;
  /* border-radius: 10px; */
  border-radius: 0.35rem;
  margin: 4px 2px;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);
  outline: none !important;
}

.CustomerQuery_main__XkgEe .CustomerQuery_queryindex__LKfk1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #4e73df;
  color: white;
  text-align: center;
}
.CustomerQuery_main__XkgEe .CustomerQuery_queryindex__LKfk1 .CustomerQuery_customerName__JZnYT {
  font-size: 18px;
  font-weight: 600;
  margin-top: 2px;
}
.CustomerQuery_queryTiming__NvRgf {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
}
.CustomerQuery_queryContent__EkkCm {
  font-size: 18px;
}
.CustomerQuery_queryValue__\\+\\+hX\\+,
.CustomerQuery_queryMsg__6pZkY {
  color: #000;
}
.CustomerQuery_queryLabel__U9j5B {
  font-size: 14px;
  font-weight: 600;
  color: black;
}
.CustomerQuery_openQuery__kX55B {
  color: #000;
  cursor: pointer;
}
.CustomerQuery_openQuery__kX55B:hover {
  color: #858796;
}
.CustomerQuery_noQuery__dqmOq {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomerQuery/CustomerQuery.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,sBAAsB;EACtB,eAAe;EACf,mBAAmB;EACnB,wCAAwC;EACxC,wBAAwB;AAC1B;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,0BAA0B;EAC1B,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;AACA;;EAEE,WAAW;AACb;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB","sourcesContent":[".main {\n  padding: 3px;\n  /* border-radius: 10px; */\n  border-radius: 0.35rem;\n  margin: 4px 2px;\n  align-items: center;\n  box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);\n  outline: none !important;\n}\n\n.main .queryindex {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  background-color: #4e73df;\n  color: white;\n  text-align: center;\n}\n.main .queryindex .customerName {\n  font-size: 18px;\n  font-weight: 600;\n  margin-top: 2px;\n}\n.queryTiming {\n  text-transform: capitalize;\n  font-size: 14px;\n  font-weight: bold;\n}\n.queryContent {\n  font-size: 18px;\n}\n.queryValue,\n.queryMsg {\n  color: #000;\n}\n.queryLabel {\n  font-size: 14px;\n  font-weight: 600;\n  color: black;\n}\n.openQuery {\n  color: #000;\n  cursor: pointer;\n}\n.openQuery:hover {\n  color: #858796;\n}\n.noQuery {\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `CustomerQuery_main__XkgEe`,
	"queryindex": `CustomerQuery_queryindex__LKfk1`,
	"customerName": `CustomerQuery_customerName__JZnYT`,
	"queryTiming": `CustomerQuery_queryTiming__NvRgf`,
	"queryContent": `CustomerQuery_queryContent__EkkCm`,
	"queryValue": `CustomerQuery_queryValue__++hX+`,
	"queryMsg": `CustomerQuery_queryMsg__6pZkY`,
	"queryLabel": `CustomerQuery_queryLabel__U9j5B`,
	"openQuery": `CustomerQuery_openQuery__kX55B`,
	"noQuery": `CustomerQuery_noQuery__dqmOq`
};
export default ___CSS_LOADER_EXPORT___;
