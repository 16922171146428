import { useEffect, useState } from "react";
import ThemeMaker from "../../components/thememaker";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { tokenDecode } from "../../utils/helper";
import axios from "axios";
import { config } from "../../utils/config";
import { useParams } from "react-router-dom";
import { show, hide } from "react-functional-modal";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import "../../index.css";

const { apiEndpoint } = config;

const s3Config = {
  bucketName: "bucket-name",
  dirName: "directory-name",
  region: "ap-south-1",
  accessKeyId: "ABCD12EFGH3IJ4KLMNO5",
  secretAccessKey: "a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0",
  s3Url: "https:/your-aws-s3-bucket-url/",
};

const aws = require("aws-sdk");

const ImageContainer = styled.div`
  border: 1px solid black;
  border-radius: 27px;
  width: 83%;
  background: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: 100% !important;
    height: 80%;
    object-fit: contain;
  }
  button {
    position: absolute;
    top: 4px;
    right: 11px;
    background: none;
    outline: none;
    border: none;
  }
`;

export default function MasterProductAdd() {
  const history=useHistory();
  const decode = tokenDecode();
  let current_sellerid = decode.data.user.seller_id;
  let rawSubCatData = {
    parent_id: "",
    name: "",
    status: true,
    id: "",
  };
  const [pageNamem, setpageName] = useState(true);
  const [uploading, setUpLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [brandlist, setbrandlist] = useState([]);
  const [readonly, setreadonly] = useState(false);
  const returnWindowLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const timeToShipLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const [Nadata, setNadata] = useState([]);
  const [subcattoggle, setSubcattoggle] = useState(false);
  const [Subcategorydata, setSubcategorydata] = useState(rawSubCatData);

  const [rentalInfo, setRentalInfo] = useState([
    { day: "1 Day", price: "", quantity: "" },
    { day: "2 Day", price: "", quantity: "" },
    { day: "3 Day", price: "", quantity: "" },
    { day: "4 Day", price: "", quantity: "" },
    { day: "5 Day", price: "", quantity: "" },
    { day: "6 Day", price: "", quantity: "" },
    { day: "Weekly", price: "", quantity: "" },
  ]);
  const defaultRentalOptions = [
    { day: "1 Day", price: "", quantity: "" },
    { day: "2 Day", price: "", quantity: "" },
    { day: "3 Day", price: "", quantity: "" },
    { day: "4 Day", price: "", quantity: "" },
    { day: "5 Day", price: "", quantity: "" },
    { day: "6 Day", price: "", quantity: "" },
    { day: "Weekly", price: "", quantity: "" },
  ];
  
  const mergeRentalOptions = (defaultOptions, fetchedOptions) => {
    return defaultOptions.map((defaultOption) => {
      const matchingFetchedOption = fetchedOptions.find(
        (fetchedOption) => fetchedOption.day === defaultOption.day
      );
      return matchingFetchedOption
        ? { ...defaultOption, ...matchingFetchedOption }
        : defaultOption;
    });
  };
  
  const isRentalInfoFilled = () => {
    const filledCount = rentalInfo.filter(info => info.price.trim() !== "" && info.quantity.trim() !== "").length;
    return filledCount >= 2;
  };
  const [catName, setCatName] = useState("");
  useEffect(() => {
    taxListHanler();
    categoryListing();
    brandlisting();
    if (id) {
      setpageName(false);
      if (current_sellerid == 1) {
        getProduct();
      }
      if (current_sellerid !== 1) {
        readrejproductse();
      }
    } else {
      setpageName(true);
    }
  }, []);
  const getProduct = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/getproducts`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: { id },
      });
      
      if (data.status === 200) {
        setProductData(data.data);
        if (data?.data?.rent === "yes" || data?.data?.rent === "rentonly") {
          const fetchedRentalInfo = data.data.rentVarient || [];
          const updatedRentalInfo = mergeRentalOptions(defaultRentalOptions, fetchedRentalInfo);
          setRentalInfo(updatedRentalInfo);
        }
        const parsedData = data.response.map((item) => ({
          id: item.id,
          parent_id: item.parent_id,
          cat_name: item.cat_name,
          status: item.status,
          subcategory: item.subcategory || [],
        }));
        setNadata(parsedData);
      }
    } catch (error) {}
  };
  const readrejproductse = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/readrejpro`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { id },
      });
      if (data.status === 200) {
        setProductData(data.data);
        const parsedData = data.data.map((item) => ({
          id: item.id,
          parent_id: item.parent_id,
          cat_name: item.cat_name,
          status: item.status,
          subcategory: item.subcategory || [],
        }));
        setNadata(parsedData);
      }
    } catch (error) {}
  };

  const open = () => {
    show(
      <ImageContainer>
        <img src={productData.image} alt="" />
        <button
          onClick={() => {
            hide("key#1");
          }}
        >
          x
        </button>
      </ImageContainer>,
      { key: "key#1" }
    );
  };

  let params = useParams();
  const { id } = params;
  const rawData = {
    seller_id: decode?.data?.user?.seller_id,
    name: "",
    //net_qty: "",
    quantity: "",
    description: "",
    category_id: "",
    mrp: 0,
    selling_price: 0,
    tax_included_price: 0,
    tax: 0,
    tags: "",
    image: [],
    sku: "",
    hsn: "",
    approve_status: false,
    returnable: "",
    cancelable: "",
    return_window: "",
    seller_pickup_return: "",
    cod: "",
    tax_included_check: "",
    replaceable: "",
    categories_id: 0,
    brand: "",
  };
  const rawDataError = {
    name: false,
    description: false,
    category_id: false,
    //net_qty: false,
    quantity: false,
    mrp: false,
    selling_price: false,
    tax_included_price: false,
    tax: false,
    tags: false,
    image: false,
    sku: false,
    hsn: false,
    approve_status: false,
    returnable: false,
    cancelable: false,
    return_window: false,
    seller_pickup_return: false,
    cod: false,
    tax_included_check: false,
    replaceable: false,
    category_id: false,
    brand: false,
  };
  const [productData, setProductData] = useState(rawData);
  const [productDataError, setProductDataError] = useState(rawDataError);
  const createVariants = () => {
    const variants = rentalInfo
    .filter((info) => info.price && info.quantity)
      .filter((variant) => variant.title !== "Buy" && variant.option1 !== "Buy")
      .map((info) => ({
        price: info.price,
        inventory_management: "shopify",
        inventory_quantity: info.quantity,
        compare_at_price: info.price,
        tags: productData.tags,
        title: info.day + `  Rent`,
        option1: info.day + ` Rent`,
        day: info.day,
        quantity: info.quantity,
      }));

    variants.push({
      price: productData.selling_price,
      inventory_management: "shopify",
      inventory_quantity: productData.quantity,
      compare_at_price: productData.mrp,
      tags: productData.tags,
      title: "Buy",
      option1: "Buy",
    });
    return variants;
  };
  const createVariants1 = () => {
    const variants = rentalInfo
      .filter((info) => info.price && info.quantity)
      .filter((variant) => variant.title !== "Buy" && variant.option1 !== "Buy")
      .map((info) => ({
        price: info.price,
        inventory_management: "shopify",
        inventory_quantity: info.quantity,
        compare_at_price: info.price,
        tags: productData.tags,
        title: info.day + ` Rent`,
        option1: info.day + ` Rent`,
        day: info.day,
        quantity: info.quantity,
      }));
    console.log(variants);
    return variants;
  };
  const updateProductHanlder = async () => {
    const errorEmptyAlert = () => {
      toast.error("Please fill the required fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    const {
      name,

      description,
      category_id,
      mrp,
      //net_qty,
      quantity,
      selling_price,
      tax_included_price,
      tax,
      tags,
      image,
      sku,
      hsn,
      approve_status,
      returnable,
      cancelable,
      return_window,
      seller_pickup_return,
      cod,
      product_type,
      tax_included,
      replaceable,
      brand,
      rent,
    } = productData;

    if (name && description && mrp && approve_status !== "") {
      if ((rent === "yes" || rent === "rentonly") && !isRentalInfoFilled()) {
        toast.error("Please fill atleast 2 rent price and rent quantity!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        return;
      }
      const productImages = image;

      const images = productImages.map((src) => ({ src }));
      let updatedDataToSend;
      updatedDataToSend = productData;
      console.log(productData.approve_status);

      let updatedTags = productData.tags ? productData.tags.split(",") : [];
      if (!updatedTags.includes("multi_vendor")) {
        updatedTags.push("multi_vendor");
      }
      if (rent === "yes" || rent === "rentonly") {
        if (!updatedTags.includes("rental")) {
          updatedTags.push("rental");
        }
      } else {
        updatedTags = updatedTags.filter((tag) => tag !== "rental");
      }
      updatedTags = updatedTags.join(",");

      try {
        let variants;
        if (rent === "yes") {
          variants = createVariants();
        } else if (rent === "rentonly") {
          variants = createVariants1();
        } else {
          variants = [
            {
              price: productData.selling_price,
              sku: productData.sku,
              inventory_management: "shopify",
              inventory_quantity: productData.quantity,
              compare_at_price: productData.mrp,
              tags: productData.tags,
            },
          ];
        }
        setUpLoading(true);
        const datan = {
          id: productData.reference_id,
          product: {
            title: productData.name,
            body_html: productData.description,
            // vendor:decode?.data?.user?.name,
            status: productData.approve_status === "false" ? "draft" : "active",
            tags: updatedTags,
            product_type: productData.brand,
            published_at: new Date(),
            variants: variants,
            options: [
              {
                name: "Buy and Rent",
                values: rentalInfo.map((info) => `${info.day} day Rent`),
              },
            ],
            images: images,
          },
        };
        if (catName) {
          datan.product.product_type = catName;
        }
        const shopifyProductResponse = await axios({
          method: "POST",
          url: `${apiEndpoint}/product/updateproductinshopifystore`, // Shopify API endpoint to add product
          headers: {
            "Content-Type": "application/json",
            "X-Shopify-Access-Token": "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",
          },
          data: datan,
        });
        // If the product is updated to Shopify successfully
        if (shopifyProductResponse.status === 201) {
          setProductData(productData);
          const data = await axios({
            url: `${apiEndpoint}/product/updateproducts`,
            method: "POST",
            headers: {
              Authorization: `${localStorage.getItem("us_st_d")}`,
            },
            data: {
              ...updatedDataToSend,
              id: id,
              rentVarient: variants,
            },
          });
          setUpLoading(false);
          if (data.status === 200) {
            history.push("/catalog");
            toast.success("Product updated successfully !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("404 error !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          toast.error("404 error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        setUpLoading(false);
        toast.error("404 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      console.log("from last ");
      errorEmptyAlert();
    }
  };
  async function brandlisting() {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/brand/getBrandList`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });

      if (data.status === 200) {
        setbrandlist(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const inputHandler = (e) => {
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };
  const errorHandler = (e) => {
    if (e.target.value) {
      setProductDataError({ ...productDataError, [e.target.name]: false });
    } else {
      setProductDataError({ ...productDataError, [e.target.name]: true });
    }
  };
  const taxListHanler = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/tax/gettaxlist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      // console.log("tax listing", data);
      if (data.status === 200) {
        setTaxData(data.data);
      }
    } catch (error) {}
  };

  const categoryListing = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/categories/getcollectionlistinshopifystore`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Shopify-Access-Token": "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",
        },
      });
      if (data.status === 201) {
        setCategoryList(data.response);
      }
    } catch (error) {}
  };

  const priceHandler = (e) => {
    // console.log("e.target.name", e.target.name);
    if (e.target.name === "selling_price") {
      const percentAmmount =
        (parseFloat(productData.tax) / 100) * parseFloat(e.target.value);
      setProductData((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: productData?.tax_included_check
          ? e.target.value
          : parseFloat(e.target.value) + parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax") {
      const percentAmmount =
        (parseFloat(e.target.value) / 100) *
        parseFloat(productData.selling_price);
      setProductData((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: productData?.tax_included_check
          ? productData?.tax_included_price
          : parseFloat(productData.selling_price) +
            parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax_included_check") {
      const percentAmmount =
        (parseFloat(productData.tax) / 100) *
        parseFloat(productData.selling_price);
      setProductData({
        ...productData,
        [e.target.name]: !productData?.tax_included_check,
        tax_included_price: !productData?.tax_included_check
          ? productData.selling_price
          : percentAmmount + parseFloat(productData.selling_price),
      });
    }
  };
  const productImageHandler = async (e) => {
    try {
      setLoading(true);
      const files = Array.from(e.target.files); // Get array of files
      const image = [];
      for (const file of files) {
        let myFile = file.name.split(".");
        let myFileType = myFile[myFile.length - 1];
        const s3 = new aws.S3({
          accessKeyId: config?.awsKeyId,
          secretAccessKey: config?.awsSecretKey,
        });
        const params = {
          Bucket: "savageseller",
          Key: `${uuidv4()}.${myFileType}`,
          Body: file,
          ContentType: file.type,
        };
        let result = await s3.upload(params).promise();
        const { Location } = result;
        image.push(Location); // Store image URL in array
      }
      setProductData({
        ...productData,
        image: [...productData.image, ...image],
      });
      setLoading(false);
    } catch (exception) {
      console.log(exception);
    }
  };
  const deleteImage = (index) => {
    const updatedImages = [...productData.image];
    updatedImages.splice(index, 1);
    setProductData({ ...productData, image: updatedImages });
  };

  const handleSubcategoryChange = (id) => {
    setSubcategorydata({ ...Subcategorydata, parent_id: id });
  };

  const MakeCategory = ({ cat_name, subcategory, id }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(
      productData.categories_id && productData.categories_id.includes(id)
    );
    const [subCategoryOpen, setSubCategoryOpen] = useState(
      Array(subcategory.length).fill(false)
    );
    const subCategoryChecker = subcategory && subcategory.length > -1;

    const handleCategoryClick = () => {
      setIsOpen(!isOpen);
    };
    const handleCheckboxChange = (event, catName) => {
      const newCheckedState = event.target.checked;
      setIsChecked(newCheckedState);
      onCategoryClick(id, newCheckedState);
      setCatName(catName);
    };

    const handleSubCategoryClick = (index) => {
      const newSubCategoryOpen = [...subCategoryOpen];
      newSubCategoryOpen[index] = !newSubCategoryOpen[index];
      setSubCategoryOpen(newSubCategoryOpen);
    };

    function onCategoryClick(id, checked) {
      if (checked) {
        setProductData({
          ...productData,
          categories_id: [...productData.categories_id, id],
        });
      } else {
        setProductData({
          ...productData,
          categories_id: productData.categories_id.filter(
            (category_id) => category_id !== id
          ),
        });
      }
    }

    useEffect(() => {
      setIsChecked(
        productData.categories_id && productData.categories_id.includes(id)
      );
    }, [id]);

    console.log("productData.categories_id", productData.categories_id);
    return (
      <div className="makeCategory_subCategory">
        <ul style={{ marginLeft: "20px" }}>
          <li className="my-0" key={id}>
            {subCategoryChecker && (
              <span onClick={handleCategoryClick}>
                {subCategoryChecker ? (
                  isOpen ? (
                    <RemoveIcon />
                  ) : (
                    <AddIcon />
                  )
                ) : (
                  ""
                )}
              </span>
            )}
            <input
              type="checkbox"
              value={id}
              name="sub-category"
              checked={isChecked}
              onChange={(e) => handleCheckboxChange(e, cat_name)}
            />{" "}
            {cat_name}
          </li>
          {isOpen &&
            subCategoryChecker &&
            subcategory.map((item, index) => (
              <MakeCategory
                key={item.id}
                {...item}
                isOpen={subCategoryOpen[index]}
                onSubCategoryClick={() => handleSubCategoryClick(index)}
                hideIcon={index === subcategory.length - 1}
              />
            ))}
        </ul>
      </div>
    );
  };
  const forbiddenKeywords = [
    "Nike",
    "Jordan",
    "Adidas",
    "vape",
    "cannabis",
    "marijuana",
    "CBD",
    "THC",
    "weed",
    "firearm",
    "gun",
    "bullet",
    "dope",
    "pistol",
    "cleaning supplies",
    "cleaning equipment",
    "holsters",
    "riffle",
    "shotguns",
    "Air pistols",
    "sawa",
    "range finders",
    "rifle parts",
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if the entered value contains any forbidden keywords
    const containsForbiddenKeyword = forbiddenKeywords.some((keyword) =>
      value.toLowerCase().includes(keyword.toLowerCase())
    );
    if (containsForbiddenKeyword) {
      setProductDataError({
        ...productDataError,
        name: "Product name contains forbidden keywords",
      });
      if (name === "brand") {
        setProductDataError({
          ...productDataError,
          brand: "Brand name contains forbidden keywords",
        });
      }
    } else {
      setProductDataError({
        ...productDataError,
        name: "",
        brand: "This field is required !",
      });
      setProductData({ ...productData, [name]: value });
    }
  };
  const renderRentalInfoFields = () => {
    console.log(rentalInfo);
    return rentalInfo.map(
      (info, index) =>
        info.option1 !== "Buy" &&
        info.title !== "Buy" && (
          <div
            key={index}
            className="rental-info-fields mb-3 row d-flex align-items-end"
          >
            <div className="d-block col-md-3">
              {info.day === "" && (
                <span className="text-danger tiny_size">
                  This field is required!
                </span>
              )}

              <input
                type="text"
                value={info.day}
                onChange={(e) =>
                  handleRentalInfoChange(index, "day", e.target.value)
                }
                placeholder={info.day}
                className="form-control"
                disabled={true}
              />
            </div>
            <div className="d-block col-md-3">
              {/*{info.price === "" && (
                <span className="text-danger tiny_size">
                  This field is required!
                </span>
              )}*/}
              <input
                type="number"
                value={info.price}
                onChange={(e) =>
                  handleRentalInfoChange(index, "price", e.target.value)
                }
                placeholder="Price"
                className="form-control"
              />
            </div>
            <div className="d-block col-md-3">
              {/*{info.quantity === "" && (
                <span className="text-danger tiny_size">
                  This field is required!
                </span>
              )}*/}

              <input
                type="number"
                value={info.quantity}
                onChange={(e) =>
                  handleRentalInfoChange(index, "quantity", e.target.value)
                }
                placeholder="Available Quantity"
                className="form-control"
              />
            </div>
            {/*<div className="d-block col-md-3">
         {rentalInfo.length > 1 && (
           <button onClick={() => removeRentalInfo(index)} className="btn-danger"><RemoveIcon /> </button>
         )}
        </div>*/}
          </div>
        )
    );
  };

  const renderAddMoreButton = () => {
    return (
      <button onClick={addMoreRentalInfo} className="btn-primary">
        <AddIcon />
      </button>
    );
  };
  const handleRentalInfoChange = (index, field, value) => {
    const updatedRentalInfo = [...rentalInfo];
    updatedRentalInfo[index][field] = value;
    setRentalInfo(updatedRentalInfo);
  };

  // Function to add more rental information fields
  const addMoreRentalInfo = () => {
    setRentalInfo([...rentalInfo, { day: "", price: "", quantity: "" }]);
  };
  const removeRentalInfo = (indexToRemove) => {
    const updatedRentalInfo = rentalInfo.filter(
      (_, index) => index !== indexToRemove
    );
    setRentalInfo(updatedRentalInfo);
  };
  console.log(rentalInfo);

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  {pageNamem ? "Create product" : "Update product"}
                </h6>
              </div>
              <div className="card-body">
                <p>
                  <b>Disclaimer:</b> Nike, Jordan, Adidas, vape, cannabis,
                  marijuana, CBD, THC, weed, firearm, gun, bullet, dope, pistol,
                  cleaning supplies, cleaning equipment, holsters, riffle,
                  shotguns, Air pistols, sawa, range finders, rifle parts, etc.
                  — the mentioned products are excluded from the listing.
                </p>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Name</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.name ? (
                        <span className="text-danger tiny_size">
                          {productDataError?.name}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter title"
                        value={productData.name}
                        onChange={handleChange}
                        onBlur={(e) => errorHandler(e)}
                        readOnly={readonly}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Category</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.category_id ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      {/* code for multi selections:-- */}
                      <select
                        className="form-control"
                        value={productData.category_id}
                        name="category_id"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={readonly}
                      >
                        <option value="">Select Category</option>
                        {categoryList.map((item) => (
                          <option value={item.id}>{item.title}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="genericlabel">Description</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.description ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <textarea
                        className="form-control"
                        name="description"
                        placeholder="Enter product description"
                        value={productData.description}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        readOnly={readonly}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Selling price in USD
                        <span className="text-danger"> * </span>
                      </label>
                      {productDataError?.selling_price ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="number"
                        className="form-control"
                        name="selling_price"
                        placeholder="selling_price"
                        value={productData.selling_price}
                        onChange={(e) => priceHandler(e)}
                        readOnly={readonly}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Original Price in USD
                      </label>
                      <span className="text-danger"> * </span>
                      {productDataError?.mrp ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="number"
                        className="form-control"
                        name="mrp"
                        placeholder="Max price"
                        value={productData.mrp}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Net Quantity</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.net_qty ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="net_qty"
                        placeholder="Net Quantity"
                        value={productData.net_qty}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      />
                    </div>
                    </div>*/}

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Quantity</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.quantity ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="number"
                        className="form-control"
                        name="quantity"
                        placeholder="Quantity"
                        value={productData.quantity}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Tags</label>
                      <input
                        type="text"
                        className="form-control"
                        name="tags"
                        placeholder="Enter Tags"
                        value={productData.tags}
                        onChange={(e) => inputHandler(e)}
                        readOnly={readonly}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        SKU (Stock Keeping Unit)
                      </label>
                      <span className="text-danger"> * </span>
                      <span
                        className="d-inline-block cursor-pointer"
                        title="Use a Unique, Consistent Format. Avoid Special Characters. Example: TSHIRT098123"
                      >
                        <InfoIcon color="primary" fontSize="small" />
                      </span>
                      {productDataError?.sku ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="sku"
                        placeholder="Enter SKU"
                        value={productData.sku}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        readOnly={readonly}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Rental Product :</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.rent ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}

                      <div className="d-flex" style={{}}>
                        <label
                          htmlFor="rentYes"
                          style={{ marginBottom: "0px" }}
                        >
                          <input
                            type="radio"
                            id="rentYes"
                            name="rent"
                            value="yes"
                            checked={productData.rent === "yes"}
                            onChange={(e) =>
                              setProductData({
                                ...productData,
                                [e.target.name]: e.target.value,
                              })
                            }
                            onBlur={(e) => errorHandler(e)}
                          />
                          Rent and Sell
                        </label>
                        <label
                          htmlFor="rentNo"
                          style={{ marginBottom: "0px", marginLeft: "10px" }}
                        >
                          <input
                            type="radio"
                            id="rentNo"
                            name="rent"
                            value="no"
                            checked={productData.rent === "no"}
                            onChange={(e) =>
                              setProductData({
                                ...productData,
                                [e.target.name]: e.target.value,
                              })
                            }
                            onBlur={(e) => errorHandler(e)}
                          />
                          Sell Only
                        </label>
                        <label
                          htmlFor="rentOnly"
                          style={{ marginBottom: "0px", marginLeft: "10px" }}
                        >
                          <input
                            type="radio"
                            id="rentOnly"
                            name="rent"
                            value="rentonly"
                            checked={productData.rent === "rentonly"}
                            onChange={(e) =>
                              setProductData({
                                ...productData,
                                [e.target.name]: e.target.value,
                              })
                            }
                            onBlur={(e) => errorHandler(e)}
                          />
                          Rent Only
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      {productData.rent === "yes" && (
                        <>
                          <label className="genericlabel">Rent Settings:</label>
                          <span className="text-danger"> * </span>

                          <div className="row">
                            <div className="col-md-10">
                              {renderRentalInfoFields()}
                            </div>
                            {/*<div className="col-md-2">
                {renderAddMoreButton()}
                  </div>*/}
                          </div>
                        </>
                      )}
                      {productData.rent === "rentonly" && (
                        <>
                          <label className="genericlabel">Rent Settings:</label>
                          <span className="text-danger"> * </span>

                          <div className="row">
                            <div className="col-md-10">
                              {renderRentalInfoFields()}
                            </div>
                            {/*<div className="col-md-2">
                        {renderAddMoreButton()}
                  </div>*/}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group">
                      <label className="genericlabel">Images: </label>
                      <span className="text-danger">*</span>
                      <span style={{ color: "#70bb80fc", paddingLeft: "50px" }}>
                        {loading ? "Please wait while uploading......." : ""}
                      </span>
                      {productDataError?.image && (
                        <span className="text-danger tiny_size">
                          This field is required!
                        </span>
                      )}
                      <input
                        type="file"
                        className="form-control text-white"
                        style={{ lineHeight: "1" }}
                        accept="image/*"
                        name="image"
                        multiple // Allow multiple file selection
                        onChange={(e) => productImageHandler(e)}
                        onBlur={(e) => errorHandler(e)} // You need to implement errorHandler function
                        disabled={readonly}
                      />
                      <p>
                        {productData.image.length}{" "}
                        {productData.image.length === 1 ? "file" : "files"}{" "}
                        selected
                      </p>
                    </div>
                    <div
                      className="row"
                      style={{ backgroundColor: " #f3f3f3" }}
                    >
                      {productData.image.map((image, index) => (
                        <div
                          key={index}
                          className="col-md-2 col-sm-12 d-flex"
                          style={{ marginBottom: "10px" }}
                        >
                          <img
                            src={image}
                            alt={`Product Image ${index + 1}`}
                            style={{ maxWidth: "70%", height: "140px" }}
                          />
                          <button
                            onClick={() => deleteImage(index)}
                            className="btn btn-danger btn-sm "
                            style={{
                              width: "30px",
                              height: "30px",
                              fontSize: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "50%",
                            }}
                          >
                            x
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Brand</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.brand ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          {productDataError.brand}{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="brand"
                        placeholder="Enter Brand Name"
                        value={productData.brand}
                        onChange={handleChange}
                        onBlur={(e) => errorHandler(e)}
                        disabled={loading}
                      />
                      {/* <select
                        className="form-control"
                        value={productData.brand}
                        name="brand"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={readonly}
                      >
                        <option value="">Select Brand</option>
                        {brandlist.map((item) => (
                          <option value={item.brand_name}>{item.brand_name}</option>
                        ))}
                      </select> */}
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Product returnable</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.returnable ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <select
                        className="form-control"
                        value={productData.returnable}
                        name="returnable"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={readonly}
                      >
                        <option value="">Select returnable</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Product replaceable
                      </label>
                      <span className="text-danger"> * </span>
                      {productDataError?.replaceable ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <select
                        className="form-control"
                        value={productData.replaceable}
                        name="replaceable"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={readonly}
                      >
                        <option value="">Select replaceable</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Product cancelable</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.cancelable ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <select
                        className="form-control"
                        value={productData.cancelable}
                        name="cancelable"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={readonly}
                      >
                        <option value="">Select cancelable</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Return window</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.return_window ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <select
                        className="form-control"
                        value={productData.return_window}
                        name="return_window"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={readonly}
                      >
                        <option value="">Select return window</option>
                        {returnWindowLength.map((item) => (
                          <option value={`P${item}D`}>{`P${item}D`}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">
                        Seller pickup return
                      </label>
                      <span className="text-danger"> * </span>
                      {productDataError?.seller_pickup_return ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <select
                        className="form-control"
                        value={productData.seller_pickup_return}
                        name="seller_pickup_return"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={readonly}
                      >
                        <option value="">Select seller return pickup</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Cash on delivery</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.cod ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <select
                        className="form-control"
                        value={productData.cod}
                        name="cod"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={readonly}
                      >
                        <option value="">Select cash on delivery</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                    </div>*/}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="genericlabel">Approve Status</label>
                      <span className="text-danger"> * </span>
                      {productDataError?.approve_status ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <select
                        className="form-control"
                        value={productData.approve_status}
                        name="approve_status"
                        id=""
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                        disabled={readonly}
                      >
                        <option value="">Select Approve Status</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    {pageNamem ? (
                      <button type="submit" className="btn btn-primary">
                        {" "}
                        {loading ? "Loading..." : "Create"}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-success"
                        onClick={updateProductHanlder}
                      >
                        {" "}
                        {uploading ? "Loading..." : "Update"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-center"></h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-10">
                    <p> Location Name </p>
                  </div>
                  <div className="col-md-2">
                    <p> Quantity </p>
                  </div>
                  <>
                    <div className="col-md-10"></div>
                    <div className="col-md-2">
                      <input type="number" className="form-control" />
                    </div>
                  </>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  //   onClick={importCsvHanlder}
                >
                  {loading ? "updating..." : "Update"}
                </button>

                <button
                  type="button"
                  id="closebtn"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
