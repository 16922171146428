import styles from "./ProductTemplate.module.css";
import deleteicon from "../../icons/delete.png";
import edit from "../../icons/edit.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import { tokenDecode } from "../../utils/helper";
import { toast } from "react-toastify";
import eye from "../../icons/eye.png";
import Pagination from "../Paginated/Pagination";
import axios from "axios";

export default function ProductTemplate({
  data,
  deleteProduct,
  handle,
  categoryListing,
}) {
  const [openInput, setopenInput] = useState(null);
  const [quickUpdatedMaxPrice2, setquickUpdatedMaxPrice2] = useState("");
  const [quickUpdatedMaxPrice, setquickUpdatedMaxPrice] = useState("");
  const [productList, setproductList] = useState(data);
  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id;
  function maxPriceHandler(id) {
    setopenInput(openInput === id ? null : id);
    const maxPriceProduct = data.filter((item) => item.id == id);
    setquickUpdatedMaxPrice2(...maxPriceProduct);
    let mp;
    // console.log("maxPriceProduct---", maxPriceProduct)
    if (maxPriceProduct[0]?.selling_price) {
      mp = maxPriceProduct[0].selling_price;
      setquickUpdatedMaxPrice(mp);
    } else if (maxPriceProduct[0]?.mrp) {
      mp = maxPriceProduct[0].mrp;
      setquickUpdatedMaxPrice(mp);
    }
  }
  async function QuickUpdateHandler(id) {
    let updatedDataToSend;
    let dataForSeller2 = {
      product_id: id,
      price: quickUpdatedMaxPrice,
      status: true,
    };
    if (current_sellerid == 1) {
      updatedDataToSend = {
        ...quickUpdatedMaxPrice2,
        selling_price: quickUpdatedMaxPrice,
      };
    } else {
      updatedDataToSend = dataForSeller2;
    }
    // console.log(updatedDataToSend)
    const { data } = await axios({
      url: "https://shikahrapp.hulsecure.in/public/index.php/api/quickupdate",
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
      },
      data: updatedDataToSend,
    });
    if (data.status == 200) {
      setopenInput(null);
      toast.success("Product Updated successfully !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (current_sellerid == 1) {
      if (data.status == 200) {
        categoryListing();
      }
    }
  }
  return (
    <>
      {data.map((item, index) => {
        return (
          <div
            className={`d-flex justify-content-between w-100 ${styles.productTemplateMain}`}
            key={index}
          >
            <div className={`${styles.productTemplateImage}`}>
              <img src={item.image} alt="" />
            </div>
            <div className={`${styles.productTemplateDesc}`}>
              <p className="m-0">{item.name}</p>
              <p className="m-0 mb-1">SKU: {item.sku} </p>
              {item.selling_price ? (
                <p className="m-0">Rs.{item.selling_price}</p>
              ) : (
                <p className="m-0"> Mrp: {item.mrp}</p>
              )}
              {handle === "nonapprovedproduct" ? (
                item.retail_approve_status == 0 ? (
                  <button className={styles.approvalStatus}>Pending</button>
                ) : item.retail_approve_status === 1 ? (
                  <button>Approved</button>
                ) : (
                  <button className={styles.approvalStatus}>Decline</button>
                )
              ) : item.status ? null : null}
            </div>
            <div className={`${styles.productTemplateButtons}`}>
              {handle == "nonapprovedproduct" &&
              current_sellerid < 2 ? null : handle == "mastercatalog" &&
                current_sellerid > 1 ? null : (
                <div onClick={() => deleteProduct(item.id)}>
                  <img src={deleteicon} alt="" />
                </div>
              )}
              <Link
                to={`updatestoreproduct/${item.id}`}
                className="btn-sm m-1 btn btn-success btn-circle"
              >
                {handle == "product" && current_sellerid > 1 ? (
                  <div>
                    <img src={eye} alt="" />
                  </div>
                ) : (
                  <div>
                    <img src={edit} alt="" />
                  </div>
                )}
              </Link>
              {openInput === item.id && (
                <div style={{ position: "relative" }}>
                  <div class="pop-up">
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "12px",
                        color: "black",
                      }}
                      className="p-1 m-0"
                    >
                      Selling Price in USD
                    </p>
                    <span
                      className={styles.popupClose}
                      onClick={() => setopenInput(null)}
                    >
                      x
                    </span>
                    <div class="pop-up-content">
                      <div
                        class="form-group d-flex flex-column align-items-center"
                        style={{ gap: "3px" }}
                      >
                        <input
                          type="text"
                          class="form-control form-control-sm w-25"
                          value={quickUpdatedMaxPrice}
                          className="w-75 px-2"
                          onChange={(e) => {
                            setquickUpdatedMaxPrice(e.target.value);
                          }}
                        />
                        <button
                          style={{
                            marginLeft: "2px",
                            backgroundColor: "blue",
                            padding: "2px 5px",
                            borderRadius: "3px",
                            border: "none",
                            color: "white",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            QuickUpdateHandler(item.id);
                          }}
                        >
                          {" "}
                          Update{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
