/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import style from "./allorderslist.module.css";
import Loader from "../../components/tabelloader";
import { jwtDecode } from "jwt-decode";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import { fileAccepted } from "react-papaparse/dist/utils";
import { BasicTable } from "../../components/Table";
import { Paginated } from "../../components/Paginated";
import "../../components/table.css";
import { format } from "date-fns";
import moment from "moment";
import * as XLSX from "xlsx";

const { apiEndpoint } = config;
var $ = require("jquery");
export default function Index() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredData, setFilteredData]: any = useState([]);
  const [Search, setSearch] = useState();
  const [popup, setPopup] = useState(false);
  const [orderStatusPopup, setOrderStatusPopup] = useState(false);
  const [orderFulfillmentPopup, setOrderFulfillmentPopup] = useState(false);
  const [orderPopup, setOrderPopup] = useState([]);
  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = tokenDecode();
  const { user, product, order } = decoded.data.permission;
  const history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const [downloadcsv, setDownloadcsv] = useState({});
  const [loading, setloading] = useState(true);
  const closeModel: any = useRef();
  const [error, setError] = useState("");
  const [_id, setId] = useState("");
  const [fullFillmentStatus, setFullFillmentStatus] = useState("");
  const [status, setstatus] = useState();
  const [orderid, setorderid] = useState("");
  const [orderitemsid, setorderitemsid] = useState("");
  const [dynamicValue, setDynamicValue] = useState("In-progress");
  const [context, setcontext] = useState("");
  const handleClickPop = async (id: any) => {
    setPopup(!popup);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getsellerorder`,
        method: "POST",
        data: {
          order_id: id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      console.log("sas => ", data);
      if (data.status === 200) {
        setOrderPopup(data.data.reverse());
        const [object] = JSON.parse(data?.data?.items);
        console.log("object => ", object);
      } else {
        toast.error("404 not found !");
      }
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {
      setloading(false);
    }
  };
  const closePopup = () => {
    setPopup(false);
  };
  const getOrderList = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${apiEndpoint}/orddetails`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      const result = data;
      setOrderList(result);
      setFilteredData(result);
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {
      setloading(false);
      setError("error");
    }
  };
  const handleStartDateChange = (event: any) => {
    setStartDate(event.target.value);
    filterData(event.target.value, endDate);
  };
  const handleEndDateChange = (event: any) => {
    setEndDate(event.target.value);
    filterData(startDate, event.target.value);
  };
  const filterData = (start: any, end: any) => {
    const startFilter = start ? start : null;
    const endFilter = end ? end : null;
    const filtered = orderList.filter((item: any) => {
      const itemDate = item.created_at;
      return (
        (!startFilter || itemDate >= startFilter) &&
        (!endFilter || itemDate <= endFilter)
      );
    });
    setFilteredData(filtered);
  };
  const filterBySearch = async (event: any) => {
    const query = event.target.value;
    let SearchFiltere = orderList.filter((item: any) => {
      return (
        item?.sellerName.toLowerCase().includes(query.toLowerCase()) ||
        item?.ntOrderId?.toLowerCase().includes(query.toLowerCase()) ||
        item?.ntTransactionId
          ?.toString()
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        item?.sku?.toString().toLowerCase().includes(query.toLowerCase()) ||
        item?.pincode?.toString().toLowerCase().includes(query.toLowerCase()) ||
        item?.city?.toString().toLowerCase().includes(query.toLowerCase()) ||
        item?.orderStatus
          ?.toString()
          .toLowerCase()
          .includes(query.toLowerCase())
      );
    });
    setFilteredData(SearchFiltere);
  };
  const DownloadCsv = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: "/orders/exportcsv",
        // url:"localhost:9095/orders/exportcsv",
        method: "GET",
        headers: {
          "Content-Type": "application/csv",
        },
      });
      console.log(data);
      setDownloadcsv(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (status === "Accepted") {
      setFullFillmentStatus("Pending");
    }
    if (status === "In-progress") {
      setFullFillmentStatus("Order-picked-up");
    }
    if (status === "Completed") {
      setFullFillmentStatus("Order-delivered");
    }
    if (status === "Cancelled") {
      setFullFillmentStatus("Cancelled");
    }
  }, [status]);
  const changeStatus = async () => {
    try {
      const { data } = await axios({
        url: `/orders/orderstatus`,
        method: "PUT",
        data: {
          order_id: orderid,
          orderStatus: status,
          fullFillmentStatus: fullFillmentStatus,
        },
      });
      if (data.status === 200) {
        toast.success("Order status updated !");
        setOrderStatusPopup(false);
        changeStatusseller(orderid, context);
      } else {
        toast.error("404 not found !");
      }
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {}
  };
  const CancelItem = async (OrderId: any, item_id: any) => {
    const contextdata = {
      order_id: OrderId,
      item_id,
      status: "Cancelled",
    };
    try {
      const { data } = await axios({
        url: `/orders/cancel-item`,
        method: "PUT",
        data: contextdata,
      });
      toast.success("Status updated by seller app");
    } catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };
  const changeStatusseller = async (orderids: any, context: any) => {
    context.message_id = uuidv4();
    const contextdata = await {
      context,
      message: {
        order_id: orderids,
      },
    };
    try {
      const { data } = await axios({
        url: `/preprod/v1/status`,
        method: "POST",
        data: contextdata,
      });
      toast.success("unsolicited Status updated by seller app");
    } catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };
  const ChangeStatusPopup = (
   
    orderStatus: any,
    context: any
  ) => {
    console.log(">>>>>>>>>>", orderStatus);
    setOrderStatusPopup(true);
   
    setstatus(orderStatus);
    setcontext(context);
    setDynamicValue(orderStatus);
  };
  const closePop = () => {
    setOrderStatusPopup(false);
  };
  const CancelOrder = async (shopifyorderid: any) => {
    const confirmed = window.confirm(
      "Are you sure you want to cancel this order?"
    );
    if (confirmed) {
      try {
        const { data } = await axios({
          url: `/orders/cancelorder`,
          method: "POST",
          data: {
            shopifyorderid: shopifyorderid,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
        // console.log("sas => ", data);
        if (data.status === 200) {
          toast.success("Order Cancelled");
        } else {
          toast.error("404 not found!");
        }
        // console.log("data>>>>>>", data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const COLUMNS = [
    {
      Header: "Sn No.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Buyer NP Name",
      accessor: "buyerNpName",
      sticky: "left",
    },
    {
      Header: "Seller NP Name",
      accessor: (a: any) => (a ? "xpressbaazaar.com" : ""),
      sticky: "left",
    },
    {
      Header: "Order Create Date & Time",
      accessor: "created_at",
      Cell: (item: any) => moment(item.value).format("DD-MM-YYYY hh:mm:ss"),
      sticky: "left",
    },
    {
      Header: "Network Order Id",
      accessor: "ntOrderId",
      sticky: "left",
    },
    {
      Header: "Network Transaction Id",
      accessor: "ntTransactionId",
      sticky: "left",
    },
    {
      Header: "Seller NP Order Id",
      accessor: "sellerNpOrderId",
      sticky: "left",
    },
    {
      Header: "Item Id",
      accessor: (a: any) => a.item_id,
      sticky: "left",
    },
    {
      Header: "Qty",
      accessor: "quantity",
      sticky: "left",
    },
    {
      Header: "Seller NP Type (MSN / ISN)",
      accessor: "sellerNpType",
      sticky: "left",
    },
    {
      Header: "Order Status",
      accessor: "orderStatus",
      sticky: "left",
    },
    {
      Header: "Name of Seller",
      accessor: "sellerName",
      sticky: "left",
    },
    {
      Header: "Seller Pincode",
      accessor: "pincode",
      sticky: "left",
    },
    {
      Header: "Seller City",
      accessor: "city",
      sticky: "left",
    },
    {
      Header: "SKU Name",
      accessor: (a: any) => a.sku,
      sticky: "left",
    },
    {
      Header: "SKU Code",
      accessor: "sku",
      sticky: "left",
    },
    {
      Header: "Order Category (F&B/ Grocery/ Home & Decor)",
      accessor: "category_name",
      sticky: "left",
    },
    {
      Header: "Ready to Ship At Date & Time",
      accessor: (a: any) =>
        (a.orderStatus === "Accepted" ||
          a.orderStatus === "In-progress" ||
          a.orderStatus === "Completed") &&
        a.readyToShipped
          ? moment(a.readyToShipped).format("DD-MM-YYYY hh:mm:ss")
          : "",
      sticky: "left",
    },
    {
      Header: "Shipped At Date & Time",
      accessor: (a: any) => {
        if (a.shippedDate == "" || a?.shippedDate == null) {
          return "";
        } else {
          let formatedDdate = moment(a.shippedDate).format(
            "DD-MM-YYYY hh:mm:ss"
          );
          return formatedDdate;
        }
      },
      sticky: "left",
    },
    {
      Header: "Delivered At Date & Time",
      accessor: (a: any) => {
        if (a.deliveryDate !== "") {
          let formatedDdate = moment(a.deliveryDate).format(
            "DD-MM-YYYY hh:mm:ss"
          );
          return formatedDdate;
        } else {
          return "";
        }
      },
      // Cell: (item: any) => moment((item.value)).format("DD-MM-YYYY hh:mm:ss"),
      sticky: "left",
    },
    {
      Header: "Delivery Type (On-network/ Off-network)",
      accessor: "delivery_type",
      sticky: "left",
    },
    {
      Header: "Logistics Seller NP Name (For on-network delivery)",
      accessor: "logisticSellerNpName",
      sticky: "left",
    },
    {
      Header: "Logistics Network Order Id (For on-network delivery)",
      accessor: "logisticNetworId",
      sticky: "left",
    },
    {
      Header: "Logistics Network Transaction Id (For on-network delivery)",
      accessor: "logisticNetworkTransaction",
      sticky: "left",
    },
    {
      Header: "Delivery City",
      accessor: (a: any) => {
        let c = JSON.parse(a?.deliveryCity);
        return c?.address?.city;
      },
      sticky: "left",
    },
    {
      Header: "Delivery Pincode",
      accessor: (a: any) => {
        let c = JSON.parse(a?.deliveryCity);
        return c?.address?.area_code;
      },
      sticky: "left",
    },
    {
      Header: "Cancelled At Date & Time",
      accessor: (a: any) => {
        if (a.cancelled_date == "" || a.cancelled_date == null) {
          return "";
        } else {
          return moment(a.cancelled_date).format("DD-MM-YYYY hh:mm:ss");
        }
      },
      sticky: "left",
    },
    {
      Header: "Cancelled By (Buyer/Seller)",
      accessor: "canceledBy",
      sticky: "left",
    },
    {
      Header: "Cancelleation Reason",
      accessor: "cancelReason",
      sticky: "left",
    },
    {
      Header: "Total Shipping Charges",
      accessor: (a: any) => {
        let c = JSON.parse(a?.shippingCharges);
        return c.breakup[c?.breakup?.length - 2]?.price?.value;
      },
      sticky: "left",
    },
    {
      Header: "Total Order Value",
      accessor: "totalOrderValue",
      sticky: "left",
    },
    {
      Header: "Total Refund Amount",
      accessor: "totalRefundAmount",
      sticky: "left",
    },
  ];
  const exportToExcel = (orders: any) => {
    console.log("csv orders----->", orders);
    const headers = [
      "S.No",
      "Buyer NP Name",
      "Seller NP Name",
      "Order Create Date & Time",
      "Network Order Id",
      "Network Transaction Id",
      "Seller NP Order Id",
      "Item Id",
      "Qty",
      "Seller NP Type (MSN / ISN)",
      "Order Status",
      "Name of Seller",
      "Seller Pincode",
      "Seller City",
      "SKU Name",
      "SKU Code",
      "Order Category (F&B/ Grocery/ Home & Decor)",
      "Ready to Ship At Date & Time",
      "Shipped At Date & Time",
      "Delivered At Date & Time",
      "Delivery Type (On-network/ Off-network)",
      "Logistics Seller NP Name (For on-network delivery)",
      "Logistics Seller NP Name (For on-network delivery)	",
      "Logistics Network Order Id (For on-network delivery)",
      "Logistics Network Transaction Id (For on-network delivery)",
      "Delivery City",
      "Delivery Pincode",
      "Cancelled At Date & Time",
      "Cancelled By (Buyer/Seller)",
      "Cancelleation Reason",
      "Total Shipping Charges",
      "Total Order Value",
      "Total Refund Amount",
      // Add more custom headers as needed
    ];
    console.log("orders----->", orders);
    const data = orders.map((order: any, index: any) => {
      let readyToShipAtDateTime =
        (order.orderStatus === "Accepted" ||
          order.orderStatus === "In-progress" ||
          order.orderStatus === "Completed") &&
        order.readyToShipped
          ? moment(order.readyToShipped).format("DD-MM-YYYY hh:mm:ss")
          : "";
      let ShippedAtDateTime =
        order?.shippedDate == "" || order?.shippedDate == null
          ? ""
          : moment(order?.shippedDate).format("DD-MM-YYYY hh:mm:ss");
      let DeliveryDate =
        order?.deliveryDate !== ""
          ? moment(order?.deliveryDate).format("DD-MM-YYYY hh:mm:ss")
          : "";
      let dcc = JSON.parse(order?.deliveryCity);
      let deliveryCity = dcc?.address?.city;
      let deliveryPincode = dcc?.address?.area_code;
      let cancelledAtDateTime =
        order?.cancelled_date == "" || order?.cancelled_date == null
          ? ""
          : moment(order?.cancelled_date).format("DD-MM-YYYY hh:mm:ss");
      let tsc = JSON.parse(order?.shippingCharges);
      let totalShippingChanrges =
        tsc.breakup[tsc?.breakup?.length - 2]?.price?.value;
      let orderCreatedDate = moment(order?.created_at).format(
        "DD-MM-YYYY hh:mm:ss"
      );
      return {
        "S.No": index + 1,
        "Buyer NP Name": order?.buyerNpName,
        "Seller NP Name": "xpressbaazaar.com",
        "Order Create Date & Time": orderCreatedDate,
        "Network Order Id": order?.ntOrderId,
        "Network Transaction Id": order?.ntTransactionId,
        "Seller NP Order Id": order?.sellerNpOrderId,
        "Item Id": order?.item_id,
        Qty: order?.quantity,
        "Seller NP Type (MSN / ISN)": order?.sellerNpType,
        "Order Status": order?.orderStatus,
        "Name of Seller": order?.sellerName,
        "Seller Pincode": order?.pincode,
        "Seller City": order?.city,
        "SKU Name": order?.sku,
        "SKU Code": order?.sku,
        "Order Category (F&B/ Grocery/ Home & Decor)": order?.category_name,
        "Ready to Ship At Date & Time": readyToShipAtDateTime,
        "Shipped At Date & Time": ShippedAtDateTime,
        "Delivered At Date & Time": DeliveryDate,
        "Delivery Type (On-network/ Off-network)": order.delivery_type,
        "Logistics Seller NP Name (For on-network delivery)":
          order?.logisticSellerNpName,
        "Logistics Network Order Id (For on-network delivery)":
          order?.logisticNetworId,
        "Logistics Network Transaction Id (For on-network delivery)":
          order.logisticNetworkTransaction,
        "Delivery City": deliveryCity,
        "Delivery Pincode": deliveryPincode,
        "Cancelled At Date & Time": cancelledAtDateTime,
        "Cancelled By (Buyer/Seller)": order?.canceledBy,
        "Cancelleation Reason": order?.cancelReason,
        "Total Shipping Charges": totalShippingChanrges,
        "Total Order Value": order?.totalOrderValue,
        "Total Refund Amount": order?.totalRefundAmount,
      };
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });
    XLSX.utils.book_append_sheet(wb, ws, "Orders");
    const fileName = `orders_${new Date().getTime()}.xlsx`;
    XLSX.writeFile(wb, fileName);
  };
  useEffect(() => {
    getOrderList();
  }, []);
  return (
    <>
      <ThemeMaker>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-12 ">
            <div className="card shadow mt-3 mb-4">
              <div className="card-header  py-3">
                <div className="row mt-3 d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Order List
                  </h6>
                  <div>
                    <a
                      onClick={() =>
                        setTimeout(() => {
                          exportToExcel(filteredData);
                        }, 2000)
                      }
                      className="btn btn-primary"
                    >
                      Download Sheet
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-6">
                      <label htmlFor="start-date">Search Filter : </label>
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                        id="start-date"
                        onChange={filterBySearch}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div className="col-6">
                      <label htmlFor="start-date"> Start Date : </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Select a date"
                        id="start-date"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </div>
                    <div className="col-5">
                      <label htmlFor="end-date"> End Date : </label>
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={handleEndDateChange}
                        id="end-date"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive table table-striped table-bordered table-sm">
                  {loading ? (
                    <Loader />
                  ) : (
                    <Paginated data={filteredData} columns={COLUMNS} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        {orderStatusPopup ? (
          <div className="orderStatuspopup text-center mx-auto p-5 border shadow bg-light position-fixed top-50 end-0 translate-middle-y">
            <div>
              <div className="selectStatus">
                <p>{`Update order : ${status}`}</p>
                <select
                  className="btn btn-secondary"
                  value={status}
                  onChange={(event: any) => {
                    console.log("event", event);
                    setstatus(event.target.value);
                  }}
                >
                  <option value="Created" disabled={dynamicValue !== "Created"}>
                    Created
                  </option>
                  <option
                    value="Accepted"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "Accepted" &&
                      (dynamicValue === "In-progress" ||
                        dynamicValue === "Completed")
                    }
                  >
                    Accepted
                  </option>
                  <option
                    value="In-progress"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "In-progress"
                    }
                  >
                    In-progress
                  </option>
                  <option
                    value="Completed"
                    disabled={
                      dynamicValue !== "Accepted" &&
                      dynamicValue === "Completed"
                    }
                  >
                    Completed
                  </option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              <div className="selectStatus">
                <p className="m-2">{`Update fulfillment : ${fullFillmentStatus}`}</p>
                <select
                  className="btn btn-secondary"
                  value={fullFillmentStatus}
                  onChange={(event: any) => {
                    console.log("event", event);
                    setFullFillmentStatus(event.target.value);
                  }}
                >
                  <option value="Pending" disabled={status !== "Accepted"}>
                    Pending
                  </option>
                  <option
                    value="Order-picked-up"
                    disabled={status !== "In-progress"}
                  >
                    Order picked up
                  </option>
                  <option
                    value="Out-for-delivery"
                    disabled={status !== "In-progress"}
                  >
                    Out for delivery
                  </option>
                  <option
                    value="Order-delivered"
                    disabled={status !== "Completed"}
                  >
                    Order delivered
                  </option>
                  <option value="Cancelled" disabled={status !== "Cancelled"}>
                    Cancelled
                  </option>
                </select>
              </div>
            </div>
            <button
              className="btn btn-primary d-flex m-auto updateButton"
              type="submit"
              onClick={changeStatus}
            >
              Update
            </button>
            <button
              className="border-0 px-3 py-2 rounded-circle "
              onClick={closePop}
            >
              x
            </button>
          </div>
        ) : null}
      </ThemeMaker>
    </>
  );
}
