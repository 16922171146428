import { useState, useEffect, useCallback } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { Paginated } from "../../components/Paginated";
import "../NewmasterPage/Master.css";
import "../../components/table.css";
import styles from "../transaction/transaction.module.css";
import searchicon_black from "../../icons/searchicon_black.png";
import style from "../../components/Search&Options/Search&Options.module.css";
import Order from "../order";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import * as XLSX from "xlsx";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import "../../index.css";
import { IoLogoUsd } from "react-icons/io";
//import { toast } from "react-toastify";
const { apiEndpoint, baseNodeapiUrl } = config;

var $ = require("jquery");

// const badgeMaker: any = {
//     "Created": (order_state: any) => <span className="badge badge-primary">{order_state}</span>,
//     "Shipped": (order_state: any) => <span className="badge badge-warning">{order_state}</span>,
//     "Delivered": (order_state: any) => <span className="badge badge-success">{order_state}</span>,
//     "Canceled": (order_state: any) => <span className="badge badge-danger">{order_state}</span>,
// }

const OrderList = () => {
  const decoded: any = tokenDecode();
  const current_seller_id = decoded?.data?.user?.seller_id;
  const [orderList, setOrderList] = useState([]);
  const [sorderList, setSorderList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [updateOrder_, setUpdateOrder_]: any = useState({
    order_id: "",
    tracking_url: "",
    order_state: "",
  });
  const [currentStatus, setCurrentStatus] = useState([]);
  const [orderDetail, setorderDetail]: any = useState([]);
  const [_orderDetail, _setorderDetail]: any = useState({
    billing_address: "",
    contact_number: "",
    created_at: "",
    email: "",
    id: "",
    name: "",
    order_id: "",
    order_state: "",
    provider_id: "",
    shipping_address: "",
    status: "",
    total_price: 0,
  });
  const rawData = {
    provider_id: "",
  };
  const Sstyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 2,
  };
  const [toggle, setToggle] = useState(false);
  const [searchData, setSearchData]: any = useState(rawData);

  const [valuename, setValuename] = useState("");
  const [valueid, setValueid] = useState("");
  const [valueemail, setValueemail] = useState("");
  const [valuecontact, setValuecontact] = useState("");
  const [valuestatus, setValuestatus] = useState("");
  const [valueamount, setValueamount] = useState("");
  const [valuedate, setValuedate] = useState("");
  const [orderisLoading, setorderisLoading] = useState(false);
  const [allorders, setallorders] = useState([]);
  const [shipmentModalOpen, setshipmentModalOpen] = useState(false);

  const [selectedCities, setSelectedCities] = useState<any>([]);
  const [singleSellerSelect, setsingleSellerSelect] = useState("");
  const { data } = decoded;
  const {
    permission: { seller },
  } = data;
  const [filterDaata, setfilterDaata] = useState({
    seller_id: "",
    orderCurrentStatus: "",
    endDate: "",
  });

  var count = 1;
  function handleShipementModalClose() {
    setshipmentModalOpen(false);
  }

  const fetchSellerList = async () => {
    let endpoint;
    if (decoded?.data?.user?.seller_id === 1) {
      endpoint = "get-orders";
    } else {
      endpoint = "getsellerordercompletelist";
    }
    setorderisLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/order/${endpoint}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decoded?.data?.user?.seller_id,
        },
      });
      console.log("order data -> ", data.response);
      if (data.status === 200) {
        setorderisLoading(false);
        setOrderList(data.response);
      }
    } catch (error) {
      console.log("error", error);

      setorderisLoading(false);
    }
  };
  const allorderList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getallstatusorder`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decoded?.data?.user?.seller_id,
        },
      });
      // console.log("order data -> ", data);
      if (data.status === 200) {
        setallorders(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateOrder = async (order_id: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: updateOrder_,
      });
      // console.log(data, "datadata");

      if (data.message === "flag1") {
        toast.success("Order updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchSellerList();
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("update error", error);
      toast.error("404 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // window.location.reload();
    }
  };

  const getSellerList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/getsellerlist`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        // console.log(data.data)
        setSellerList(
          data.data.filter((item: any) => item.approve_status === 1)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const inputHandler = async (e: any) => {
    setValuename("");
    setValueid("");
    setValueemail("");
    setValuecontact("");
    setValuestatus("");
    setValueamount("");
    setValuedate("");
    var optionvalue = e.target.value;
    if (optionvalue) {
      try {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbyseller`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data);
          setOrderList(data.data);
          setSorderList(data.data);
          setToggle(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setOrderList(sorderList);
    }
  };
  const inputeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        const { provider_id } = searchData;
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputoHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/order/filterOrderById/${optionvalue}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log("line 332", data.response)
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
      fetchSellerList();
    }
  };
  const inputooHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueid(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            order_id: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputemHandler = async (e: any) => {
    const optionvalue = e.target.value;
    // console.log(optionvalue)
    setValueemail(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            email: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response)
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeemHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueemail(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            email: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputcHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            contact_number: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response)
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputccHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuecontact(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            contact_number: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputpHandler = async (e: any) => {
    const optionvalue = e.target.value;
    // console.log(optionvalue)
    setValuestatus(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            status: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response)
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };

  const inputOrderStatusHandler = async (e: any) => {
    const optionvalue = e.target.value;
    // console.log(optionvalue)
    setValuestatus(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: searchData?.provider_id,
            order_state: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response)
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputppHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuestatus(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            status: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const inputOOrderStatusHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuestatus(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            order_state: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputaHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueamount(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            order_total: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response)
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputaaHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueamount(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            order_total: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };
  const inputdHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        //setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        //console.log("line 239"+rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerorder`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            provider_id: { searchData },
            created_at: optionvalue,
          },
        });

        if (data.status === 200) {
          setOrderList(data.response);
          // console.log(data.response)
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputddHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuedate(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        const { data } = await axios({
          url: `${apiEndpoint}/filterordersellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            created_at: optionvalue,
          },
        });

        if (data.status === 200) {
          // console.log(data.response);
          setOrderList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      fetchSellerList();
    }
  };

  const getAllOrderList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/order/getAllOrderList`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        setCurrentStatus(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const currentOrderState = currentStatus.reduce((acc: any, item: any) => {
    acc[item.order_id] = item.order_state;
    return acc;
  }, {});
  const COLUMNS = [
    {
      Header: "Serial No.",
      Footer: "Serial No.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>;
      },
      disableFilters: true,
      sticky: "left",
    },
    {
      Header: "Order ID",
      Footer: "Order ID",
      accessor: "id",
      sticky: "left",
    },
    {
      Header: "Order Status",
      Footer: "Order Status",
      accessor: (row: any) => {
        const currentRowId = String(row.id);
        if (currentOrderState[currentRowId]) {
          return currentOrderState[currentRowId];
        }
        if (row.financial_status === "voided") {
          return "Canceled";
        } else if (row.fulfillment_status === null) {
          return "Accepted";
        } else if (
          row.fulfillment_status === "fulfilled" &&
          row?.fulfillments.length > 0 &&
          row?.fulfillments[0].shipment_status === "delivered"
        ) {
          return "Completed";
        } else if (
          row.fulfillment_status === "fulfilled" &&
          row?.fulfillments.length > 0 &&
          (row?.fulfillments[0].shipment_status === null ||
            row?.fulfillments[0].shipment_status === "in_transit" ||
            row?.fulfillments[0].shipment_status === "out_for_delivery")
        ) {
          return "In-progress";
        } else {
          return "";
        }
      },
      sticky: "left",
    },
    {
      id: "Seller Name",
      Header: "Seller Name",
      Footer: "Seller Name",
      accessor: (row: any) => {
        return <>{row.seller_company_name}</>;
      },
      sticky: "left",
    },
    {
      Header: "Payment Method",
      Footer: "Payment Method",
      accessor: (row: any) => {
        return <>{row.payment_gateway_names}</>;
      },
      sticky: "left",
    },
    {
      Header: "Customer name",
      Footer: "Customer name",

      accessor: (row: any) => {
        return <>{row.billing_address.name}</>;
      },
      sticky: "left",
    },
    {
      Header: "Total (In USD)",
      Footer: "Total",
      accessor: (row: any, count: any) => {
        return (
          <span className="d-flex justify-content-center align-items-center">
            {" "}
            <IoLogoUsd />
            {row.current_total_price}
          </span>
        );
      },
      sticky: "left",
    },
    {
      Header: "Payment Status",
      Footer: "Payment Status",
      accessor: (row: any) => {
        return <>{row.financial_status === "paid" ? "PAID" : "COD"}</>;
      },
      sticky: "left",
    },
    {
      Header: "Created Date",
      Footer: "Created Date",
      accessor: (row: any) => {
        if (row?.created_at) {
          return moment.utc(row?.created_at).format("DD-MM-YYYY  HH:mm:ss");
        } else {
          return "";
        }
      },
      sticky: "left",
    },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row: any) => {
        return (
          <>
            <Link
              to={`../../orderCompleteDetail/${row.id}`}
              className="btn btn-success btn-circle"
            >
              <i className="fas fa-pen"></i>
            </Link>
          </>
        );
      },
      sticky: "left",
    },
  ];
  count++;
  useEffect(() => {
    fetchSellerList();
    getSellerList();
    getAllOrderList();
    //allorderList()
    // getAllOrderList();
  }, []);
  useEffect(() => {
    if (selectedCities.length > 0 || singleSellerSelect !== "") {
      applyFilterHandler();
    } else {
      fetchSellerList();
    }

    // getAllOrderList();
  }, [selectedCities, singleSellerSelect]);

  const exportToExcel = (orders: any) => {
    // Define custom column headers for the Excel file
    const headers = [
      "S.No",
      "Order Id",
      "Created At",
      "Seller Name",
      "Order State",
      "Status",
      "Item Count",
      "Order Total in USD",
      "Customer Name",
      "Customer Email",
      "Customer Contact",
    ];

    // Prepare data with just the values to be displayed in the columns
    const data = orderList.map((order: any, index: any) => {
      console.log(order.customer.phone);
      let orderCreatedDate = moment(order?.created_at).format(
        "DD-MM-YYYY hh:mm:ss"
      );
      let sellerName = order?.address?.start?.location?.descriptor?.name;
      let orderstatus;
      if (order.financial_status === "voided") {
        orderstatus = "Canceled";
      } else if (order.fulfillment_status === null) {
        orderstatus = "Accepted";
      } else if (
        order.fulfillment_status === "fulfilled" &&
        order?.fulfillments.length > 0 &&
        order?.fulfillments[0].shipment_status === "delivered"
      ) {
        orderstatus = "Completed";
      } else if (
        order.fulfillment_status === "fulfilled" &&
        order?.fulfillments.length > 0 &&
        (order?.fulfillments[0].shipment_status === null ||
          order?.fulfillments[0].shipment_status === "in_transit" ||
          order?.fulfillments[0].shipment_status === "out_for_delivery")
      ) {
        orderstatus = "In-progress";
      } else {
        orderstatus = "";
      }
      return {
        "S.No": index + 1,
        "Order Id": order.order_number,
        "Created At": orderCreatedDate,
        "Seller Name": order.seller_company_name,
        "Order State": order.billing_address.province,
        Status: orderstatus,
        "Item Count": order.line_items.length,
        "Order Total in USD": `$${order.current_total_price}`,
        "Customer Name": order.billing_address.name,
        "Customer Email": order.email,
        "Customer Contact": order.customer.phone,
      };
    });

    // Create a new workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data, { header: headers });

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Orders");

    // Generate a unique name for the Excel file (you can customize this as needed)
    const fileName = `allorders_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file
    XLSX.writeFile(wb, fileName);
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month: string | number = today.getMonth() + 1;
    let day: string | number = today.getDate();

    // Add leading zero if month or day is a single digit
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  async function filterChangeHandler(
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) {
    if (e.target.name !== "orderCurrentStatus") {
      setfilterDaata((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      setfilterDaata((prev: any) => {
        return { ...prev, orderCurrentStatus: [e.target.value] };
      });
    }
  }

  async function applyFilterHandler() {
    let payload;
    if (selectedCities.length > 0) {
      payload = {
        date: filterDaata.endDate,
        order_status: [...selectedCities],
        seller_id: filterDaata.seller_id,
      };
    } else {
      payload = {
        date: filterDaata.endDate,
        order_status: filterDaata.orderCurrentStatus,
        seller_id: filterDaata.seller_id,
      };
    }
    setorderisLoading(true);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/ordersfilter`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          // date: filterDaata.endDate,
          // order_status: filterDaata.orderCurrentStatus,
          // seller_id: filterDaata.seller_id
          ...payload,
        },
      });
      if (data.status === 200) {
        setOrderList(data.data);
        setshipmentModalOpen(false);
        setorderisLoading(false);
      }
    } catch (error) {
      setorderisLoading(false);
      console.log("error", error);
    }
  }

  const preventMinus = (e: any) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const steps = ["Low", "Medium", "High"];

  const allOptions = ["Accepted", "In-progress", "Completed", "Cancelled"];
  const handleAutocompleteChange = (
    event: React.ChangeEvent<{}>,
    newSelectedCities: any
  ) => {
    setSelectedCities(newSelectedCities);
  };

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-2">
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              {current_seller_id === 1 ? (
                <div className="card-header p-2">
                  <div
                    className={`d-flex justify-content-between p-2 align-items-center ${styles.headerContainer}`}
                  >
                    <h6
                      className="m-0 font-weight-bold text-primary text-left"
                      style={{ width: "100px" }}
                    >
                      Order list
                    </h6>
                    {seller?.read ? (
                      <div className="d-flex align-items-center w-100">
                        {/* <div className={styles.sellerListOptions}><b>Filter By Seller: </b><select name="provider_id" id="" onChange={(e: any) => inputHandler(e)} >
                                                <option value="">Select Seller</option>
                                                {
                                                    sellerList.map((item: any) => (
                                                        <option value={item?.provider_id} key={item?.provider_id}>{item?.name}</option>
                                                    ))
                                                }

                                            </select>
                                            </div> */}

                        <div
                          className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4 ml-2 mt-sm-0 w-100"
                          style={{ gap: "11px" }}
                        >
                          {/*<div className="d-flex justify-content-end bg-white" >

                                                    <Autocomplete
                                                        multiple
                                                        size="small"
                                                        id="checkboxes-tags-demo"
                                                        options={allOptions}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option: any) => option}
                                                        onChange={handleAutocompleteChange}
                                                        className="multiOrderStatusSelector"
                                                        value={selectedCities}
                                                        renderOption={(props, option: any, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option}
                                                            </li>
                                                        )}
                                                        // style={{
                                                        //     maxWidth: "400px", width: "100%"
                                                        //     // width: "400px"
                                                        // }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Select Order Status" placeholder="Search" />
                                                        )}
                                                    />
                                                </div>*/}

                          <input
                            type="text"
                            className="form-control  w-100 w-md-50 mt-2 mt-md-0"
                            placeholder="Enter Order ID.."
                            onChange={(e) => {
                              seller?.read
                                ? inputoHandler(e)
                                : inputooHandler(e);
                            }}
                          />

                          <div
                            className="d-flex  align-items-center"
                            style={{ gap: "11px" }}
                          >
                            {/*<button className="btn btn-primary commonBtn " onClick={() => {
                                                        setshipmentModalOpen(true)
                                                    }} style={{ letterSpacing: "1px" }}>Filters</button>
                                                */}
                            <button
                              className="btn btn-primary commonBtn "
                              onClick={() =>
                                setTimeout(() => {
                                  exportToExcel(allorders);
                                }, 2000)
                              }
                              style={{ width: "155px" }}
                            >
                              Export Orders{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={`search mt-1 d-none `}>
                    <div
                      className={`row w-100 d-flex m-0`}
                      style={{ flexWrap: "wrap" }}
                    >
                      <div className={style.inputMain}>
                        <img src={searchicon_black} alt="" />{" "}
                        <input
                          type="search"
                          name="order_id"
                          placeholder="Search by order id"
                          onChange={(e) => {
                            seller?.read ? inputoHandler(e) : inputooHandler(e);
                          }}
                          value={valueid ? valueid : ""}
                        />
                      </div>

                      <div className={`${style.inputMain} d-none`}>
                        <img src={searchicon_black} alt="" />{" "}
                        <input
                          type="search"
                          name="name"
                          placeholder="Search by name"
                          onChange={(e) => {
                            seller?.read ? inputeHandler(e) : inputeeHandler(e);
                          }}
                          value={valuename ? valuename : ""}
                        />
                      </div>

                      <div className={`${style.inputMain} d-none`}>
                        <img src={searchicon_black} alt="" />{" "}
                        <input
                          type="search"
                          name="email"
                          placeholder="Search by email"
                          onChange={(e) => {
                            seller?.read
                              ? inputemHandler(e)
                              : inputeemHandler(e);
                          }}
                          value={valueemail ? valueemail : ""}
                        />
                      </div>

                      <div className={`${style.inputMain} d-none`}>
                        <img src={searchicon_black} alt="" />{" "}
                        <input
                          type="search"
                          name="contact_number"
                          placeholder="Search by Contact"
                          onChange={(e) => {
                            seller?.read ? inputcHandler(e) : inputccHandler(e);
                          }}
                          value={valuecontact ? valuecontact : ""}
                        />
                      </div>

                      <select
                        name="payment_status"
                        onChange={(e) => {
                          seller?.read ? inputpHandler(e) : inputppHandler(e);
                        }}
                        value={valuestatus ? valuestatus : ""}
                        className={style.selector}
                        style={{ display: "none" }}
                      >
                        <option>Select Payment Status</option>
                        <option value="NOT-PAID">Cod</option>
                        <option value="pa">Paid</option>
                      </select>
                      <select
                        name="order_status"
                        onChange={(e) => {
                          seller?.read
                            ? inputOrderStatusHandler(e)
                            : inputOOrderStatusHandler(e);
                        }}
                        value={valuestatus ? valuestatus : ""}
                        className={style.selector}
                      >
                        <option>Select Order Status</option>
                        <option value="Accepted">Accepted</option>
                        <option value="Completed">Completed</option>
                        <option value="In-progress">In-progress</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>

                      <div className={`${style.inputMain} d-none`}>
                        <img src={searchicon_black} alt="" />{" "}
                        <input
                          type="search"
                          name="amount"
                          className="d-none"
                          placeholder="Search by Amount"
                          onChange={(e) => {
                            seller?.read ? inputaHandler(e) : inputaaHandler(e);
                          }}
                          value={valueamount ? valueamount : ""}
                        />
                      </div>

                      <div className="mt-3 w-100">
                        <span
                          style={{ lineHeight: "34px" }}
                          className={style.date}
                        >
                          Search By Date:
                        </span>{" "}
                        <input
                          type="date"
                          name="date"
                          placeholder="Search by Date"
                          onChange={(e) => {
                            seller?.read ? inputdHandler(e) : inputddHandler(e);
                          }}
                          value={valuedate ? valuedate : ""}
                          className={style.inputTypeDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="card-body p-1" style={{ minHeight: "80vh" }}>
                <div className="table-responsive ">
                  {
                    // decoded?.data?.user?.seller_id > 1 || (decoded?.data?.user?.seller_id == 0) ?
                    orderisLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "60vh" }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <div>
                        <div className="showdiv">
                          <Paginated data={orderList} columns={COLUMNS} />
                        </div>
                        <div className="hidediv">
                          {orderList.map((item: any, index) => {
                            let sellerName =
                              item?.address?.start?.location?.descriptor?.name;
                            if (sellerName) {
                              sellerName = sellerName;
                            } else {
                              // const parsedData = JSON.parse(item?.fulfillments);
                              // const selletName = parsedData[0]?.start?.location?.descriptor?.name
                              // sellerName = selletName
                            }

                            return (
                              <div className="border p-1" key={index}>
                                <div
                                  className="d-flex  mt-1 align-items-center"
                                  style={{ gap: "10px" }}
                                >
                                  <span
                                    className="genericlabel"
                                    style={{ width: "28%" }}
                                  >
                                    Order ID
                                  </span>
                                  <span
                                    className=""
                                    style={{ fontSize: "14px", width: "70%" }}
                                  >
                                    {item?.id}
                                  </span>
                                </div>
                                <div
                                  className="d-flex  mt-1"
                                  style={{ gap: "10px" }}
                                >
                                  <span
                                    className="genericlabel "
                                    style={{ width: "28%" }}
                                  >
                                    Order Status
                                  </span>
                                  <span
                                    className=""
                                    style={{ fontSize: "14px", width: "70%" }}
                                  >
                                    {item.financial_status === "voided"
                                      ? "Canceled"
                                      : item.fulfillment_status === null
                                      ? "Accepted"
                                      : item.fulfillment_status ===
                                          "fulfilled" &&
                                        item?.fulfillments.length > 0 &&
                                        item?.fulfillments[0]
                                          .shipment_status === "delivered"
                                      ? "Completed"
                                      : item.fulfillment_status ===
                                          "fulfilled" &&
                                        item?.fulfillments.length > 0 &&
                                        (item?.fulfillments[0]
                                          .shipment_status === null ||
                                          item?.fulfillments[0]
                                            .shipment_status === "in_transit" ||
                                          item?.fulfillments[0]
                                            .shipment_status ===
                                            "out_for_delivery")
                                      ? "In-progress"
                                      : ""}
                                  </span>
                                </div>
                                <div
                                  className="d-flex mt-1"
                                  style={{ gap: "10px" }}
                                >
                                  <span
                                    className="genericlabel "
                                    style={{ width: "28%" }}
                                  >
                                    {" "}
                                    Seller Name
                                  </span>
                                  <span
                                    className=""
                                    style={{ fontSize: "14px", width: "70%" }}
                                  >
                                    {item?.seller_company_name}
                                  </span>
                                </div>
                                <div
                                  className="d-flex mt-1"
                                  style={{ gap: "10px" }}
                                >
                                  <span
                                    className="genericlabel "
                                    style={{ width: "31%" }}
                                  >
                                    Payment Status
                                  </span>
                                  <span
                                    className=""
                                    style={{ fontSize: "14px", width: "69%" }}
                                  >
                                    {item.financial_status === "paid"
                                      ? "Paid"
                                      : "Not Paid"}
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <Link
                                    to={`../../orderCompleteDetail/${item.id}`}
                                  >
                                    {" "}
                                    <p
                                      style={{
                                        width: "120px",
                                        fontWeight: 600,
                                        borderRadius: "17px",
                                        color: "white",
                                        background: "blue",
                                        padding: "6px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Check Order
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          <Modal
            open={shipmentModalOpen}
            // onClose={handleShipementModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Sstyle} className="col-md-3 w-75 w-md-25">
              <div className="modal-content ">
                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                  <p
                    className="modal-title genericlabel  "
                    style={{ letterSpacing: "3px" }}
                  >
                    Filters:
                  </p>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span style={{ cursor: "pointer" }}>
                      <CloseIcon onClick={handleShipementModalClose} />
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>
                    <select
                      name="seller_id"
                      id=""
                      className="form-control"
                      value={filterDaata.seller_id}
                      onChange={filterChangeHandler}
                    >
                      <option value="" disabled selected>
                        Select Seller
                      </option>
                      {sellerList.map((item: any) => (
                        <option value={item?.seller_id} key={item?.seller_id}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mt-2">
                    <select
                      name="orderCurrentStatus"
                      id=""
                      className="form-control"
                      value={filterDaata.orderCurrentStatus}
                      onChange={filterChangeHandler}
                    >
                      <option value="" disabled selected>
                        Select Order Status
                      </option>
                      <option value="Accepted" className="form-control">
                        Accepted
                      </option>
                      <option value="Completed">Completed</option>
                      <option value="In-progress">In-progress</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </div>

                  <div className="mt-2">
                    <input
                      type="date"
                      name="endDate"
                      className="form-control"
                      max={getCurrentDate()}
                      value={filterDaata.endDate}
                      onChange={filterChangeHandler}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      fetchSellerList();
                      setshipmentModalOpen(false);
                      setfilterDaata({
                        seller_id: "",
                        orderCurrentStatus: "",
                        endDate: "",
                      });
                    }}
                  >
                    Clear Filter
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={applyFilterHandler}
                  >
                    Apply Changes
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      </ThemeMaker>
    </>
  );
};

export default OrderList;
