import { useState } from "react";
import ThemeMaker from "../../components/thememaker";
import { toast } from "react-toastify";
import axios from "axios";
import { config } from "../../utils/config";
import "../../index.css";
import { tokenDecode } from "../../utils/helper";

export default function UpdatePassword() {
  const decode = tokenDecode();
  const email = decode?.data?.user?.email;
  const { apiEndpoint,communityUrl } = config;
  const rawData = {
    newPassword: "",
    oldPassword: "",
    confirmPassword: "",
  };
  const [passwordData, setpasswordData] = useState(rawData);
  const [errorShow, seterrorShow] = useState(false);
  function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const { newPassword, confirmPassword } = passwordData;
    setpasswordData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
    if (e.target.name == "confirmPassword") {
      if (e.target.value !== newPassword) {
        seterrorShow(true);
      } else {
        seterrorShow(false);
      }
    }
  }
  async function PasswordHandler() {
    const { newPassword, confirmPassword } = passwordData;
    if (!errorShow && newPassword !== "" && confirmPassword !== "") {
      if (newPassword == confirmPassword) {
        const data = await axios({
          url: `${apiEndpoint}/user/reset-password`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: { password: passwordData?.confirmPassword, email: email },
        });
        console.log(data);
        if (data.status == 200) {
          await axios.post(`${communityUrl}/user/reset-password`, {
            email: email,
            newPassword: newPassword // Hardcoded password for multivendor API, adjust if needed
          });
          toast.success("Password Updated");
        } else {
          toast.error("Something Went Wrong");
        }
      } else {
        toast.error("Password not matched");
      }
    } else {
      toast.error("Please enter password correctly!");
    }
  }
  return (
    <ThemeMaker>
      <div className="row w-100 m-0 mt-1">
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">
                Update Password
              </h6>
            </div>
            <div className="card-body" style={{ minHeight: "80vh" }}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="newPassword"
                      placeholder="Enter New Password"
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Confirm New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onChange={onChangeHandler}
                    />
                    {errorShow ? (
                      <span className="text-danger tiny_size">
                        {" "}
                        Password Mismatch !{" "}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <button className="btn btn-primary" onClick={PasswordHandler}>
                    Update{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeMaker>
  );
}
