import { Link } from "react-router-dom";
import { tokenDecode } from "../../../utils/helper";
import { useState } from "react";
// import logoimg from "../../../../src/icons/logoimg.png";
import logoimg from "../../../../src/icons/multivendor-logo.png"
import styles from "./Side_bar.module.css";
import { useEffect } from "react";
import { config } from "../../../utils/config";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaidIcon from '@mui/icons-material/Paid';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GroupIcon from '@mui/icons-material/Group';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ViewListIcon from '@mui/icons-material/ViewList';
import Navbar from "../navbar";
import { useHistory, useLocation } from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';
import AddCardIcon from '@mui/icons-material/AddCard';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
const { apiEndpoint } = config;
const Sidebar = () => {
  const [arrows, setArrows] = useState({});
  const decoded = tokenDecode();
  const currentLocation = useLocation();
  const history = useHistory()
  const { data } = decoded;
  const { permission: { category, order, product, role, seller, tax, user, location, product_master_catalog, transaction }, } = data;
  // console.log("permissions------->", data.permission)
  if (decoded) {
    // if (isAdmin === "true") {
    //     return children;
    // }
  } else {
    console.log("no");
  }

  const [numberOfNAP, setnumberOfNAP] = useState([]);

  const [sidebarShow, setsidebarShow] = useState(checkstatus());
  const [activeLink, setActiveLink] = useState(currentLocation.pathname);

  useEffect(() => {
    setActiveLink(currentLocation.pathname);
  }, [currentLocation.pathname]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  function checkstatus() {
    const token = localStorage.getItem("us_st_d");
    const tokenDecode = jwtDecode(token);
    // console.log("tokendecode from siebar---->", tokenDecode)
    //console.log("approvalstatus48",tokenDecode.data.user.approve_status);
    return tokenDecode.data.user.approve_status;
  }
  // console.log("seller approve status from sidebar---->", sidebarShow)
  useEffect(() => {
    async function getNAP() {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/dashboard/listNonApproveProductSeller`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: decoded?.data?.user?.seller_id,
          },
        });

        if (data.status === 200) {
          setnumberOfNAP(data.data);
          // console.log(data)
        }
      } catch (error) { }
    }
    getNAP();

    function handleResize() {
      const location = window.location.href.split("/")
      // console.log(location[location.length - 1])
      if (window.innerWidth > 766) {
        if (location[location.length - 1] == "sidebar") {
          history.push("/dashboard")
        }
      }
    }

    window.addEventListener('resize', handleResize);

  }, []);

  // console.log(numberOfNAP)

  // console.log("form sidebar>>>>", decoded.data.user.seller_id)
  let current_sellerid = decoded.data.user.seller_id;

  const handleClick = (id) => {
    setArrows((prevArrows) => {
      const newArrows = Object.fromEntries(
        Object.entries(prevArrows).map(([key, value]) => [key, false])
      );
      newArrows[id] = !prevArrows[id];
      return newArrows;
    });
  };

  return (
    <>
      <div className={styles.sidebar_navbar}>
        <Navbar></Navbar>
      </div>
      {sidebarShow === 1 ? (
        <ul
          className={`navbar-nav sidebar-dark accordion ${styles.side_bar}`}
          id="accordionSidebar"
        >
          <div>
            <a href="https://savageseller.com/" rel="noreferrer" target="_blank" className="sidebar-brand d-flex align-items-center justify-content-start" >
              <div className="sidebar-brand-icon rotate-n-15"></div>
              <div className="sidebar-brand-text">
                <img
                  src={logoimg}
                  style={{ height: "65px", padding: "10px", borderRadius:"100%" }}
                  alt="logo here"
                  className={styles.sideBarLogo}
                />
              </div>
            </a>
          </div>
          

          <li className={`nav-item active mb-2`} onClick={handleLinkClick}>
            <Link to="/dashboard">
              <a
                href="replace"
                className={`nav-link d-flex `}
                style={{ gap: "20px" }}
              >
                {/* <DashboardCustomizeIcon className={styles.icon_color}></DashboardCustomizeIcon> */}
                <span style={{fontWeight:`${activeLink === '/dashboard' && 'bold' }`}}>{decoded?.data?.user?.seller_id===1?"Admin Dashboard":"Vendor Dashboard"}</span>
              </a>
            </Link>
          </li>
          <li className={`nav-item active mb-2`} onClick={handleLinkClick}>
            <Link to={`/${decoded?.data?.user?.seller_id ===1?"masterproductlist":"catalog"}`}>
              <a
                href="replace"
                className={`nav-link d-flex `}
                style={{ gap: "20px" }}
              >
                <div className={styles.listItems}>
                  {/* <ViewListIcon className={styles.icon_color}></ViewListIcon> */}
                  <span className={`${activeLink === '/catalog' || activeLink === '/masterproductlist' ? styles.activeLink : '' }`}>{decoded?.data?.user?.seller_id ===1?"Product List":"Product List"}</span>
                </div>
              </a>
            </Link>
          </li>

          <li className={`nav-item active mb-2`} onClick={handleLinkClick}>
            <Link to={`/${decoded?.data?.user?.seller_id ===1?"createmasterproduct":"createSellerProduct"}`}>
              <a
                href="replace"
                className={`nav-link d-flex `}
                style={{ gap: "20px" }}
              >
                <div className={styles.listItems}>
                  {/* <AddCardIcon className={styles.icon_color}></AddCardIcon> */}
                  <span className={`${activeLink === '/createmasterproduct' || activeLink === '/createSellerProduct' ? styles.activeLink : '' }`}>{decoded?.data?.user?.seller_id ===1?"Add Product":"Add Product"}</span>
                </div>
              </a>
            </Link>
          </li>

          {product?.read ? <>
            {/* <hr className="sidebar-divider mt-0" /> */}
           
            {/* MasterCatalog  field:- */}
            
            <>
              <li
                className="nav-item"
                style={{
                  display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "none" : "block"
                    }`,
                }}
              >
                <Link to="#">
                  <a
                    className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                    href="replace"
                    data-toggle="collapse"
                    data-target="#mastercatalog"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                    id="master-catalog__"
                    onClick={() => handleClick("master-catalog")}
                  >
                    <div className={styles.listItems}>
                      {/* <i className="fas fa-fw fa-tablet"></i> */}
                      {/* <ListAltIcon className={styles.icon_color}></ListAltIcon> */}
                      <span>Catalog</span>
                    </div>

                    <i
                      className={
                        arrows["master-catalog"]
                          ? `fas fa-angle-down `
                          : `fas fa-angle-right `
                      }
                    ></i>
                  </a>
                </Link>
                <div
                  id="mastercatalog"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div
                    className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}
                  >
                    {(current_sellerid < 2 && current_sellerid !== 0 && product.read) ? (
                      <>
                        {" "}
                        <Link to="/createmasterproduct">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Add Catalog Product
                          </a>
                        </Link>
                        <Link to="/masterproductlist">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Master Product List
                          </a>
                        </Link>
                        {category?.write ? (
                          <Link to="/newcategory">
                            <a
                              href="replace"
                              className={`collapse-item  ${styles.collapseitemText}`}
                            >
                              Category
                            </a>
                          </Link>
                        ) : null}
  
                        {category?.write && (
                          <Link to="/brand">
                            <a
                              href="replace"
                              className={`collapse-item  ${styles.collapseitemText}`}
                            >
                              Brand
                            </a>
                          </Link>
                        )}
                        
                      {category?.write && (
                        <Link to="/productlist">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Vendor Products
                          </a>
                        </Link>
                      )}
                      {product?.read && (
                        <>
                          {" "}
                          {product?.read ? (
                            <Link to="/nonapprovedproducts">
                              <a
                                href="replace"
                                className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Non-Approved Products
                              </a>
                            </Link>
                          ) : null}
                        </>
                      )}
  {" "}
                      </>
                    ) : (
                      <>
                      <Link to="/createSellerProduct">
                      <a
                        href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                       Add Product
                      </a>
                      
                    </Link>
                        {" "}
                        <Link to="/catalog">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Product List
                          </a>
                          
                        </Link>
                       
                              <Link to="/nonapprovedproducts">
                                <a
                                  href="replace"
                                  className={`collapse-item  ${styles.collapseitemText}`}
                                >
                                  Non-Approved Products
                                </a>
                              </Link>
                              {category?.write ? (
                                <Link to="/newcategory">
                                  <a
                                    href="replace"
                                    className={`collapse-item  ${styles.collapseitemText}`}
                                  >
                                    Category
                                  </a>
                                </Link>
                              ) : null}
        
                              {category?.write && (
                                <Link to="/brand">
                                  <a
                                    href="replace"
                                    className={`collapse-item  ${styles.collapseitemText}`}
                                  >
                                    Brand
                                  </a>
                                </Link>
                              )}
                       
                      </>
                    )}
                  </div>
                </div>
              </li>
            </>
          </> : null}

          <>
            <li
              className="nav-item"
              style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "block" : "none"
                  }`,
              }}
            >
              <Link to="/allorderslist">
                <a
                  className="nav-link  d-flex justify-content-between myaddedclass"
                  href="replace"
                  id="order"
                  onClick={() => handleClick("issues")}
                >
                  <div className={styles.listItems}>
                    {/* <i className="fas fa-fw fa-cog"></i> */}
                    <ReceiptLongIcon></ReceiptLongIcon>
                    <span>Order </span>
                  </div>

                </a>
              </Link>
            </li>
          </>
          <>
           

            <li
              className="nav-item"
              style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "block" : "none"
                  }`,
              }}
            >
                          </li>
          </>

          {/* MasterCatalog  field end:- */}

          {/* Non approved Products tab start */}

          


          {(order?.read || transaction?.read) ? (
            <>
              
              <div className="sidebar-heading" style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email === "tf1001@ondc.org" || decoded?.data?.user?.email === "tf1002@ondc.org") ? "none" : "block"
                  }`,
              }} ></div>

              {order?.read ? <li className="nav-item">
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseTwo_1" aria-expanded="true" aria-controls="collapseTwo" id="orders"
                    onClick={() => handleClick("orders")} >
                    <div className={styles.listItems}>
                      {/* <ReceiptLongIcon className={styles.icon_color}></ReceiptLongIcon> */}
                      <span>Orders</span>
                    </div>

                    <i className={arrows["orders"] ? `fas fa-angle-down` : `fas fa-angle-right`}  ></i>
                  </a>
                </Link>
                <div id="collapseTwo_1" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">

                  <div className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}>
                    {order?.read ? (
                      <>
                        { current_sellerid === 1 &&
                          <Link to="/orderlist">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                            >
                              Order List
                            </a>
                          </Link>
                        }
                       
                        {/*  */}
                        {decoded?.data?.user?.seller_id > 1 || (decoded?.data?.user?.seller_id === 0) ?
                          <>
                            <Link to="/customerOrder">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                All Orders
                              </a>
                            </Link>
                            {/*<Link to="/completeOrder">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Complete Orders
                              </a>
                            </Link>
                            <Link to="/cancelOrderlist">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Cancelled Orders
                              </a>
                        </Link>*/}

                          </> :
                          null}
                          
                        {/*  */}
                       


                      </>
                    ) : null}
                  </div>
                </div>
              </li> : null}
            </>
          ) : null}
          {(order?.read || transaction?.read) ? (
            <>
              
              <div className="sidebar-heading" style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email === "tf1001@ondc.org" || decoded?.data?.user?.email === "tf1002@ondc.org") ? "none" : "block"
                  }`,
              }} ></div>

              {order?.read ? <li className="nav-item">
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseTwo_2" aria-expanded="true" aria-controls="collapseTwo" id="rentalOrders"
                    onClick={() => handleClick("rentalOrders")} >
                    <div className={styles.listItems}>
                      {/* <ReceiptIcon className={styles.icon_color}></ReceiptIcon> */}
                      <span>Rental Orders</span>
                    </div>

                    <i className={arrows["rentalOrders"] ? `fas fa-angle-down` : `fas fa-angle-right`}  ></i>
                  </a>
                </Link>
                <div id="collapseTwo_2" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">

                  <div className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}>
                    {order?.read ? (
                      <>
                        { current_sellerid === 1 &&
                          <Link to="/rentalorderlist">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                            >
                              Order List
                            </a>
                          </Link>
                        }
                       
                        {/*  */}
                        {decoded?.data?.user?.seller_id > 1 || (decoded?.data?.user?.seller_id === 0) ?
                          <>
                            <Link to="/rentalcustomerOrder">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                All Orders
                              </a>
                            </Link>
                            {/*<Link to="/completeOrder">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Complete Orders
                              </a>
                            </Link>
                            <Link to="/cancelOrderlist">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Cancelled Orders
                              </a>
                        </Link>*/}

                          </> :
                          null}
                          
                        {/*  */}
                       


                      </>
                    ) : null}
                  </div>
                </div>
              </li> : null}
            </>
          ) : null}


          {(transaction?.read && decoded.data.user.parent_id !== 519) ? (
            <>
              <li className="nav-item">

                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseTwo1_1" aria-expanded="true" aria-controls="collapseTwo" id="transaction"
                    onClick={() => handleClick("transaction")}
                  >

                    <div className={styles.listItems}>
                      {/* <PaidIcon className={styles.icon_color}></PaidIcon> */}
                      <span>Transaction</span>
                    </div>
                    <i className={arrows["transaction"] ? `fas fa-angle-down` : `fas fa-angle-right`}  ></i>
                  </a>
                </Link>

                <div
                  id="collapseTwo1_1"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div
                    className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}
                  >

                    {transaction?.read ? (
                      <Link to="/unsettledtransactions">
                        <a
                          href="replace"
                          className={`collapse-item  ${styles.collapseitemText}`}
                        >
                          Unsettled  Transaction
                        </a>
                      </Link>
                    ) : null}

                    {transaction?.read ? (
                      <Link to="/transaction">
                        <a
                          href="replace"
                          className={`collapse-item  ${styles.collapseitemText}`}
                        >
                          Settled  Transaction
                        </a>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </li>
            </>
          ) : null}


          {/* --------------------------------------SETTINGS------------------------------------ */}

          <section style={{
            display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "none" : "block"
              }`,
          }}>
           
            {(user?.read || user?.write || role?.write) && <div className="" ></div>}

            <>
              

              <li className="nav-item">
                {user?.read || user?.write || role?.write ? (
                  <>
                    <Link
                      to="#"
                      className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                      data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseTwo" id="users"
                      onClick={() => handleClick("users")}
                    >
                      <div className={styles.listItems}>
                        {/* <SupervisedUserCircleIcon className={styles.icon_color}></SupervisedUserCircleIcon> */}
                        <span>Users</span>
                      </div>
                      <i className={arrows["users"] ? `fas fa-angle-down ` : `fas fa-angle-right `} ></i>
                    </Link>
                    <div id="collapseOne" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"
                    >
                      <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}                      >
                        {role?.write ? (
                          <Link to="/roles">
                            <a href="replace" className={`collapse-item text-capitalize  ${styles.collapseitemText}`}
                            >
                              Roles
                            </a>
                          </Link>
                        ) : null}
                        {user?.write ? (
                          <Link to="/adduser">
                            <a
                              href="replace"
                              className={`collapse-item  text-capitalize ${styles.collapseitemText}`}
                            >
                              Add user
                            </a>
                          </Link>
                        ) : null}
                        {user?.read ? (
                          <Link to="/userlist">
                            <a
                              href="replace"
                              className={`collapse-item   text-capitalize ${styles.collapseitemText}`}
                            >
                              User List
                            </a>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
              </li>

              <li className="nav-item ">
                {seller?.read || seller?.write || tax?.write ? (
                  <>
                    <Link to="#" className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass" data-toggle="collapse" data-target="#seller11" aria-expanded="true"
                      aria-controls="collapseTwo"
                      onClick={() => handleClick("sellers")}
                    >
                      <div className={styles.listItems}>
                        {/* <GroupIcon className={styles.icon_color}></GroupIcon> */}
                        <span>Sellers</span>
                      </div>

                      <i className={arrows["sellers"] ? `fas fa-angle-down ` : `fas fa-angle-right `}  ></i>
                    </Link>


                    <div id="seller11" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"  >

                      <div className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}    >

                        {seller?.write ? (<>
                          <Link to="/addseller">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}       >
                              Add Individual seller
                            </a>
                          </Link>
                          <Link to="/addBusinessSeller">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}       >
                              Add Business seller
                            </a>
                          </Link>
                          </>
                        ) : null}

                        {seller?.read ? (
                          <Link to="/sellerlist">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}     >
                              Seller List
                            </a>
                          </Link>
                        ) : null}

                        {/*{tax.write ? (
                          <Link to="/tax">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}    >
                              Tax
                            </a>
                          </Link>
                        ) : null}*/}
                      </div>
                    </div>
                  </>
                ) : null}



              </li>
              <li className={`nav-item`} onClick={handleLinkClick}>
                <Link to="/notifications">
                  <a
                    href="replace"
                    className={`nav-link d-flex `}
                    style={{ gap: "20px" }}
                  >
                    <div className={styles.listItems}>
                      {/* <NotificationsIcon className={styles.icon_color}></NotificationsIcon> */}
                      <span className={`${activeLink === '/notifications' ? styles.activeLink : ''}`}>Notification</span>
                    </div>
                  </a>
                </Link>
              </li> 
              <li className={`nav-item`} onClick={handleLinkClick}>
                <Link to="/customer-query">
                  <a
                    href="replace"
                    className={`nav-link d-flex `}
                    style={{ gap: "20px" }}
                  >
                  <div className={styles.listItems}>
                    {/* <SupportAgentIcon className={styles.icon_color}></SupportAgentIcon> */}
                    <span className={`${activeLink === '/customer-query' ? styles.activeLink : '' }`}>Customer Query</span>
                  </div>
                  </a>
                </Link>
              </li> 

              
            </>
          </section>
          {/*<li className={`nav-item active mb-2`}>
          <Link to="/requestproductapproval">
            <a
              href="replace"
              className={`nav-link d-flex `}
              style={{ gap: "20px" }}
            >
        
              <SettingsSuggestIcon className={styles.icon_color}></SettingsSuggestIcon>
              <span>Request For Rental</span>
            </a>
          </Link>
              </li>*/}




        </ul>
      ) : (
        <h5 className="text-center"></h5>
      )}
    </>
  );
};

export default Sidebar;