import React from "react";
import { useState, useEffect } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { Paginated } from "../../components/Paginated";
import "../../components/table.css";
import "../NewmasterPage/Master.css";
import styles from "../../components/Search&Options/Search&Options.module.css";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import searchicon_black from "../../icons/searchicon_black.png";
import style from "../transaction/transaction.module.css";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { IoLogoUsd } from "react-icons/io";
const { apiEndpoint } = config;

var $ = require("jquery");

var count = 1;

export default function ProductList() {
  const [productList, setProductList] = useState<any[]>([]);
  const [sproductList, setSproductList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [sellers, setSellers]: any = useState({
    seller: "",
  });
  const rawData = {
    seller_id: "",
  };
  const rawpData = {
    seller_id: "",
    mrp: "",
  };
  const rawsData = {
    seller_id: "",
    sku: "",
  };
  const [categories, setCategories]: any = useState();
  const [searchData, setSearchData]: any = useState(rawData);
  const [searchpData, setSearchpData]: any = useState(rawpData);
  const [searchsData, setSearchsData]: any = useState(rawsData);
  const [currentSid, setcurrentSid]: any = useState("");
  const [valuename, setValuename] = useState("");
  const [valueprice, setValueprice] = useState("");
  const [valuesku, setValuesku] = useState("");
  const [toggle, setToggle] = useState(false);
  const [showAllcheckboxes, setshowAllcheckboxes] = useState(false);
  const [selectedBox, setselectedBox]: any = useState([]);
  const [SelectedSellerId, setSelectedSellerId]: any = useState("");
  const decode = tokenDecode();
  const { data } = decode;
  const {
    permission: { category, order, product, role, seller, tax, user },
  } = data;
  interface State extends SnackbarOrigin {
    open: boolean;
  }
  // const [open, setOpen] = useState(false);
  const [state, setState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [Msgopen, setMsgOpen] = useState(false);

  const handleMsgClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMsgOpen(false);
  };
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const categoryListing = async () => {};
  const fetchSellerList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/seller/getsellerlist`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
      });
      if (data.status === 200) {
        setSellerList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchCatgoryList = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/categories/getcategorylist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
      });
      console.log(data);
      if (data.status === 200) {
        setCategoryList(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const deleteProduct = async (id: any) => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/deleteproductv`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: { id },
      });
      if (data.status === 200) {
        setProductList(productList.filter((item: any) => item.id !== id));
        toast.success("Product deleted!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("403 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const inputHandler = async (e: any) => {
    var optionvalue = e.target.value;
    setcurrentSid(optionvalue);
    setValuename("");
    setValueprice("");
    setValuesku("");
    if (optionvalue) {
      try {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
        setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
        setToggle(true);
        /*if(e.target.name==="seller"){etSearchpData({ ...searchpData, [e.target.name]: e.target.value });
                setSellers({[e.target.name]: e.target.value})
            }
            if(e.target.name==="category"){
                setCategories({[e.target.name]: e.target.value})
            }
            console.log(sellers);*/
        console.log(optionvalue);
        setSelectedSellerId(optionvalue);
        const { data } = await axios({
          url: `${apiEndpoint}/product/filterproductbyseller`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.data);
          setSproductList(data.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setProductList(sproductList);
    }
  };
  const inputeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        const { seller_id } = searchData;

        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });

        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerproduct`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: { searchData },
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputeeHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuename(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);
        setSearchData({ ...searchData, [e.target.name]: e.target.value });

        const { data } = await axios({
          url: `${apiEndpoint}/filterproductsellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            name: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      categoryListing();
    }
  };
  const inputpHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueprice(optionvalue);
    if (optionvalue) {
      try {
        const { seller_id, mrp } = searchpData;
        setToggle(true);

        setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        console.log("line 239" + rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerproduct`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: { searchData },
            mrp: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputppHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValueprice(optionvalue);
    if (optionvalue) {
      try {
        setToggle(true);

        setSearchpData({ ...searchpData, [e.target.name]: e.target.value });
        console.log("line 239" + rawpData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterproductsellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            mrp: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      categoryListing();
    }
  };
  const inputsHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuesku(optionvalue);
    if (optionvalue) {
      try {
        const { seller_id, sku } = searchsData;
        console.log(searchsData);
        setToggle(true);

        setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
        console.log("line 195" + rawsData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterbysellerproduct`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: { searchData },
            sku: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      inputHandler(e);
    }
  };
  const inputssHandler = async (e: any) => {
    const optionvalue = e.target.value;
    setValuesku(optionvalue);
    if (optionvalue) {
      try {
        console.log(searchsData);
        setToggle(true);

        setSearchsData({ ...searchsData, [e.target.name]: e.target.value });
        console.log("line 195" + rawsData);
        const { data } = await axios({
          url: `${apiEndpoint}/filterproductsellerlogin`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: {
            sku: optionvalue,
          },
        });

        if (data.status === 200) {
          setProductList(data.response);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      categoryListing();
    }
  };
  const inputHandlerforcategory = async (e: any) => {
    try {
      var optionvalue = e.target.value;
      const { data } = await axios({
        url: `${apiEndpoint}/filtercategory`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          category_id: optionvalue,
        },
      });
      console.log("product data", data.response);

      if (data.status === 200) {
        setProductList(data.response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSwitchAction = async (e: any, item: any) => {
    console.log("item status-->", item.index);
    let newIsChecked = !item?.approve_status;
    let itemId = item?.id;

    const { data } = await axios({
      url: `${apiEndpoint}/product/changeproductstatus`,
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("us_st_d")}`,
        "Content-type": "application/json",
      },
      data: {
        product_id: itemId,
        isChecked: newIsChecked,
        seller_id: currentSid,
      },
    });
    console.log(data);
    const datan = {
      id: item?.reference_id,
      product: {
        status: newIsChecked === false ? "draft" : "active",
      },
    };

    const shopifyProductResponse = await axios({
      method: "POST",
      url: `${apiEndpoint}/product/updateproductinshopifystore`, // Shopify API endpoint to add product
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Access-Token": "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",
      },
      data: datan,
    });
    if (shopifyProductResponse.status === 201) {
      toast.success("Product updated successfully !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("404 error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const updatedProductlist = [...productList];
    const uuProductlist: any[] = productList.map((product: any) => {
      if (product?.id == item.id) {
        return {
          ...product,
          approve_status: newIsChecked,
        };
      } else {
        return product;
      }
    });
    // updatedProductlist[index] = {
    //   ...item,
    //   status: newIsChecked,
    // };
    console.log("uuProductlist", uuProductlist);
    setProductList(uuProductlist);
  };

  // ----------------------------------Code functions for bulk selection start ---------------------------------------------------
  function showSelection() {
    setshowAllcheckboxes(true);
  }

  function checkHandler(row: any) {
    console.log("dhadha", row.id);
    let hasId = selectedBox.some((item: any) => item == row.id);
    if (!hasId) {
      setselectedBox((prev: any) => {
        return [...prev, row.id];
      });
    } else {
      setselectedBox((prev: any) => {
        return prev.filter((item: any) => item !== row.id);
      });
    }
  }

  async function submitCheckedDatatoOn() {
    let dataToSend = {
      product_id: selectedBox,
      seller_id: SelectedSellerId,
      action: "on",
    };

    console.log(dataToSend);
    const { data } = await axios({
      url: `${apiEndpoint}/bulkchangeproductstatus`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
      },
      method: "POST",
      data: dataToSend,
    });

    if (data.status == 200) {
      const { data } = await axios({
        url: `${apiEndpoint}/filterproductbyseller`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: SelectedSellerId,
        },
      });

      if (data.status === 200) {
        setProductList(data.response);
        setSproductList(data.response);
        setselectedBox([]);
        toast.success("Catalog Updated!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Something Went Wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  async function submitCheckedDatatoOff() {
    let dataToSend = {
      product_id: selectedBox,
      seller_id: SelectedSellerId,
      action: "off",
    };

    const { data } = await axios({
      url: `${apiEndpoint}/bulkchangeproductstatus`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
      },
      method: "POST",
      data: dataToSend,
    });
    if (data.status == 200) {
      const { data } = await axios({
        url: `${apiEndpoint}/filterproductbyseller`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: SelectedSellerId,
        },
      });

      if (data.status === 200) {
        setProductList(data.response);
        setSproductList(data.response);
        setselectedBox([]);
        toast.success("Catalog Updated!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Something Went Wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  const [SelectionParam, setSelectionParam]: any = useState(false);
  function inactiveProductHandler() {
    // console.log(productList)
    let z = productList.filter((item) => item);
    // console.log(z);
    let m = z.map((item) => item.id);
    setselectedBox(m);
    // checkHandler(...m)
  }

  function activeProductHandler() {
    let z = productList.filter((item) => item.status == true);
    // console.log(z);
    let m = z.map((item) => item.id);
    // console.log(m)
    setselectedBox(m);
  }

  async function clearSelectionHandler() {
    setSelectionParam(false);
    handleClose();
    setselectedBox([]);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/filterproductbyseller`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: currentSid,
        },
      });

      if (data.status === 200) {
        setProductList(data.response);
        setSproductList(data.response);
      }
    } catch (err) {
      console.log(err);
    }
  }
  // ----------------------------------Code functions for bulk selection end ---------------------------------------------------
  const COLUMNS = [
    !SelectionParam
      ? {
          Header: "Serial No.",
          Footer: "Serial No.",
          accessor: (row: any, count: any) => {
            return <>{count + 1}</>;
          },
          disableFilters: true,
          sticky: "left",
        }
      : {
          Header: "Select",
          Footer: "Select",
          accessor: (row: any) => {
            return (
              <input
                type="checkbox"
                onChange={() => checkHandler(row)}
                checked={selectedBox.includes(row.id)}
              />
            );
          },
          disableFilters: true,
          sticky: "left",
        },
    {
      Header: "Product ID",
      Footer: "Product ID",
      accessor: (row: any) => {
        return row.id;
      },
      sticky: "left",
    },
    {
      Header: "Image",
      Footer: "Image",
      accessor: (row: any) => {
        const firstImage =
          Array.isArray(row.image) && row.image.length > 0
            ? row.image[0]
            : null;
        return (
          <img
            src={
              firstImage
                ? firstImage
                : "http://cdn.onlinewebfonts.com/svg/img_546302.png"
            }
            alt="ok"
            height={80}
            width={80}
          />
        );
      },
      sticky: "left",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "name",
      sticky: "left",
    },
    {
      Header: "Price in USD",
      Footer: "Price in USD",
      accessor: (row: any, count: any) => {
        return <span className="d-flex justify-content-center align-items-center"> <IoLogoUsd />{row.mrp}</span>;
      },
      sticky: "left",
      
    },
    {
      Header: "SKU",
      Footer: "SKU",
      accessor: "sku",
      sticky: "left",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (row: any) => {
        return <>{row.approve_status ? "Active" : "Draft"}</>;
      },
      sticky: "left",
    },

    {
      Header: "Action",
      Footer: "Action",
      accessor: (row: any) => {
        return (
          <>
            {/* --------------------------------- Commented the edit and delete button and added switch ------------------------------- */}
            {/* {product.update ? (
              <Link
                to={`../../product/${row.id}`}
                className="btn btn-success btn-circle"
              >
                <i className="fas fa-pen"></i>
              </Link>
            ) : null}
            {product.delete ? (
              <button
                className="btn btn-danger btn-circle"
                onClick={() => deleteProduct(row.id)}
              >
                <i className="fas fa-trash"></i>
              </button>
            ) : null} */}
            {/* --------------------------------- Commented the edit and delete button and added switch ------------------------------- */}
            <div
              className=" d-flex flex-column align-items-center"
              style={{ top: "3px", right: "5px", gap: "3px" }}
            >
              <label className="switch">
                <input
                  type="checkbox"
                  checked={row?.approve_status}
                  onChange={(e) => handleSwitchAction(e, row)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </>
        );
      },
      sticky: "left",
    },
  ];
  count++;
  useEffect(() => {
    categoryListing();
    fetchSellerList();
    fetchCatgoryList();
    setMsgOpen(true);
  }, []);

  useEffect(() => {
    // console.log(selectedBox)
    if (selectedBox.length > 0) {
      handleClick({ vertical: "bottom", horizontal: "center" });
    }
  }, [selectedBox]);

  const [currentState, setCurrentState] = useState("");

  async function filterHandler(e: any) {
    setCurrentState(e.target.value);
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/filterbysellerproduct`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: currentSid,
          status: e.target.value,
        },
      });

      if (data.status === 200) {
        setProductList(data.response);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleClick = (newState: SnackbarOrigin) => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const action = (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ width: "280px" }}
    >
      {currentState === "true" && (
        <p
          className=" ml-5 w-50 text-center text-dark mb-1  p-1 "
          style={{
            color: "white",
            background: "lightgrey",
            borderRadius: "20px",
            height: "30px",
            cursor: "pointer",
            fontWeight: "700",
          }}
          onClick={submitCheckedDatatoOff}
        >
          Deactivate
        </p>
      )}

      {currentState === "false" && (
        <p
          className=" ml-5 w-50 text-center text-dark mb-1  p-1 "
          style={{
            color: "white",
            background: "lightgrey",
            borderRadius: "20px",
            height: "30px",
            cursor: "pointer",
            fontWeight: "700",
          }}
          onClick={submitCheckedDatatoOn}
        >
          Activate
        </p>
      )}

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={clearSelectionHandler}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );

  async function getSearchedProduct(e: any) {
    let payload = {
      seller_id: currentSid,
      [e.target.name]: e.target.value,
      // name: "",
      // sku: "",
      // mrp: "1200"
    };
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/product/filterProductByName`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: payload,
      });

      if (data.status === 200) {
        setProductList(data.response);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-1">
          <div className="col-md-12 ">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary text-left">
                  Product list
                </h6>
                {seller?.read ? (
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div className={style.sellerListOptions}>
                      <b className="mr-1">Select Seller : </b>
                      <select
                        name="seller_id"
                        id=""
                        onChange={(e: any) => inputHandler(e)}
                      >
                        <option value="">Select Seller</option>
                        {sellerList.map((item: any, index) => (
                          <option value={item?.seller_id} key={index}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="search mt-1">
                      <div className="row">
                        <div
                          className="d-flex w-100"
                          style={{ flexWrap: "wrap" }}
                        >
                          <div className={styles.inputMain}>
                            <img src={searchicon_black} alt="" />
                            <input
                              type="search"
                              name="name"
                              placeholder="Search By Name"
                              className="mr-2"
                              onChange={(e) => {
                                // seller?.read ? inputeHandler(e) : inputeeHandler(e);
                                getSearchedProduct(e);
                              }}
                              // value={valuename ? valuename : ""}
                            />{" "}
                          </div>
                          <div className={styles.inputMain}>
                            <img src={searchicon_black} alt="" />{" "}
                            <input
                              type="search"
                              name="mrp"
                              placeholder="Search By Price"
                              className="mr-2"
                              onChange={(e) => {
                                // seller?.read ? inputpHandler(e) : inputppHandler(e);
                                getSearchedProduct(e);
                              }}
                              // value={valueprice ? valueprice : ""}
                            />{" "}
                          </div>
                          <div className={styles.inputMain}>
                            <img src={searchicon_black} alt="" />{" "}
                            <input
                              type="search"
                              name="sku"
                              placeholder="Search By SKU"
                              className="mr-2"
                              onChange={(e) => {
                                // seller?.read ? inputsHandler(e) : inputssHandler(e);
                                getSearchedProduct(e);
                              }}
                              // value={valuesku ? valuesku : ""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="card-body" style={{ minHeight: "60vh" }}>
                <div className="table-responsive">
                  {SelectionParam ? (
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <span
                        onClick={clearSelectionHandler}
                        className="text-center"
                        style={{
                          background: "lightgrey",
                          borderRadius: "12px",
                          fontSize: "12px",
                          width: "70px",
                          height: "25px",
                          cursor: "pointer",
                          paddingTop: "3px",
                          fontWeight: "600",
                        }}
                      >
                        Cancel
                      </span>

                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "15px" }}
                      >
                        <span
                          onClick={inactiveProductHandler}
                          className="text-center"
                          style={{
                            background: "lightgrey",
                            borderRadius: "12px",
                            fontSize: "12px",
                            width: "70px",
                            height: "25px",
                            cursor: "pointer",
                            paddingTop: "3px",
                            fontWeight: "600",
                          }}
                        >
                          Select All
                        </span>

                        <select name="" id="" onChange={filterHandler}>
                          <option value="" disabled selected>
                            Select Status
                          </option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="d-none d-md-block">
                    <Paginated data={productList} columns={COLUMNS} />
                  </div>

                  <div className="hidediv">
                    {productList.map((item: any, index) => {
                      return (
                        <div className="border p-1" key={index}>
                          <div
                            className="d-flex  mt-1 align-items-center"
                            style={{ gap: "10px" }}
                          >
                            <span
                              className="genericlabel"
                              style={{ width: "28%" }}
                            >
                              Name
                            </span>
                            <span
                              className=""
                              style={{ fontSize: "14px", width: "70%" }}
                            >
                              {item?.name}
                            </span>
                          </div>
                          <div className="d-flex  mt-1" style={{ gap: "10px" }}>
                            <span
                              className="genericlabel "
                              style={{ width: "28%" }}
                            >
                              Product ID
                            </span>
                            <span
                              className=""
                              style={{ fontSize: "14px", width: "70%" }}
                            >
                              {item?.id}
                            </span>
                          </div>
                          <div className="d-flex mt-1" style={{ gap: "10px" }}>
                            <span
                              className="genericlabel "
                              style={{ width: "28%" }}
                            >
                              {" "}
                              Price
                            </span>
                            <span
                              className=""
                              style={{ fontSize: "14px", width: "70%" }}
                            >
                              {item.mrp}
                            </span>
                          </div>
                          <div className="d-flex mt-1" style={{ gap: "10px" }}>
                            <span
                              className="genericlabel "
                              style={{ width: "28%" }}
                            >
                              SKU
                            </span>
                            <span
                              className=""
                              style={{ fontSize: "14px", width: "69%" }}
                            >
                              {item?.sku}
                            </span>
                          </div>
                          <div className="d-flex mt-1" style={{ gap: "10px" }}>
                            <span
                              className="genericlabel "
                              style={{ width: "28%" }}
                            >
                              Status
                            </span>
                            <span
                              className=""
                              style={{ fontSize: "14px", width: "69%" }}
                            >
                              Draft
                            </span>
                          </div>
                          <div className="mt-2">
                            <div
                              className=" d-flex flex-column "
                              style={{ top: "3px", right: "5px", gap: "3px" }}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item?.approve_status}
                                  onChange={(e) => handleSwitchAction(e, item)}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                            {/* <button className="btn btn-danger"> Switch</button> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>

      <div style={{ width: "100px" }}>
        {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          sx={{ width: "100%" }}
          // onClose={handleClose}
          key={vertical + horizontal}
          action={action}
        />
      </div>

      <Snackbar
        open={Msgopen}
        autoHideDuration={20000}
        onClose={handleMsgClose}
        key={vertical + horizontal}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleMsgClose}
          sx={{ width: "100%", fontWeight: 600, background: "#105293" }}
        >
          To see products, Select a seller.
        </Alert>
      </Snackbar>
    </>
  );
}
