import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";

const { apiEndpoint } = config;

export default function Location() {
  const [loading, setLoading] = useState(false);
  const decode = tokenDecode();
  const rawData = {
    id: 0,
    location_name: "",
    delivery_pincode: "",
    seller_id: decode?.data?.user?.seller_id,
    status: true,
  };
  const rawDataError = {
    location_name: false,
    delivery_pincode: false,
  };
  const [userData, setUserData]: any = useState(rawData);
  const [userErrorData, setUserErrorData] = useState(rawDataError);

  const errorHandler = (e: any) => {
    if (e.target.value) {
      setUserErrorData({ ...userErrorData, [e.target.name]: false });
    } else {
      setUserErrorData({ ...userErrorData, [e.target.name]: true });
    }
  };
  const inputHandler = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const getLocation = async () => {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getlocation`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          seller_id: decode?.data?.user?.seller_id,
        },
      });
      console.log("datataa", data);
      if (data.status === 200) {
        if (data.data.length >= 1) {
          setUserData(data.data[0]);
        } else {
          console.log("no location !");
        }
      }
    } catch (error) {}
  };
  const newLocationCreate = async () => {
    setLoading(true);
    const { location_name, delivery_pincode } = userData;
    console.log(location_name, location_name);
    if (location_name && delivery_pincode) {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/addlocation`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
          },
          data: userData,
        });
        if (data.message === "flag2") {
          toast.success("Location updated !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (data.message === "flag1") {
          toast.success("New Location created !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        setLoading(false);
        toast.error("404 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Please fill all the field !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getLocation();
  }, []);
  return (
    <>
      <ThemeMaker>
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Location</h6>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Location name</label>
                      <span className="text-danger"> * </span>
                      {userErrorData?.location_name ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <input
                        type="text"
                        className="form-control"
                        name="location_name"
                        placeholder="Enter name"
                        value={userData.location_name}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Pincode</label>
                      <span className="text-danger"> * </span>
                      {userErrorData?.delivery_pincode ? (
                        <span className="text-danger tiny_size">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        name="delivery_pincode"
                        value={userData.delivery_pincode}
                        onChange={(e) => inputHandler(e)}
                        onBlur={(e) => errorHandler(e)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={newLocationCreate}
                    >
                      {loading ? "saving..." : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
}
