import { useEffect, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";
import { config } from "../../utils/config";

const { apiEndpoint } = config;

export default function Checkseller() {
  const history = useHistory();
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    async function getId() {
      console.log("getid function---------");
      const queryString = window.location.search;
      // const queryString = https://stageadmin.xpressbaazaar.com/sellerauthcheck?HULID=" + sHulId + "&ACCESSTOKEN=" + sToken;
      const urlParams = new URLSearchParams(queryString);
      const hulid = urlParams.get("hulid");
      console.log(hulid);
      const { data } = await axios({
        url: `${apiEndpoint}/checkheader`,
        method: "POST",
        data: { hulid: hulid },
      });
      if (data) {
        console.log("data--->", data);
        var token = data.data.token;
        var decoded = jwtDecode(token);
        localStorage.setItem("us_st_d", data.data.token);
        let decodedUserdata = decoded.data.user;
        console.log("decodedUserdata", decodedUserdata);
        if (
          decodedUserdata.email == null &&
          decodedUserdata.contact_no == null &&
          decodedUserdata.name == null
        ) {
          console.log("inside if block");
          history.push(`/selleregister?hulid=HUL-410530D-P00010773`);
        } else if (decodedUserdata.approve_status == 1) {
          history.push(`/dashboard`);
        } else if (decodedUserdata.approve_status == 0) {
          console.log("from else----", decodedUserdata.approve_status);
          givedta();
          async function givedta() {
            const { data } = await axios({
              url: `${apiEndpoint}/getbankdetails`,
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
              },
            });
            console.log("data", data);
            if (data.status === 200) {
              if (
                data.data[0].opening_time == null ||
                data.data[0].bank_account == null ||
                data.data[0].pan_name == null
              ) {
                if (data.data[0].opening_time == null) {
                  history.push(`/sellerdetail?tab=shopdetails`);
                } else if (data.data[0].pan_name == null) {
                  history.push(`/sellerdetail?tab=personaldetails`);
                } else if (data.data[0].bank_account == null) {
                  history.push(`/sellerdetail?tab=bankdetails`);
                }
              } else {
                history.push(`/pending`);
              }
            }
          }
        }
      }
    }
    if (!renderAfterCalled.current) {
      getId();
    }
    renderAfterCalled.current = true;
  }, []);
  return (
    <div className="d-flex justify-content-center w-100 mt-2 text-dark">
      Loading...
    </div>
  );
}
