import { useState, useRef } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import { config } from "../../utils/config";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { show, hide } from "react-functional-modal";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";
import { jwtDecode } from "jwt-decode";

const { apiEndpoint } = config;
const s3Config = {
  bucketName: "bucket-name",
  dirName: "directory-name" /* Optional */,
  region: "ap-south-1",
  accessKeyId: "ABCD12EFGH3IJ4KLMNO5",
  secretAccessKey: "a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0",
  s3Url: "https:/your-aws-s3-bucket-url/" /* Optional */,
};

const aws = require("aws-sdk");

const ImageContainer = styled.div`
  border: 1px solid black;
  border-radius: 27px;
  width: 83%;
  background: white;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 100% !important;
    height: 80%;
    object-fit: contain;
  }

  button {
    position: absolute;
    top: 4px;
    right: 11px;
    background: none;
    outline: none;
    border: none;
  }
`;

export default function Bankdetails() {
  const param = useParams();
 // const id = param.id;
  let token = localStorage.getItem("us_st_d");
  let decoded = jwtDecode(token);
  let { data } = decoded;
  const isApproved = data?.seller_data?.approve_status;
  let id=data?.seller_data?.id
  // console.log("decoded----", data)
  const history = useHistory();

  const rawData = {
    id: id,
    bank_account: "",
    ifsc_code: "",
    beneficiary_name: "",
  };
  const rawErrorData = {
    bank_account: false,
    ifsc_code: false,
    beneficiary_name: false,
    commission: false,
  };
  const [bankdetailsData, setbankdetailsData] = useState(rawData);
  const [bankdetailsDataError, setbankdetailsDataError] =
    useState(rawErrorData);
  const renderAfterCalled = useRef(false);
  async function givedta() {
    const { data } = await axios({
      url: `${apiEndpoint}/seller/getseller`,
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("us_st_d")}`,
      },
      data: {
        seller_id: id,
      },
    });
    if (data.status === 200) {
      setbankdetailsData(data.response);
      renderAfterCalled.current = true;
    }
  }
  if (!renderAfterCalled.current) {
    givedta();
  }
  const inputHandler = (e) => {
    setbankdetailsData({ ...bankdetailsData, [e.target.name]: e.target.value });
  };
  const errorHandler = (e) => {
    if (e.target.value) {
      setbankdetailsDataError({
        ...bankdetailsDataError,
        [e.target.name]: "valid",
      });
    } else {
      setbankdetailsDataError({
        ...bankdetailsDataError,
        [e.target.name]: true,
      });
    }
  };
  const onSubmitHandler = async () => {
    // console.log("bankdetailsData => ", bankdetailsData);
    // console.log("bankdetailsDataError => ", bankdetailsDataError);
    const {
      id,
      bank_account,
      ifsc_code,
      beneficiary_name,
    } = bankdetailsData;
    // if (
    //   bank_account &&
    //   ifsc_code &&
    //   beneficiary_name
    // ) {
      // dispatch(registerSeller(bankdetailsData));
      // console.log(">>>>>>>>>>>>", bankdetailsData)
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/seller/bankdetails`,
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("us_st_d")}`,
          },
          data: bankdetailsData,
        });

        if (data.status === 200) {
          toast.success("Details submitted Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            if (decoded.data.user.approve_status == 0) {
              history.push("/pending");
            }
          }, 2000);
        }
        // console.log("data from server---", data)
        // console.log("data from server---", bankdetailsData)
      } catch (err) {
        console.log(err);
        toast.error("Have some issue !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    // } else {
    //   toast.error("Please fill all the fields !", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  const productImageHandler = async (e) => {
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: config?.awsKeyId,
        secretAccessKey: config?.awsSecretKey,
      });
      const params = {
        Bucket: "savageseller",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let result = await s3.upload(params).promise();
      const { Location } = result;
      // console.log(Location);
      setbankdetailsData({ ...bankdetailsData, [e.target.name]: Location });
    } catch (exception) {
      console.log(exception);
    }
  };
  const open = (image) => {
    show(
      <ImageContainer>
        <img src={image} alt="" />
        <button
          onClick={() => {
            hide("key#1");
          }}
        >
          x
        </button>
      </ImageContainer>,
      { key: "key#1" }
    );
  };
  // console.log("bankdetailsData",bankdetailsData);
  return (
    <>
    {!bankdetailsData?.area_code ? (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
        <CircularProgress />
      </div>
    ) : (
    <div className="row m-0 w-100 mt-1">
      <div className="col-md-12" style={{ background: "white" }}>
        <div className="card shadow mb-4">
          {/* <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-bg-gradient-dark">
                                Bank Details
                            </h6>
                        </div> */}
          <div className="card-body" style={{ minHeight: "70vh" }}>
            <div className="row">
              
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="text-dark"
                    style={{ color: "black", fontWeight: 600 }}
                  >
                    Bank Account Number
                    {/*<span className="text-danger">*</span>
                    {bankdetailsDataError?.bank_account !== "valid" &&
                    bankdetailsDataError?.bank_account !== false ? (
                      <span className="text-danger tiny_size">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}*/}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="bank_account"
                    placeholder="Enter Account Number"
                    value={bankdetailsData.bank_account}
                    onChange={(e) => inputHandler(e)}
                    onBlur={(e) => errorHandler(e)}
                    readOnly={isApproved === 1 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="text-dark fw-bold"
                    style={{ color: "black", fontWeight: 600 }}
                  >
                    Routing Number
                    {/*<span className="text-danger">*</span>
                    {bankdetailsDataError?.ifsc_code !== "valid" &&
                    bankdetailsDataError?.ifsc_code !== false ? (
                      <span className="text-danger tiny_size">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}*/}
                  </label>
                  <input
                    type="text"
                    name="ifsc_code"
                    className="form-control"
                    placeholder="Enter IFSC Code"
                    value={bankdetailsData.ifsc_code}
                    onChange={(e) => inputHandler(e)}
                    onBlur={(e) => errorHandler(e)}
                    readOnly={isApproved === 1 ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="text-dark"
                    style={{ color: "black", fontWeight: 600 }}
                  >
                    Beneficiary Name
                    {/*<span className="text-danger">*</span>
                    {bankdetailsDataError?.beneficiary_name !== "valid" &&
                    bankdetailsDataError?.beneficiary_name !== false ? (
                      <span className="text-danger tiny_size">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}*/}
                  </label>
                  <input
                    type="text"
                    name="beneficiary_name"
                    className="form-control"
                    placeholder="Enter Benificiary Name"
                    value={bankdetailsData.beneficiary_name}
                    onChange={(e) => inputHandler(e)}
                    onBlur={(e) => errorHandler(e)}
                    readOnly={isApproved === 1 ? true : false}
                  />
                </div>
              </div>
             
              
              <div className="col-md-12">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={onSubmitHandler}
                >
                  Update
                </button>
              </div>
              {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
          </div>
        </div>
      </div>
    </div>)}
    </>
  );
}
