// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Buttons_button__2PV1L {
  border: none;
  outline: none;
  background-color: blue;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/Buttons/Buttons.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".button {\n  border: none;\n  outline: none;\n  background-color: blue;\n  color: white;\n  padding: 5px 10px;\n  border-radius: 6px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Buttons_button__2PV1L`
};
export default ___CSS_LOADER_EXPORT___;
