import eye from "../../icons/eye.png";
import styles from "../TaxlistTemplate/TaxlistTemplate.module.css";
import deleteicon from "../../icons/delete.png";
import moment from "moment";

export default function Brandlist({ item, index, editRedirect, deleteTax }) {
  return (
    <>
      <div
        className={`d-flex justify-content-between w-100 ${styles.taxlistTemplatemain}`}
        key={item.id}
      >
        <div className={`${styles.taxlistTemplateImage}`}></div>
        <div className={`${styles.taxlistTemplateDesc}`}>
          <div>
            <p className="m-0">
              {" "}
              <span>Brand Name:</span>{" "}
              <span style={{ color: "black", fontWeight: "700" }}>
                {item.brand_name}
              </span>
            </p>
          </div>
          <div>
            <p className="m-0">
              {" "}
              <span>Date: </span>
              <span>{moment(item.created_at).format("DD-MM-YYYY")}</span>
            </p>
          </div>
          <div>
            {item.status ? (
              <button className={styles.success}>Active</button>
            ) : (
              <button className={styles.danger}>Deactive</button>
            )}
          </div>
        </div>
        <div className={`${styles.taxlistTemplateedit}`}>
          <div onClick={() => deleteTax(item.id)}>
            <img src={deleteicon} alt="" />
          </div>
          <img
            src={eye}
            alt=""
            onClick={() => {
              editRedirect(item.id);
            }}
            data-target="#exampleModal1"
            data-toggle="modal"
          />
        </div>
      </div>
    </>
  );
}
