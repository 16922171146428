export const config = {
  //apiEndpoint: "https://xpressbazarprod.hulsecure.in/public/index.php/api",
  // apiEndpoint: "http://localhost:5001",
  apiEndpoint: "https://marketplace-api.savageseller.com",

  baseNodeapiUrl: "http://localhost",
  awsKeyId: process.env.REACT_APP_AWSKEYID,
  awsSecretKey: process.env.REACT_APP_AWSSECRETKEY,
  storeUrl:"https://savagesellerstore.myshopify.com",
  communityUrl:"https://community-api.savageseller.com",
  // communityUrl:"http://localhost:7070",

};
