import { Link, useHistory } from "react-router-dom";
import { tokenDecode } from "../../../utils/helper";
import shikharapplogo from "../../../icons/logoimg.png";
import styles from "./navbar.module.css";
import bars from "../../../icons/MenuBar.png";
import shop from "../../../icons/shop.png";
import PublicIcon from "@mui/icons-material/Public";

export default function Navbar(props) {
  const history = useHistory();
  const logouthandler = () => {
    localStorage.removeItem("us_st_d");
  };
  const token = localStorage.getItem("us_st_d");
  const decoded = tokenDecode();
  const { data } = decoded;
  const { user } = data;

  return (
    <>
    <nav className={`navbar navbar-expand navbar-light topbar static-top shadow ${styles.mobile_navbar}`}>
    <button
      id="sidebarToggleTop"
      className={`btn btn-link rounded-circle mr-3 ${styles.burgerButton}`}
      onClick={props.Showmenu}
    >
          <img src={bars} alt="" style={{ height: "70%" }} />
        </button>
        <ul className="navbar-nav">
          <li className={`nav-item dropdown no-arrow ${styles.plus} d-flex align-items-center justify-content-center`}>
            <a rel="noreferrer" href="https://community.savageseller.com/" target="_blank" className={`d-none d-lg-flex text-white small`} disabled={true}>Group Hub
            </a>
          </li>
          <div className="topbar-divider d-none d-sm-block"></div>
          <li className={`nav-item dropdown no-arrow ${styles.plus}`}>
            <Link
              to="#"
              className="nav-link dropdown-toggle"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-white small">
                {user?.name}
              </span>
              <img
                className={`img-profile rounded-circle ${styles.dropdownimg}`}
                src={`/img/undraw_profile.svg`}
                alt="im here"
              />
              <div className={styles.searchOpener}>
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </div>
            </Link>
            <div
              className={`dropdown-menu  shadow animated--grow-in p-0 w-100 ${styles.dropDownPosition}`}
              aria-labelledby="userDropdown"
            >
              <div className={styles.doropdownnavmenu}>
              {user.role_id==1 || user.role_id==2?
                <>
                <Link to="/sellerdetail?tab=shopdetails">
                  <a
                    href="replace"
                    className={`dropdown-item ${styles.bottomPadding}`}
                    data-toggle="modal"
                    data-target="#logoutModal"
                  >
                    <i className="fas fa-home fa-sm fa-fw mr-2 text-gray-400"></i>
                    <span> Shop details </span>
                  </a>
                </Link>
                <div className="dropdown-divider"></div></>:""}
                <Link to="/updatepassword">
                  <a
                    href="replace"
                    className={`dropdown-item ${styles.bottomPadding}`}
                    data-toggle="modal"
                    data-target="#logoutModal"
                  >
                    <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                    <span> Update Password </span>
                  </a>
                </Link>
                <div className="dropdown-divider"></div>
                <Link to="#" onClick={logouthandler}>
                  <a
                    href="replace"
                    className={`dropdown-item ${styles.bottomPadding}`}
                    data-toggle="modal"
                    data-target="#logoutModal"
                  >
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    <span> Logout </span>
                  </a>
                </Link>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
}
