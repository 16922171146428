import { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import "./style.css";
import { useParams, useHistory } from "react-router-dom";

interface ICustomer {
  id: any;
}
export default function ResetPassword() {
  const history = useHistory();
  const [token, setToken] = useState("");
  let params: ICustomer = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState({
    password: "",
    matchpassword: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    password: "",
    matchpassword: "",
  });
  const forgetPassMannage = async () => {
    const { data } = await axios({
      url: "/user/pasword/reset",
      method: "POST",
      data: pass,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setLoading(false);

    if (data.message === "flag1") {
      setPass({
        password: "",
        matchpassword: "",
      });
      toast.success("Passwod reset successfully !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/login");
    } else {
      toast.error("404 erro !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  useEffect(() => {}, []);
  const forgetPassHandler = (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (errorMessage.password || errorMessage.matchpassword) {
      setLoading(false);
      toast.error("Both field is required !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (pass.password !== pass.matchpassword) {
      setLoading(false);
      toast.error("Both password do not match !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      forgetPassMannage();
    }
  };
  const onChangeHandler = (e: any) => {
    setPass({ ...pass, [e.target.name]: e.target.value });
    setErrorMessage({
      ...errorMessage,
      [e.target.name]: e.target.value ? false : true,
    });
  };
  return (
    <>
      <div className="container-fluid bg-primary" style={{ height: "100vh" }}>
        <div className="row justify-content-center">
          <div className="col-md-6 mt-4 mb-4">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center mb-3">
                        <img
                          src="/img/mainlogo.png"
                          style={{ height: "65px" }}
                          alt="logo here"
                        />
                      </div>
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-2">
                          Reset Your Password
                        </h1>
                        <p className="mb-4">
                          We get it, stuff happens. Just enter your email
                          address below and we'll send you a link to reset your
                          password!
                        </p>
                      </div>
                      <form
                        className="user mb-4"
                        onSubmit={(e) => forgetPassHandler(e)}
                      >
                        <div className="form-group">
                          <label> New password * </label>
                          <input
                            type="password"
                            className="form-control form-control-user"
                            placeholder="Enter new password"
                            name="password"
                            onChange={(e) => onChangeHandler(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label> Retype password * </label>

                          <input
                            type="password"
                            className="form-control form-control-user"
                            placeholder="Retype password"
                            name="matchpassword"
                            onChange={(e) => onChangeHandler(e)}
                          />
                          {pass.password !== pass.matchpassword ? (
                            <span className="errmess">
                              Password do not match !
                            </span>
                          ) : null}
                        </div>
                        {loading ? (
                          <button className="btn btn-primary btn-user btn-block mt-4 mb-4">
                            Loading...
                          </button>
                        ) : (
                          <button className="btn btn-primary btn-user btn-block mt-4 mb-4">
                            Reset password
                          </button>
                        )}
                      </form>
                      <hr />
                      <div className="text-center">
                        <Link className="small" to="/">
                          Already have an account? Login!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
