// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-family: "Nunito", sans-serif !important;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 15px;
  text-align: center;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

thead {
  white-space: nowrap;
}

table th,
tfoot td {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  text-align: center;
  background: #454545;
  color: white;
}
.page_link_item {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  border: 1px solid #dddfeb;
}
`, "",{"version":3,"sources":["webpack://./src/components/table.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,yBAAyB;EACzB,WAAW;AACb;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,4BAA4B;EAC5B,+BAA+B;EAC/B,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,uBAAuB;EACvB,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":["table {\n  font-family: \"Nunito\", sans-serif !important;\n  border-collapse: collapse;\n  width: 100%;\n}\n\ntable td,\ntable th {\n  border: 1px solid #ddd;\n  padding: 8px;\n  font-size: 15px;\n  text-align: center;\n}\n\ntable tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\ntable tr:hover {\n  background-color: #ddd;\n}\n\nthead {\n  white-space: nowrap;\n}\n\ntable th,\ntfoot td {\n  padding-top: 12px !important;\n  padding-bottom: 12px !important;\n  text-align: center;\n  background: #454545;\n  color: white;\n}\n.page_link_item {\n  position: relative;\n  display: block;\n  padding: 0.5rem 0.75rem;\n  margin-left: -1px;\n  line-height: 1.25;\n  border: 1px solid #dddfeb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
